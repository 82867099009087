<!-- UserRecord.vue - Component for individual rows of Users table -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    <tr v-bind:class="[rowClass]">
        <td
            v-if="showMark"
        >
            <b-form-checkbox
                v-if="showMark"
                name="selections[]"
                v-model="isChecked"
                v-bind:id="'selection-' + user.id"
                v-bind:checked="checked"
                v-on:change="$emit('change', isChecked)"
            >
            </b-form-checkbox>
        </td>
        <td
            v-bind:class="{special: (user.id == 1)}"
        >
            <router-link
                v-bind:to="{name: 'Checkouts', query: { item: null, user: user.id, group: null, show: 'checkouts' }}"
            >
                <span v-on:click.prevent="goToFilteredCheckouts(null, user, null, 'checkouts')">{{ user.id }}</span>
            </router-link>
        </td>
        <td
            v-bind:class="{special: (user.id == 1)}"
        >
            <router-link
                v-bind:to="{name: 'Checkouts', query: { item: null, user: user.id, group:null, show: 'checkouts' }}"
            >
                <span v-on:click.prevent="goToFilteredCheckouts(null, user, null, 'checkouts')">{{ user.lastName }}</span>
            </router-link>
        </td>
        <td
            v-bind:class="{special: (user.id == 1)}"
        >
            <router-link
                v-bind:to="{name: 'Checkouts', query: { item: null, user: user.id, group: null, show: 'checkouts' }}"
            >
                <span v-on:click.prevent="goToFilteredCheckouts(null, user, null, 'checkouts')">{{ user.firstName }}</span>
            </router-link>
        </td>
        <td>
            <router-link
                v-bind:to="{name: 'Checkouts', query: { item: null, user: user.id, group:null, show: 'nonreturned' }}"
            >
                <span v-on:click.prevent="goToFilteredCheckouts(null, user, null, 'nonreturned')">{{ user.status.itemsOut }}</span>
            </router-link>
        </td>
        <td v-bind:title="user.status.lastCheckoutTime">
            {{ lastCheckout | prettyDate }}
        </td>
        <td>
            <router-link
                v-bind:to="{name: 'Checkouts', query: { item:null, user: user.id, group:null, show: 'checkouts' }}"
            >
                <span v-on:click.prevent="goToFilteredCheckouts(null, user, null, 'checkouts')">{{ user.status.totalCheckouts }}</span>
            </router-link>
        </td>
        <td v-if="enableTracking">
            <router-link
                v-bind:to="{name: 'Checkouts', query: { item:null, user: user.id, group:null, show: 'completed' }}"
            >
                <span v-on:click.prevent="goToFilteredCheckouts(null, user, null, 'completed')">{{ user.status.completedTasks }}</span>
            </router-link>
        </td>
        <td v-bind:class="{inactive: !this.user.group.active}" v-if="!grouped">
            <span v-if="user.group.active">
                <router-link
                    v-bind:to="{name: 'Checkouts', query: { item:null, user: null, group: user.group.id, show: 'checkouts' }}"
                >
                    <span v-on:click.prevent="goToFilteredCheckouts(null, null, user.group, 'checkouts')">{{ user.group.name }}</span>
                </router-link>
            </span>
            <span v-else>
                {{ user.group.name }}
            </span>
        </td>
    </tr>
</template>

<script>

import record from '../mixins/record'
import filterLinks from '../mixins/filter-links'

export default {
    data(){
        return {
            
        }
    },
    
    props: {
        user: Object,
        grouped: {
            type: Boolean,
            default: false
        },
        enableTracking: Boolean
    },
    
    mixins: [record, filterLinks],
    
    computed: {
    
        lastCheckout: function() {
            if (this.user.status.lastCheckoutTime == null) {
                return '-';
            }
            else {
                return new Date(this.user.status.lastCheckoutTime.replace(' ', 'T'));
            }
        },
    
        checkoutDate: function() {
            if (this.user.status.currentCheckoutTime != null) {
                return new Date(this.user.status.currentCheckoutTime.replace(' ', 'T'));
            }
            else {
                return new Date();
            }
        },
        
        status: function () {
            if (this.user.status.itemsOut > 0) {
                if (this.user.status.hasOverdueCheckout) {
                    return 'overdue';
                }
                else {
                    return 'out';
                }
           }
           else {
                return '';
           }
        }
        
    }
}
</script>

<style>

td.inactive {
    color: gray;
    text-decoration: line-through;
}

td.special, tr.special, tr.special td a {
    color: gray;
}

tr.missing {
    font-style: italic;
}
tr.late {
    color: red;
    font-weight: bold;
}

tr.late a {
    color: red;
}

tr.late a:hover {
    color: blue;
    text-decoration: underline;
}

tr.out{
    color: black;
    font-weight: bold;
}

tr.out a {
    color: black;
}

tr.out a:hover {
    color: blue;
    font-weight: bold;
}

</style>
