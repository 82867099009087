<!-- PageStructure.vue - Component for handling overall shared page structure -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    <!-- Begin Page Structure -->
     <div class="container">
        <!-- Page Title -->
        <div class="row">
            <div class="col">
                <div class="d-inline-block">
                    <h2><span v-if="restore">Deleted </span>{{ pageTitle | capitalize }}</h2>
                </div>
                <slot name="after-title"></slot>
            </div>
        </div>
        
        <div v-if="authorized" id="table-filter" class="row mb-3">
            <div class="col">
                <slot name="sub-nav"></slot>
            </div>
        </div>
        
        <!-- Top Buttons -->
        <div v-if="!restore && authorized && scope == 'admin' && hasTopButtons" class="row mb-4 mt-3">
            <div class="col">
                <slot name="topAdminButtons"></slot>
            </div>          
        </div>
        
        <!-- Status Indicator -->
        <record-status
            v-bind:authorized="authorized"
            v-bind:authFunction="authFunction"
            v-bind:loading="loading"
            v-bind:showDetails="showDetails"
            v-bind:start="start"
            v-bind:end="end"
            v-bind:count="count"
            v-bind:restore="restore"
            v-bind:pluralNoun="pluralNoun"
            v-bind:limit="limit"
            v-bind:grouped="groupedBy != null"
            v-on:updated-limit="$emit('updated-limit', arguments[0])"
        >
            <slot name="extra-record-status"></slot>
        </record-status>
        
        <!-- Records Updated Indicator -->
        <updated-message
            v-bind:showMessage="showUpdateMessage"
            v-on:update:showMessage="$emit('update:showUpdateMessage', $event)"
            v-bind:count="updateCount"
            v-bind:action="action"
            v-bind:noun="noun"
        ></updated-message>
        
        <!-- Record Table -->
        <div v-if="authorized" class="row">
            <div class="col">
                
                <!-- Standard Table Format -->
                <div v-if="groupedBy == null" class="table-responsive">
                    
                    <!-- Display using BootstrapVue component -->
                    <template v-if="useBootstrapTable">
                        <b-table
                            v-bind:items="items"
                            v-bind:fields="fieldsWithSelect"
                            v-bind:sort-by="sortedBy"
                            head-variant="light"
                            v-bind:small="true"
                            v-bind:striped="true"
                            v-bind:tbody-tr-class="rowClass"
                            primary-key="id"
                            hover
                            selectable
                            no-sort-reset
                            ref="itemTable"
                            v-model="currentItems"
                            v-on:row-selected="rowSelected"
                            no-select-on-click
                            selected-variant=""
                            v-bind:sort-desc="sortDesc"
                            v-bind:per-page="limit"
                            v-bind:current-page="currentPage"
                            v-bind:busy="loading"
                        >
                            <template v-slot:head(select)>
                                <b-form-checkbox
                                    v-model="selectAllStatus"
                                    v-on:change="selectAllStatus ? $refs.itemTable.selectAllRows() : $refs.itemTable.clearSelected()"
                                >
                                </b-form-checkbox>
                            </template>
                            <template v-slot:head()="data">
                                <span class="field-label">
                                    <slot
                                        name="header-formatter"
                                        v-bind:column="data.column"
                                        v-bind:label="data.label"
                                        v-bind:field="data.field"
                                    >
                                    </slot>
                                </span>
                            </template>
                            <template v-slot:cell(select)="{ rowSelected, selectRow, unselectRow }">
                                <template>
                                    <b-form-checkbox
                                        v-bind:checked="rowSelected"
                                        v-on:change="rowSelected ? unselectRow() : selectRow()"
                                    >
                                    </b-form-checkbox>
                                </template>
                            </template>
                            <template v-slot:cell()="data">
                                <slot
                                    name="cell-formatter"
                                    v-bind:item="data.item"
                                    v-bind:value="data.value"
                                    v-bind:field="data.field"
                                >
                                </slot>
                            </template>
                            <template v-slot:table-busy>
                                <div class="text-center text-primary my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                </div>
                            </template>
                        </b-table>
                    </template>
                    
                    <!-- Display using legacy (manual) method -->
                    <template v-else>
                        <table v-if="initialLoadComplete" v-bind:id="noun + '-table'" class="table table-hover table-sm table-striped">
                            <thead class="thead-light">
                            <tr ref="tablehead">
                                <th v-if="includeSelectColumn">
                                    <b-form-checkbox v-if="showSelectAll" v-on:change="selectAll" v-model="selectAllStatus"></b-form-checkbox>
                                </th>
                                <th v-for="column in columns" v-bind:key="column.sort">
                                    <router-link
                                        v-if="column.sortable"
                                        v-bind:to="{ query: { page: 1, sort: column.sort } }"
                                        v-bind:class="{ sorted: sortedBy == column.sort}"
                                    >
                                        {{ column.name }}
                                    </router-link>
                                    <template v-else>{{ column.name }}</template>
                                </th>
                                <slot name="additionalColumn1"></slot>
                            </tr>
                            </thead>

                            <tbody>
                                <loading-record
                                    v-for="index in limit"
                                    v-bind:key="'loading'+index"
                                    v-bind:loading = "loading"
                                    v-bind:showCheck = "includeSelectColumn"
                                    v-bind:columns = "columns.length + (!!$slots.additionalColumn1 && !!$slots.additionalColumn1[0] ? 1 : 0)"
                                    v-bind:skeletonWidth = "skeletonWidth"
                                ></loading-record>
                                <slot name="records"></slot>
                            </tbody>
                        </table>
                        
                        <!-- Initial load indicator -->
                        <div v-else>
                            <div class="text-center text-primary my-2">
                                <b-spinner class="align-middle"></b-spinner>
                            </div>
                        </div>
                    </template>
                </div>
                
                <!-- Grouped Table Format -->
                <div v-else class="table-responsive grouped mt-n3">
                    <div v-if="loading" class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                    </div>
                    <table class="table table-hover table-sm table-striped">
                        <tbody v-for="( items, group, index ) in groupedItems" v-bind:key="group">
                            <tr class="item-cat">
                                <td colspan=12>
                                    <div class="container">                                     
                                        <div class="row justify-content-end mt-4">
                                            <div class="col-4 text-center">
                                                <strong>{{ groupedBy | capitalize }}:</strong> <span v-bind:class='{inactive: (items[0].group !== undefined) && !items[0].group.active}'>{{ group != 'null' ? group : '[None]' }}</span>
                                            </div>
                                            <div class="col-4 pr-0 text-right">
                                                <slot name="group-status" v-bind:group="group" v-bind:items="items"></slot>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr class="thead-light">
                                <th v-if="includeSelectColumn">
                                    <b-form-checkbox v-on:change='selectAll' v-model='selectAllStatus' v-if="showSelectAll && index == 0"></b-form-checkbox>
                                </th>
                                <th v-for="column in groupedColumns" v-bind:key="column.sort">
                                    <router-link
                                        v-if="column.sortable"
                                        v-bind:to="{ query: { page: 1, sort: column.sort } }"
                                        v-bind:class="{ sorted: sortedBy == column.sort}"
                                    >
                                        {{ column.name }}
                                    </router-link>
                                    <template v-else>{{ column.name }}</template>
                                </th>
                            </tr>
                            <slot name="grouped-records" v-bind:items="items"></slot>  
                        </tbody>
                    </table>
                </div>
            
            <!-- End Record Table -->
            </div>
        </div>
        
        <!-- Bottom Buttons -->
        <div class="row mb-4">
            <div class="container">
                <div class="row justify-content-between">
                    <!-- Lefthand Buttons -->
                    <div class="col-lg-2 col-md-3 ">
                        <slot
                            v-if="authorized && count > 0 && includeSelectColumn"
                            name="bottom-left-admin-buttons"
                        ></slot>
                    </div>
                    <div class="col-lg-8 col-md-6">
                        <page-nav
                            v-if="groupedBy == null && lastPage > 1 && limit != 0"
                            v-bind:currentPage = "currentPage"
                            v-bind:lastPage = "lastPage"
                            v-bind:additionalQueries = "additionalQueries"
                        ></page-nav>
                    </div>
                    <!-- Righthand Buttons -->
                    <div class="col-lg-2 col-md-3 text-right">
                        <slot
                            v-if="authorized && count > 0 && includeSelectColumn"
                            name="bottom-right-admin-buttons"
                        ></slot>
                    </div>      
                </div>
            </div>
        </div>
        
        <!-- Page Navigation -->
        
        
    <!-- End Page Structure -->
    </div>
</template>


<script>

import RecordStatus from './RecordStatus';
import UpdatedMessage from './UpdatedMessage';
import LoadingRecord from './LoadingRecord';
import PageNav from './PageNav';

export default {
    
    data(){
        return{
            selectAllStatus: false,
            isChecked: [],
            currentItems: []
        }
    },
    
    components: {
        'record-status': RecordStatus,
        'updated-message': UpdatedMessage,
        'page-nav': PageNav,
        'loading-record': LoadingRecord
    },
    
    
    props: {
        noun: String,
        pluralNoun: String,
        title: String,
        restore: Boolean,
        authorized: Boolean,
        authFunction: Function,
        scope: String,
        loading: Boolean,
        showDetails: Boolean,
        start: Number,
        end: Number,
        count: Number,
        showUpdateMessage: Boolean,
        updateCount: Number,
        action: String,
        sortedBy: [String, Boolean],
        groupedBy: [String, Boolean],
        groupedItems: Object,
        columns: Array,
        groupedColumns: Array,
        limit: Number,
        currentPage: Number,
        lastPage: Number,
        additionalQueries: Object,
        skeletonWidth: String,
        showSelectAll: Boolean,
        selectAllCallback: Function,
        selectAllIsChecked: [Boolean, Number],
        includeSelectColumn: Boolean,
        useBootstrapTable: Boolean,
        items: Array,
        fields: Array,
        sortBy: String,
        statusChecker: Function,
        selected: Array,
        sortDesc: Boolean,
        initialLoadComplete: Boolean
    },
    
    computed: {
        hasTopButtons: function() {
            return !!this.$slots.topAdminButtons && !!this.$slots.topAdminButtons[0];
        },
        
        pageTitle: function() {
            return (this.title && !this.restore) ? this.title : this.pluralNoun;
        },
        
        fieldsWithSelect: function() {
            if (this.includeSelectColumn) {
                return ['select'].concat(this.fields);
            }
            else {
                return this.fields;
            }
        }
    },
    
    methods: {
        selectAll() {
            this.$emit('update:selectAllIsChecked', this.selectAllStatus);
            this.$emit('selectAll');
            console.log(this.selectAllStatus);
        },
        
        rowClass: function(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            
            let status = this.statusChecker(item);
            
            if (status == 'missing') {
                return 'table-warning missing';
            }
            else if (status == 'overdue') {
                return 'table-danger out';
            }
            else if (status == 'out' || this.status == 'assigned') {
                return 'out';
            }
            else if (status == 'completed') {
                return '';
            }
            else {
                return '';
            }
        },
         
        rowSelected: function(selectedItems) {
            this.selectAllStatus = (selectedItems.length > 0 && selectedItems.length == this.currentItems.length);
            let selected = [];
            selectedItems.forEach(item => {
                selected[item.id] = true;
            })
            this.$emit('update:selected', selected);
        }
    },
    
    watch: {
        selectAllIsChecked: function() {
            console.log(this.selectAllIsChecked);
            this.selectAllStatus = this.selectAllIsChecked;
        }
        
        
    }
}

</script>

<style>

#table-filter a.active {
    font-weight: bold;
    color: black
}

#table-filter a {
    color: #007bff
}

.table.b-table > thead > tr > th .field-label::after {
    content: "\2022";
    visibility: hidden;
}

.table.b-table > thead > tr > th[aria-sort=ascending] .field-label::after,
.table.b-table > thead > tr > th[aria-sort=descending] .field-label::after {
    visibility: visible;
}

.table.b-table > thead > tr > [aria-sort=ascending],
.table.b-table > thead > tr > [aria-sort=descending],
.table.b-table > thead > tr > [aria-sort=none] {
    background-image:none !important;
}

span.inactive {
    text-decoration:line-through;
}

</style>