<!-- EmailSettings.vue - Component for showing Notification Settings dialog -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    <b-modal
        v-bind:id="modal"
        v-bind:title="title" 
        v-on:ok="handleSaveSettings"
        v-on:show="handleShow"
        ok-title="Save"
        size="md"
        v-bind:ok-disabled="disabled"
        v-bind:cancel-disabled="disabled"
    >
        <p>
            The Inventory Box can send a message to users when they've checked out
            an item as well as daily reminders when an item is overdue.
            Use this form to customize the notifications.
        </p>
        <small>
            You may leave a message box blank if you'd prefer not to send the reminder.
        </small>
        <b-card no-body class="mt-4">
            <b-tabs card>
                <b-tab title="Messages" active>
                    <b-card-text>
                        <b-form-group
                            label="Sender Name:"
                            label-for="sender-name"
                        >
                            <b-form-input
                                id="sender-name"
                                v-model="items.sender"
                                size="sm"
                                placeholder="Inventory Box"
                            ></b-form-input>
                        </b-form-group>
        
                        <b-form-group
                            label="Reply-To Email:"
                            label-for="reply-to"
                            v-bind:state="replyToState"
                            invalid-feedback="Invalid email!"
                        >
                            <b-form-input
                                id="reply-to"
                                v-model="items.replyTo"
                                size="sm"
                                type="email"
                                v-bind:state="replyToState"
                                v-on:input="replyToState = null"
                                placeholder="admin@example.com"
                            ></b-form-input>
                        </b-form-group>
        
                        <b-form-group
                            label="New Checkout Message:"
                            label-for="checkout-message"
                            description="Available placeholders: {PERSON}, {ITEM}"
                        >
                            <template v-slot:description>
                                Available placeholders: {PERSON}, {ITEMS}. 
                                <b-link href="#" v-on:click="items.message = defaultMessage">Use Default</b-link>
                            </template>
                            <b-form-textarea
                                id="checkout-message"
                                v-model="items.message"
                                rows=5
                                size="sm"
                            ></b-form-textarea>
                        </b-form-group>
        
                        <b-form-group
                            label="Overdue Message:"
                            label-for="overdue-message"
                        >
                            <template v-slot:description>
                                Available placeholders: {PERSON}, {ITEM}. 
                                <b-link href="#" v-on:click="items.overdueMessage = defaultOverdue">Use Default</b-link>
                            </template>
                            <b-form-textarea
                                id="overdue-message"
                                v-model="items.overdueMessage"
                                rows=5
                                size="sm"
                            ></b-form-textarea>
                        </b-form-group>
                    </b-card-text>
                </b-tab>
                
                <b-tab title="Recipients">
                    <b-card-text>
                        <p>By default, notifications will only be sent to the user who checked out the device.</p>
                        <b-form-group
                            label="Also send these notifications to..."
                        >
                            <b-form-checkbox
                                id="copy-group-admins"
                                v-model="items.copyGroupAdmins"
                                name="copy-group-admins"
                                v-bind:value=true
                                v-bind:unchecked-value=false
                            >
                                Group supervisor
                            </b-form-checkbox>
                            <b-form-checkbox
                                id="copy-admins"
                                v-model="items.copyAdmins"
                                name="copy-admins"
                                v-bind:value=true
                                v-bind:unchecked-value=false
                            >
                                Administrator(s)
                            </b-form-checkbox>
                        </b-form-group>
                        <b-form-group
                            label="Send these copies for..."
                        >
                            <b-form-radio
                                id="include-checkouts"
                                v-model="items.excludeCheckouts"
                                name="exclude-checkouts"
                                v-bind:value=false
                            >
                                Checkouts and overdue items
                            </b-form-radio>
                            <b-form-radio
                                id="exclude-checkouts"
                                v-model="items.excludeCheckouts"
                                name="exclude-checkouts"
                                v-bind:value=true
                            >
                                Overdue items only
                            </b-form-radio>
                        </b-form-group> 
                    </b-card-text>
                </b-tab>
                
                <b-tab title="SMTP Configuration" v-if="items.useSMTP">
                    <b-card-text>
                        <b-form-group v-slot="{ ariaDescribedby }">
                            <b-form-radio-group
                                v-model="xoauthChosen"
                                id="smtp-xoauth"
                                v-bind:aria-describedby="ariaDescribedby"
                            >
                                <b-form-radio v-bind:value="true">Google / Yahoo / Microsoft</b-form-radio>
                                <b-form-radio v-bind:value="false">Other</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        
                        <template v-if="xoauthChosen">
                            <div v-if="items.useSMTP != 2">
                                <ul>
                                    <li><b-link
                                        v-on:click.prevent="oauthLogin('google')"
                                    >
                                        Log in with Google
                                    </b-link></li>
                                    <li><b-link
                                        v-on:click.prevent="oauthLogin('microsoft')"
                                    >
                                        Log in with Microsoft
                                    </b-link></li>
                                </ul>
                                <p class="mt-2"><small>
                                    <strong>Note:</strong> You must save any changes
                                    you've made to your notification settings
                                    before attempting to log in to your email provider.
                                </small></p>
                            </div>
                            <div v-else>
                                <p><strong>Username:</strong> {{ items.account }}</p>
                                <p><small>
                                    To disconnect and/or reauthorize your email
                                    account, please choose "Other" and then click "Save."
                                </small></p>
                            </div>
                        </template>
                        
                        <template v-else>
                            <b-form-group
                                label="Host:"
                                label-for="smtp-host"
                            >
                                <b-form-input
                                    id="smtp-host"
                                    v-model="items.host"
                                    size="sm"
                                    placeholder="smtp.example.com"
                                ></b-form-input>
                            </b-form-group>
                        
                            <b-form-group
                                label="Port:"
                                label-for="smtp-port"
                            >
                                <b-form-input
                                    id="smtp-port"
                                    v-model="items.port"
                                    size="sm"
                                    placeholder="587"
                                ></b-form-input>
                            </b-form-group>
                        
                            <b-form-group label="Security:" v-slot="{ ariaDescribedby }">
                                <b-form-radio-group
                                    v-model="items.security"
                                    id="smtp-security"
                                    v-bind:aria-describedby="ariaDescribedby"
                                >
                                    <b-form-radio value="ssl">SSL</b-form-radio>
                                    <b-form-radio value="tls">TLS</b-form-radio>
                                    <b-form-radio value="none">None</b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                            <b-form-group
                                label="Username:"
                                label-for="smtp-account"
                            >
                                <b-form-input
                                    id="smtp-account"
                                    v-model="items.account"
                                    size="sm"
                                ></b-form-input>
                            </b-form-group>
                        
                            <b-form-group
                                label="Password:"
                                label-for="smtp-password"
                                description="Hidden for privacy. Leave blank if no changes needed."
                            >
                                <b-form-input
                                    id="smtp-password"
                                    v-model="items.password"
                                    size="sm"
                                    type="password"
                                ></b-form-input>
                            </b-form-group>
                        
                            <b-form-group
                                label="Email Address:"
                                label-for="smtp-email"
                            >
                                <b-form-input
                                    id="smtp-email"
                                    v-model="items.address"
                                    size="sm"
                                ></b-form-input>
                            </b-form-group>
                        </template>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>
        <div class="mt-4">
            <p class="text-secondary" v-if="success == 1">Saving...</p>
            <p class="text-danger" v-else-if="success == -1">Failed to update email settings: {{ failReason }}</p>
            <p style="visibility: hidden;" v-else>Status</p>
        </div>
    </b-modal>
</template>


<script>

import settingsDialog from '../mixins/settings-dialog'

export default {

    data() {
        return {
            uri: 'settings/email',
            id: 'save-email',
            modal: 'email-settings',
            title: 'Notification Settings',
            replyToState: null,
            defaultMessage: "Dear {PERSON},\n\nThe following items have been checked out to you:\n\n{ITEMS}\n\nPlease return these items as soon as possible. Thank you!",
            defaultOverdue:"Dear {PERSON},\n\nThe following item is overdue:\n\n{ITEM}\n\nPlease return this item as soon as possible. Thank you!",
            xoauthChosen: 0
        }
    },
    
    props: {
        isKiosk: Boolean
    },
    
    computed: {
        fetchedUseSMTP: function() {
            return this.items.useSMTP;
        }
    },
    
    watch: {
        fetchedUseSMTP: function() {
            this.xoauthChosen = (this.fetchedUseSMTP == 2 ? true : false);
        }
    },
    
    mixins: [settingsDialog],
    
    methods: {

        saveSettingsValidator: function() {
            // Email regex (borrowed from Vue.js cookbook)
            // https://vuejs.org/v2/cookbook/form-validation.html
            const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (this.items.replyTo != '' && this.items.replyTo != null && !regexEmail.test(this.items.replyTo)) {
                this.replyToState = false;
                return false;
            }
            
            let saveData = {
                useSMTP: this.xoauthChosen ? -1 : 1,
                host: this.items.host == null ? '' : this.items.host,
                port: this.items.port == null ? '' : this.items.port,
                security: this.items.security == null ? '': this.items.security,
                account: this.items.account == null ? '' : this.items.account,
                password: this.items.password == null ? '' : this.items.password,
                address: this.items.address == null ? '' : this.items.address,
                sender: this.items.sender == null ? '' : this.items.sender,
                replyTo: this.items.replyTo == null ? '' : this.items.replyTo,
                message: this.items.message == null ? '' : this.items.message,
                overdueMessage: this.items.overdueMessage == null ? '' : this.items.overdueMessage,
                copyAdmins: this.items.copyAdmins,
                copyGroupAdmins: this.items.copyGroupAdmins,
                excludeCheckouts: this.items.excludeCheckouts == null ? false : this.items.excludeCheckouts
            }
            
            return saveData;
        },
        
        validateResponse: function(response) {
            return (response.data.sender == this.items.sender && response.data.replyTo == this.items.replyTo && response.data.copyAdmins == this.items.copyAdmins && response.data.copyGroupAdmins == this.items.copyGroupAdmins && response.data.excludeCheckouts == this.items.excludeCheckouts);
        },
        
        oauthLogin: function(provider) {
            // Build the authorization URL
            var url = process.env.VUE_APP_API_BASE + '/' + this.$clientPath
                + '/auth/email'
                + "?provider="
                + provider
            ;
            
            // Redirect to the authorization server
            window.location = url;
        }
    }
}
</script>