<!-- AddNewItem.vue - Component for "New Item" interface -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    <div id="add-new-button">
        <b-button size="sm" variant="outline-primary" v-on:click="showAddNew">Add New Item</b-button>
        <b-modal
            id="add-new"
            title="Add New Item"
            v-on:ok="handleAddNewSaveButton"
            v-on:show="resetAddNew"
            ok-title="Save"
            v-bind:ok-disabled="disabled"
            v-bind:cancel-disabled="disabled"
        >
            <form method="post"> 
                <b-container fluid>
                    <b-row class="my-3">
                        <b-col sm="3">
                          <label for="input-id">Item #:</label>
                        </b-col>
                        <b-col sm="3">
                          <b-form-input id="input-id" size="sm" v-model="newItemNumber" maxlength=5></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="my-3">
                        <b-col sm="3">
                          <label for="input-desc">Description:</label>
                        </b-col>
                        <b-col sm="6">
                          <b-form-input id="input-desc" size="sm" v-model="newItemDescription" maxlength=20></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="my-3">
                        <b-col sm="3">
                          <label for="input-cat">Category:</label>
                        </b-col>
                        <b-col sm="6">
                          <b-form-select
                            id="input-cat" size="sm"
                            v-model="newItemCategory"
                            v-bind:options="newItemCategoryOptions"
                          >
                          </b-form-select>
                        
                        </b-col>
                    </b-row>
                    <b-row v-if="error != ''">
                        <p class="text-danger"> {{ error }}</p>
                    </b-row>
                </b-container>
            </form>
        </b-modal>
    </div>
</template>

<script>

import netfail from '../mixins/netfail'

export default {
    data(){
        return {
            existingItems: [],
            newItemNumber: '',
            newItemDescription: '',
            newItemCategory: '',
            newItemCategoryOptions: [
              { value: null, text: '' },
            ],
            error: '',
            disabled: false
        }
    },
    
    props: {
        lastCommand: String,
        expire: Date
    },
    
    mixins: [netfail],
    
    created: function() {
        // Get category list
        this.axios.get('categories?sort=order')
            .then((response) => {
                response.data.forEach(category => {
                    this.newItemCategoryOptions.push({
                        value: category.id,
                        text: category.name 
                    });
                })
            })
            .catch(() => {
                console.log('Network Failure');
                if (!this.networkFalure) this.networkFailure = true;
            });
        
        // Get active and inactive items
        this.axios.get('assets?limit=1000')
            .then((response) => {
                response.data.forEach(asset => {
                    this.existingItems.push(asset.id);
                });
            })
            .catch(() => {
                if (!this.networkFalure) this.networkFailure = true;
            });
        this.axios.get('assets?active=0&limit=1000')
            .then((response) => {
                response.data.forEach(asset => {
                    this.existingItems.push(asset.id);
                });
            })
            .catch(() => {
                if (!this.networkFalure) this.networkFailure = true;
            });
    },
    
    mounted: function() {
    
        // If the user was trying to add a new item before reauthorization, resume
        if (this.lastCommand == 'add-new-item') {
            this.showAddNew();
            this.$emit('resumed');
        }
    },
    
    methods: {
        
        showAddNew() {
            const currentTime = new Date();
            if ( (this.expire.getTime() - currentTime.getTime()) <= 0 ) {
                this.$emit('checkAuth', {
                    lastCommand: 'add-new-item',
                    lastInput: ''
                });
            }
            
            else {
                this.$bvModal.show('add-new');
            }
        },
        
        resetAddNew() {
            // Clear error
            this.error = '';
        },
        
        handleAddNewSaveButton(bvModalEvt) {
            
            this.disabled = true;
            
            // Override default close behavior
            bvModalEvt.preventDefault();
            
            // Submit the new item
            this.handleAddNewSubmit();
            
        },
        
        handleAddNewCancelButton() {
                    
            // Clear error
            this.error = '';
            
        },
        
        handleAddNewSubmit() {
            
            // Ensure item number has been entered
            if (this.newItemNumber == '') {
                this.error = 'Item # is required!'
                this.disabled = false;
                return;
            }
            
            // Ensure unique item number
            if (this.existingItems.includes(Number(this.newItemNumber))) {
                this.error = 'Item # must be unique!';
                this.disabled = false;
                return;
            }
            
            // Ensure item number is between 1 and 1000
            var n = Number(this.newItemNumber);
            if (n < 1 || n > 1000) {
                this.error = 'Item # must an integer between 1 and 1000!';
                this.disabled = false;
                return;
            }
            
            // Ensure description has been entered
            if (this.newItemDescription == '') {
                this.error = 'Description is required!'
                this.disabled = false;
                return;
            }
            
            // Ensure description is correct format
            const regex = RegExp('^[a-zA-Z0-9 ]*$');
            if (!regex.test(this.newItemDescription)) {
                this.error = 'Description may contain only letters, numbers, and whitespace!'
                this.disabled = false;
                return;
            }
            
            // Ensure category has been entered
            if (this.newItemCategory == '') {
                this.error = 'Category is required!'
                this.disabled = false;
                return;
            }            
           
            // Submit new item to API
            let uri = 'assets';
            this.axios.post(uri, {
                id: this.newItemNumber,
                description: this.newItemDescription,
                category: this.newItemCategory
            })
            .then((response) => {
                console.log(response);
                
                // Clear inputs
                this.newItemNumber = '',
                this.newItemDescription = '',
                this.newItemCategory = '',
                
                // Close the modal
                this.$bvModal.hide('add-new')
                
                // Send a success notification to the parent
                this.$emit('added', 1);
                
                // Reenable buttons for next submission
                this.disabled = false;
                
                // Display confirmation
                
                /*
                this.$bvModal.msgBoxOk('Item #' + response.data.id + 
                    ' (' + response.data.description + ') has been added to the inventory.',
                    {
                        title: 'Success!',
                        size: 'md',
                        buttonSize: 'sm',
                        okVariant: 'success'
                    }
                );
                */
            })
            .catch((error) => {
                if (error.response) {
                    this.$bvModal.hide('add-new'); 
                    // Display confirmation
                    this.$bvModal.msgBoxOk(error.response.data.reason_phrase,
                        {
                            title: 'Error Saving Item',
                            size: 'md',
                            buttonSize: 'sm',
                            okVariant: 'warning'
                        }
                    ).then( () => {
                        this.$bvModal.show('add-new');
                    });
                }
                else {
                    this.$bvModal.msgBoxOk('New item could not be saved. Please try again.',
                        {
                            title: 'Something went wrong...',
                            size: 'md',
                            buttonSize: 'sm',
                            okVariant: 'warning'
                        }
                    ).then( () => {
                        this.$bvModal.show('add-new');
                    });
                }
                
                // Reenable buttons for next submission
                this.disabled = false;
                
            });
        }
        

    
    }
    
    
}
</script>

<style>

</style>
