<!-- CheckoutSettings.vue - Component for displaying Checkout Settings interface -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    <b-modal
        v-bind:id="modal"
        title="Checkout Settings" 
        v-on:ok="handleSaveSettings"
        v-on:show="handleShow"
        ok-title="Save"
        size="md"
        v-bind:ok-disabled="disabled"
        v-bind:cancel-disabled="disabled"
    >
        <b-form-group
            label="Checkout Message:"
            label-for="checkout-message"
            description="The Inventory Box can display a message to users after a successful checkout.
                         This is useful for showing policy info or providing a reminder about when to return an item.
                         Basic HTML customization is allowed. Leave this box blank if you'd prefer not to display a message."
        >
            <b-form-textarea
                id="checkout-message"
                v-model="items.checkoutMessage"
                rows=5
                size="sm"
            ></b-form-textarea>
        </b-form-group>
        
        <b-form-group
            class="mt-5"
            description='Turn on this setting to allow a single user to check out or check in more than one item at the same time.'
        >
            <b-form-checkbox
                id="allow-multiple"
                v-model="items.allowMultiple"
                name="allow-multiple"
                v-bind:value=true
                v-bind:unchecked-value=false
            >
                Allow multiple items per checkout
            </b-form-checkbox>
        </b-form-group>        
        
        <b-form-group
            description='Check this box to enable basic task management features.
                         When turned on, each checkout will be associated with an "assignment."
                         Assignments are not considered complete until the item is checked in
                         and a supervisor has reviewed the task. (A new "Mark Complete"
                         button will appear on the Checkout/Assignment page.)'
            >
            <b-form-checkbox
                id="enable-tracking"
                v-model="items.enableTracking"
                name="enable-tracking"
                v-bind:value=true
                v-bind:unchecked-value=false
                v-on:change="checkVirtualStatus"
            >
                Enable assignment tracking
            </b-form-checkbox>
        </b-form-group>
        
        <b-form-group
            description='If this box is checked, a "No item needed" option appears when making a checkout selection.
                         This could be helpful if you wish to track assignments that do not require the use of
                         items from the inventory.'

        >
            <b-form-checkbox
                id="itemless"
                v-model="items.allowVirtual"
                name="allow-virtual"
                v-bind:value=true
                v-bind:unchecked-value=false
                v-bind:disabled="!items.enableTracking"
            >
                Allow assignments without items
            </b-form-checkbox>
        </b-form-group>
        
        <p class="text-secondary" v-if="success == 1">Saving...</p>
        <p class="text-danger" v-else-if="success == -1">Failed to update checkout settings: {{ failReason }}</p>
        <p style="visibility: hidden;" v-else>Status</p>
    </b-modal>
</template>


<script>

import settingsDialog from '../mixins/settings-dialog'
import HtmlSanitizer from '@jitbit/htmlsanitizer'

export default {

    data() {
        return {
            uri: 'settings/checkout',
            id: 'save-checkout',
            modal: 'checkout-settings',
            title: 'Checkout Settings',
        }
    },
    
    props: {
        expire: [Object, Date],
        lastCommand: String,
        lastInput: [String, Array, Object]
    },
    
    mixins: [settingsDialog],
    
    computed: {
        
    },
    
    watch: {

    },
    
    methods: {
    
        checkVirtualStatus: function(checked) {
            if (!checked) {
                this.items.allowVirtual = false;
            }
        },
    
        saveSettingsValidator: function() {
            let saveData = {
                checkoutMessage: this.items.checkoutMessage == null ? '' : HtmlSanitizer.SanitizeHtml(this.items.checkoutMessage),
                enableTracking: this.items.enableTracking === true ? true : false,
                allowVirtual: this.items.allowVirtual === true ? true : false,
                allowMultiple: this.items.allowMultiple === true ? true : false
            }
            
            return saveData;
        },
        
        validateResponse: function(response) {
            let valid = ( (response.data.allowVirtual == this.items.allowVirtual) && (response.data.enableTracking == this.items.enableTracking) && (response.data.allowMultiple == this.items.allowMultiple) );
            // If settings were updated successfully, report the new settings to parent controller
            if (valid) {
                this.$emit('updateSettings', {
                    checkoutMessage: response.data.checkoutMessage,
                    enableTracking: response.data.enableTracking,
                    allowVirtual: response.data.allowVirtual,
                    allowMultiple: response.data.allowMultiple
                });
            }
            return valid;
        }
    }
}
</script>