<!-- CheckoutForm.vue - Component for walking user through checkout process -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    <b-modal
        v-bind:id="id"
        v-bind:title="title"
        v-on:ok="handleContinueButton"
        v-on:cancel="handleCancelButton"
        v-on:close="handleCancelButton"
        v-on:show="reset"
        v-bind:ok-disabled="disabled"
        v-bind:cancel-disabled="disabled"
        v-bind:ok-title="continueText"
        v-bind:cancel-title="(phase == steps.CONFIRM_CHECKIN) ? 'No' : 'Cancel'"
        v-bind:no-close-on-backdrop = 'this.phase == this.steps.PROCESSING'
        v-bind:ok-variant="okVariant"
        v-bind:ok-only="(phase == steps.ERROR || phase == steps.SUCCESS || phase == steps.EMPTY)"
        v-bind:centered="alwaysCentered || centered"
        size="md"
    >  
    
        <div v-if="phase == steps.LOADING_ITEMS">
            <p>One moment please...</p>
        </div>
        
        
        <div v-else-if="phase == steps.SELECT_ITEM">
            <p>Please select {{ allowMultiple ? 'one or more items' : 'an item' }} below to <strong>{{ actionText }}:</strong></p>
            <div id="special-options" v-if="type=='out' && allowVirtual">
                <strong>Special:</strong>
                <div class="row mt-2">
                    <div class="col">
                        <b-form-group>
                            <b-form-checkbox
                                v-if="allowMultiple"
                                v-model="selected"
                                v-on:change="if (selected == -1) selectedArray = []"
                                name="items"
                                value="-1"
                                class="ml-4"
                            >
                                No {{ allowMultiple ? 'items' : 'item' }} needed (I will use my own)
                            </b-form-checkbox>
                            <b-form-radio
                                v-else
                                v-model="selected"
                                name="items"
                                value="-1"
                                class="ml-4"
                            >
                                No {{ allowMultiple ? 'items' : 'item' }} needed (I will use my own)
                            </b-form-radio>
                        </b-form-group>
                    </div>
                </div>
            </div>
            <div id="item-options" v-for="( assets, category ) in groupedItems(groupKey)" v-bind:key="category">
                <strong>{{ category != 'null' ? category : '[None]' }}:</strong>
                <div class="row mt-2">
                    <div class="col">
                        <b-form-group v-if="allowMultiple">
                            <b-form-checkbox
                                v-for="asset in assets"
                                v-bind:key="asset.id"
                                v-model="selectedArray[asset.id]"
                                v-on:change="if (selectedArray[asset.id]) selected = 0"
                                name="items[]"
                                class="ml-4"
                            >
                                {{ asset.id }} - {{ asset.description }}
                            </b-form-checkbox>
                        </b-form-group>
                        <b-form-group v-else>
                            <b-form-radio
                                v-for="asset in assets"
                                v-bind:key="asset.id"
                                v-model="selected"
                                name="items"
                                v-bind:value="asset.id"
                                class="ml-4"
                            >
                                {{ asset.id }} - {{ asset.description }}
                            </b-form-radio>
                        </b-form-group>
                    </div>
                </div>
            </div>
        </div>
        
        
        
        <div v-else-if="phase == steps.PROVIDE_USER">
            <h6 v-if="!allowMultiple">{{ selectedItemName }}</h6>
            <div class="row">
                <div class="col">
                    <div v-if="selected == -1">
                        <p>Please enter your assignment details below.</p>
                    </div>
                    <div v-else-if="allowMultiple">
                        <p>You have selected the following items for <strong>{{ actionText }}</strong>:</p>
                        <ul>
                            <template v-for="(checked, selection) in selectedArray">
                                <li v-if="checked" v-bind:key="selection">{{ selection }} - {{ items.find(item => { return item.id === selection }).description }}</li>
                            </template>
                        </ul>
                        <p>Please fill out the form below to complete your transaction.</p>
                    </div>
                    <div v-else>
                        <p>This item is available for <strong>{{ actionText }}</strong>. Please provide the following information:</p>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>User:</label>
                <vue-typeahead-bootstrap
                    v-model="userSearch"
                    v-bind:data="users"
                    v-bind:serializer="user => (user.firstName + ' ' + user.lastName)"
                    v-on:hit="user = $event"
                    v-bind:showOnFocus="true"
                    v-bind:disableSort="false"
                    v-bind:maxMatches=1000
                    placeholder="Type to search..."
                    ref="userList"
                >
                    <template slot="append">
                        <span v-if="userSearch == ''"></span>
                        <span v-else-if="userSearch == user.firstName + ' ' + user.lastName" class="input-group-text text-success"><font-awesome-icon icon='check'></font-awesome-icon></span>
                        <span v-else class="input-group-text text-danger"><font-awesome-icon icon='times'></font-awesome-icon></span>
                    </template>
                </vue-typeahead-bootstrap>
                <span class="text-danger">{{ userError }}</span>
            </div>
            <div class="form-group">
                <label for="input-note">Reason/Note:</label>
                <b-form-input id="input-note" v-model="note"></b-form-input>
                <span class="text-danger">{{ noteError }}</span>
            </div>
        </div>
        
        
        
        <div v-else-if="phase == steps.CONFIRM_CHECKIN">
            <div class="row mb-3">
                <div class="col">
                    <h6>{{ selectedItemName }}</h6>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p v-if="!selectedArray.length">This item is currently checked out to <strong>{{ selectedItemUser }}</strong>.</p>
                    <p>Do you wish to <strong>check in</strong> this item? Click "Yes" to proceed.</p>
                </div>
            </div>
        </div>
        
        
        
        <div v-else-if="phase == steps.PROCESSING">
            <p>One moment please...</p>
        </div>
        
        
        
        <div v-else-if="phase == steps.SUCCESS" v-html="sanitizedCheckoutMessage">
        </div>
        
        
        <div v-else-if="phase == steps.EMPTY">
            <p>There are currently no items available to {{ actionText }}.</p>
        </div>
        
        <div v-else>
            <p>{{ checkError }}</p>
        </div>
    
    </b-modal>
</template>


<script>

import groupedItems from '../mixins/grouped-items'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import HtmlSanitizer from '@jitbit/htmlsanitizer'


export default {
    
    mixins: [groupedItems],
    
    components: {
        VueTypeaheadBootstrap,
        'font-awesome-icon': FontAwesomeIcon
    },
    
    data() {
        return {
            phase: -1,
            items: [],
            selected: 0,
            selectedArray: [],
            users: [],
            user: 0,
            userSearch: '',
            note: '',
            userError: '',
            noteError: '',
            checkError: '',
            centered: false
        }
    },
    
    props: {
        id: String,
        type: String,
        checkoutMessage: String,
        enableTracking: Boolean,
        allowVirtual: Boolean,
        allowMultiple: Boolean,
        alwaysCentered: Boolean
    },
    
    created: function() {
        // Constants for tracking checkout steps
        this.steps = Object.freeze({
            LOADING_ITEMS: 0,
            SELECT_ITEM: 1,
            LOOKING_UP_ITEM: 2,
            PROVIDE_USER: 3,
            CONFIRM_CHECKIN: 4,
            PROCESSING: 5,
            SUCCESS: 6,
            ERROR: -1,
            EMPTY: -2
        });
        
        library.add(faCheck);
        library.add(faTimes);
        
    },
    
    computed: {
        
        sanitizedCheckoutMessage: function() {
            return HtmlSanitizer.SanitizeHtml(this.checkoutMessage);
        },
        
        
        title: function() {
            switch(this.phase) {
                case(this.steps.LOADING_ITEMS):
                    return 'Loading Item List';
                case(this.steps.SELECT_ITEM):
                case(this.steps.PROVIDE_USER):
                    if (this.type == 'in') {
                        return 'Check In';
                    }
                    else if (this.type == 'out') {
                        return 'Check Out';
                    }
                    else {
                        return 'Invalid Transaction Type';
                    }
                case(this.steps.CONFIRM_CHECKIN):
                    return 'Check In'
                case (this.steps.PROCESSING):
                    return 'Processing ' + this.actionTextNoun;
                case(this.steps.SUCCESS):
                    return 'Checkout Complete';
                case(this.steps.EMPTY):
                    return 'Nothing to ' + this.actionText;
                default:
                    return 'Error';
            }
        },
        
        continueText: function() {
            switch(this.phase) {
                case(this.steps.LOADING_ITEMS):
                case(this.steps.SELECT_ITEM):
                case(this.steps.LOOKING_UP_ITEM):
                    return 'Continue';
                case(this.steps.PROVIDE_USER):
                case(this.steps.PROCESSING):
                    return 'Save';
                case(this.steps.CONFIRM_CHECKIN):
                    return 'Yes'
                default:
                    return 'OK';
            }
        },
        
        okVariant: function() {
            if (this.phase == this.steps.ERROR) {
                return 'warning';
            }
            else if (this.phase == this.steps.SUCCESS) {
                return 'success';
            }
            else if (this.phase == this.steps.EMPTY) {
                return 'info';
            }
            else {
                return 'primary';
            }
        },
        
        disabled: function() {
            if (this.phase == this.steps.LOADING_ITEMS || this.phase == this.steps.PROCESSING) {
                return true;
            }
            else {
                return false;
            }
        },
        
        actionText: function() {
            if (this.type == 'in') {
                return 'check in';
            }
            else if (this.type == 'out') {
                return 'check out';
            }
            else {
                return 'ERROR';
            }
        },
        
        actionTextNoun: function() {
           if (this.type == 'in') {
                return 'Check-In';
            }
            else if (this.type == 'out') {
                return 'Check-Out';
            }
            else {
                return 'ERROR';
            }
        },
        
        groupKey: function() {
            if (this.type == 'in') {
                return 'user';
            }
            else {
                return 'category';
            }
        },
        
        selectedItemName() {
            if (this.selectedArray.length) {
                return 'Multiple Items';
            }
            if (this.selected == -1) {
                return 'Assignment Details';
            }
            else {
                // Get index of selected item
                let i = this.items.findIndex(asset => asset.id == this.selected);
            
                // Return item name
                return 'Item #' + this.items[i].id + ": " + this.items[i].description;
            }
        },
        
        selectedItemUser() {
            // Get index of selected item
            let i = this.items.findIndex(asset => asset.id == this.selected);
            
            // Return item name
            return this.items[i].status.lastTransaction.user.firstName + 
                    ' ' + this.items[i].status.lastTransaction.user.lastName;
        },
        
        selectedItemUserId() {
            // If this is a checkout, the desired user was selected directly, so just get
            // the input.
            if (this.type == 'out') {
                return this.user.id;
            }
            
            // If this was a checkin, we need to look the user up based on the item
            else {
                // Get index of selected item
                let i = this.items.findIndex(asset => asset.id == this.selected);
    
                // Return user id for this item
                return this.items[i].status.lastTransaction.user.id;
            }
        }    
    },
    
    methods: {
        reset: function() {
            // Start us at the first step
            this.phase = this.steps.LOADING_ITEMS;
        
            // Get a list of users to populate the input box
            this.fetchUsers();
            
            // Set things to their defaults
            this.fetchItems();
            this.fetchUsers();
            this.selected = 0;
            this.selectedArray = [];
            this.user = 0;
            this.userSearch = '';
            this.note = '';
            this.userError = '';
            this.noteError = '';
            this.centered = false;
        },
        
        fetchItems() {
            
            // Build URL according to whether this is a checkout or checkin
            let uri = 'assets?limit=1000&sort=category';
            if (this.type == 'out') {
                uri += '&available=in';
            }
            else if (this.type == 'in') {
                uri += '&available=out&getstatus=1';
            }
            
            // Access the API
            this.axios.get(uri)
                .then((response) => {
                    this.items = response.data;
                    if (this.items.length || (this.type == 'out' && this.allowVirtual)) {
                        this.phase = this.steps.SELECT_ITEM;
                    }
                    else {
                        this.phase = this.steps.EMPTY;
                    }
                })
                .catch((error) => {
                    this.phase = this.steps.ERROR;
                    if (error.response && (error.response.status_code == 401)) {
                        this.$parent.access_token = '';
                        this.$parent.isAuthorized = false;
                    }
                });
        },
        
        fetchUsers() {
            // Build URL according to whether this is a checkout or checkin
            let uri = 'users?limit=0';
             
            // Access the API
            // Access the API
            this.axios.get(uri)
                .then((response) => {
                    this.users = response.data.sort((a,b) => {
                        if (a.lastName < b.lastName) {
                            return -1;
                        }
                        if (a.lastName > b.lastName) {
                            return 1;
                        }
                        if (a.firstName < b.firstName) {
                            return -1;
                        }
                        if (a.firstName > b.firstName) {
                            return 1;
                        }
                        return 0;
                    });
                })
                .catch((error) => {
                    this.phase = this.steps.ERROR;
                    if (error.response && (error.response.status_code == 401)) {
                        this.$parent.access_token = '';
                        this.$parent.isAuthorized = false;
                    }
                    else {
                        console.log(error.response);
                    }
                });
        },
        
        handleContinueButton(e) {
            
            this.userError = '';
            this.noteError = '';
            switch(this.phase) {
                case(this.steps.SELECT_ITEM):
                    e.preventDefault();
                    if (this.selected != 0 || this.selectedArray.includes(true)) {
                        if (this.type == 'out') {
                            this.phase = this.steps.PROVIDE_USER;
                        }
                        else if (this.type == 'in') {
                            if (!this.allowMultiple) {
                                this.phase = this.steps.CONFIRM_CHECKIN;
                            }
                            else {
                                e.preventDefault();
                                this.phase = this.steps.PROCESSING;
                                this.user = 
                                this.submit();
                            }
                        }
                        else {
                            this.phase = this.steps.ERROR;
                        }
                    }
                    break;
                case(this.steps.PROVIDE_USER):
                    e.preventDefault();
                    if (this.validateUserInput()) {
                        this.phase = this.steps.PROCESSING;
                        this.submit();
                    }
                    break;
                case (this.steps.CONFIRM_CHECKIN):
                    e.preventDefault();
                    this.phase = this.steps.PROCESSING;
                    this.user = 
                    this.submit();
                    break;
                case (this.steps.ERROR):
                case (this.steps.SUCCESS):
                case (this.steps.EMPTY):
                    break;
                default:
            }
        },
        
        handleCancelButton(e) {
            if (this.phase == this.steps.LOADING_ITEMS || this.phase == this.steps.PROCESSING) {
                e.preventDefault();
            }
        },
        
        validateUserInput() {
            // Make sure a user was properly chosen
            if (this.userSearch == '') {
                this.userError = 'Please select a user!';
                return false;
            }
            else if (this.userSearch != this.user.firstName + ' ' + this.user.lastName) {
                this.userError = 'Invalid user! Please select a user from the provided list.';
                return false;
            }
            else if (this.user.id < 1 || this.user.id > 1000) {
                this.userError = 'Invalid user number!';
                return false;
            }
            
            // Make sure note is valid
            const regex = RegExp('^[a-zA-Z0-9 ]*$');
            if (!regex.test(this.note)) {
                this.noteError = 'Only letters, numbers, and whitespace allowed!'
                return false;
            }
            
            // If we made it this far, all is good!
            return true;            
        },
        
        submit() {
            
            // If we're doing a virtual checkout, ignore the selection array
            if (this.selected == -1) {
                this.submitSingle(-1);
            }
            
            // If the selection array is empty, check to see whether a single item is selected
            else if (!this.selectedArray.length && this.selected ) {
                this.submitSingle(this.selected);
            }
            
            // Otherwise, loop through all items in the selection array
            else {
                const checkedItems = [];
                this.selectedArray.forEach((checked, item) => {
                    // If the item in the checkbox array was selected, then set it as next to process
                    if (checked) {
                        this.selected = item;
                        checkedItems.push({
                            item: this.selected,
                            user: this.selectedItemUserId,
                            type: (this.selected == -1) ? 'virtual' : this.type,
                            note: this.note
                        });
                    }
                });
                
                this.axios.post('checkouts', checkedItems).then((response) => {
                    let event = '';
                    if (this.type == 'in') {
                        event = 'checkin';
                    }
                    else if (this.type == 'out') {
                        event = 'checkout';
                        if (this.selected == -1) {
                            this.$bvModal.hide(this.id);
                        }
                        else {
                            if (this.checkoutMessage != null && this.checkoutMessage != '') {
                                this.centered = true;
                                this.phase = this.steps.SUCCESS;
                            }
                            else {
                                this.$bvModal.hide(this.id);
                            }
                        }
                    }
        
                    this.$emit(event, response.data);
                })
                .catch((error) => {
                    this.$bvModal.show(this.id);
                    this.phase = this.steps.ERROR;
                    this.checkError = error.response.data.reason_phrase;
                });
            
                // Clear selections
                this.selectedArray = [];
                this.selected = 0;
            }
        },
        
        submitSingle(item) {
            this.selected = item;
            this.axios.post('checkouts', {
                item: this.selected,
                user: this.selectedItemUserId,
                type: (this.selected == -1) ? 'virtual' : this.type,
                note: this.note
            })
            .then((response) => {
                let event = '';
                if (this.type == 'in') {
                    event = 'checkin';
                }
                else if (this.type == 'out') {
                    event = 'checkout';
                    if (this.selected == -1) {
                        this.$bvModal.hide(this.id);
                    }
                    else {
                        if (this.checkoutMessage != null && this.checkoutMessage != '') {
                            this.centered = true;
                            this.phase = this.steps.SUCCESS;
                        }
                        else {
                            this.$bvModal.hide(this.id);
                        }
                    }
                }
        
                this.$emit(event, response.data);
            })
            .catch((error) => {
                this.$bvModal.show(this.id);
                this.phase = this.steps.ERROR;
                this.checkError = error.response.data.reason_phrase;
            });
        }
    }
}

</script>


<style>
    #checkout-form h6, #checkin-form h6 {
        font-size: 1.4rem
    }

</style>