<!-- AssetRecord.vue - Component for displaying individual row of inventory list -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    <tr v-bind:class="[rowClass]">
        <td
            v-if="showMark"
        >
            <b-form-checkbox
                v-if="showMark"
                name="selections[]"
                v-model="isChecked"
                v-bind:id="'selection-' + asset.id"
                v-bind:checked="checked"
                v-on:change="$emit('change', isChecked)"
            >
            </b-form-checkbox>
        </td>
        <td>
            <router-link
                v-bind:to="{name: 'Checkouts', query: { item: asset.id, user: null, group: null, show: 'checkouts' }}"
            >
                <span v-on:click.prevent="goToFilteredCheckouts(asset, null, null, 'checkouts')">{{ asset.id }}</span>
            </router-link>
        </td>
        <td>
            <router-link
                v-bind:to="{name: 'Checkouts', query: { item: asset.id, user: null, group: null, show: 'checkouts' }}"
            >
                <span v-on:click.prevent="goToFilteredCheckouts(asset, null, null, 'checkouts')">{{ asset.description }}</span>
            </router-link>
        </td>
        <td>
            <router-link
                v-bind:to="{name: 'Checkouts', query: { item: asset.id, user: null, group: null, show: 'checkouts' }}"
            >
                <span v-on:click.prevent="goToFilteredCheckouts(asset, null, null, 'checkouts')">{{ asset.status.checkouts }}</span>
            </router-link>
        </td>
        <td v-bind:class="{special:(status == 'missing' || status == 'out for repair' || status == 'reserved')}">
            {{ status }}
        </td>
        <td v-bind:title="asset.status.lastTransaction.timestamp">
            {{ this.lastCheckout | prettyDate }}
        <td v-bind:class="userClass">
            <span v-if="userClass != 'special' && userClass != 'inactive'">
                <router-link
                    v-bind:to="{name: 'Checkouts', query: { item: null, user: asset.status.lastTransaction.user.id, group: null, show: 'checkouts'}}"
                >
                    <span v-on:click.prevent="goToFilteredCheckouts(null, asset.status.lastTransaction.user, null, 'checkouts')">{{ userName }}</span>
                </router-link>
            </span>
            <span v-else>
                {{ userName }}
            </span>
        </td>
    </tr>
</template>

<script>

import record from '../mixins/record'
import filterLinks from '../mixins/filter-links'

export default {
    data(){
        return {
            
        }
    },
    
    props: {
        asset: Object, 
    },
    
    mixins: [record, filterLinks],
    
    computed: {
    
        lastCheckout: function() {
            if (this.asset.status.lastTransaction.timestamp == null) {
                return '-';
            }
            else {
                return new Date(this.asset.status.lastTransaction.timestamp.replace(' ', 'T'));
            }
        },
    
        checkoutDate: function() {
            return this.lastCheckout;
        },
        
        status: function () {
            /*
            if (this.asset.status.lastTransaction.type == 'out') {
                if (this.overdue) {
                    return 'overdue';
                }
                else {
                    return 'out';
                }
            }
            */
            /*else*/
            if (this.asset.status.lastTransaction.type == 'added') {
                return 'new';
            }
            else {
                return this.asset.status.lastTransaction.type;
            }
        },
        
        userClass: function() {
            if (this.asset.status.lastTransaction.user.id == 1) {
                return 'special';
            }
            else if (!this.asset.status.lastTransaction.user.active) {
                return 'inactive';
            }
            else {
                return '';
            }
        },
        
        userName: function() {
            return this.asset.status.lastTransaction.user.firstName + ' ' + this.asset.status.lastTransaction.user.lastName
        }
    }
}
</script>

<style>

td.inactive {
    color: gray;
    text-decoration: line-through;
}

td.special, tr.special, tr.special td a {
    color: gray;
}

tr.missing {
    font-style: italic;
}
tr.late {
    color: red;
    font-weight: bold;
}

tr.late a {
    color: red;
}

tr.late a:hover {
    color: blue;
    text-decoration: underline;
}

tr.out{
    color: black;
    font-weight: bold;
}

tr.out a {
    color: black;
}

tr.out a:hover {
    color: blue;
    font-weight: bold;
}

</style>
