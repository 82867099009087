/**
 * update-items.js - Shared code used by components related to updating items
 *
 * @see       https://github.com/teachernerd/inventory/ The Inventory Box GitHub project
 *
 * @author    Daniel Reinish (teachernerd) <dan@reinish.net>
 * @copyright 2016 - 2022 Daniel Reinish
 * @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
 * @note      This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 */
 
/**
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
 */
 
 
export default {
  
  data(){
        return {
            itemsToChange: [],
            itemErrors: [],
            changeCount: 0,
            errorCount: 0,
            disabled: false
        }
    },
    
    props: {
        selectedItems: Array,
        pluralNoun: String,
        noun: String
    },
    
        
    mounted: function() {
        this.$emit('rendered');
    },
    
    methods: {
        
        reset() {
            // Clear error
            this.itemErrors = [];
            this.changeCount = 0;
            this.errorCount = 0;
        },
        
        handleSaveButton(bvModalEvt) {

            // Override default close behavior
            bvModalEvt.preventDefault();
            
            // Disable buttons
            this.disabled = true;
            
            // Clear prior errors
            this.reset();
            
            // Submit the new item
            this.handleSubmit();
            
        },
        
        handleCancelButton() {
            // If anything changed, have the parent refresh.
            // (This can happen if one of the changes failed and the
            // user clicked "cancel" rather than fixing the problem.)
            if (this.changeCount > 0) {
                this.$emit('updated-items', false);
            }
            this.reset();
        },
        
        handleSubmit() {
        
            // Attempt to update each selected item
            this.itemsToChange.forEach((item, index) => {          
               
                // Run validation routines. If errors were caught, stop processing item.
                if (!this.validateInput(item, index)) {                
                               
                    // If we've cycled through everything, reenable the buttons
                    if (this.changeCount + this.errorCount == this.itemsToChange.length) {
                        this.disabled = false;
                    }
                
                    // Stop processing this item
                    return;
                }
           
                // Construct the API url
                var url = '';
                if (item.id != '') {
                    url = this.uriPrefix + '/' + item.id + this.uriSuffix;
                }
                else {
                    url = this.uriPrefix;
                }
                
                const updateObject = this.buildUpdateObject(item);
                //console.log(updateObject);
                
                // Submit the API request
                this.axios({
                    url: url,
                    method: this.updateMethod,
                    data: updateObject
                })
                
                // If successful...
                .then(() => {
                    this.itemErrors[index] = 'Success!';
                    this.$forceUpdate();
                    
                    // If ALL the updates have succeeded, close the modal and notify parent
                    if (++this.changeCount == this.itemsToChange.length) {
                        // Close the modal
                        this.$bvModal.hide(this.id);
                        this.itemsToChange = [];
                        this.disabled = false;
                        // Send a success notification to parent
                        this.$emit('updated-items', this.changeCount);
                        this.reset();
                    }
                    
                    // If we've attempted the final update (even if some didn't work)
                    // reenable the save/cancel buttons
                    else if (this.changeCount + this.errorCount == this.itemsToChange.length) {
                        this.disabled = false;
                    }
                })
                
                // If unsuccessful
                .catch((error) => {
                    this.itemErrors[index] = error.response.data.reason_phrase;
                    //console.log(this.itemErrors);
                    this.$forceUpdate();
                    this.errorCount++;
                                       
                    // If we've cycled through everything, reenable the buttons
                    if (this.changeCount + this.errorCount == this.itemsToChange.length) {
                        this.disabled = false;
                    }
                });
            });
        },

    
    }
    
};