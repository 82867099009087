/**
 * transaction.js - Shared code related to displaying transactions/checkouts
 *
 * @see       https://github.com/teachernerd/inventory/ The Inventory Box GitHub project
 *
 * @author    Daniel Reinish (teachernerd) <dan@reinish.net>
 * @copyright 2016 - 2022 Daniel Reinish
 * @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
 * @note      This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 */
 
/**
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
 */

import FilterBadges from '../components/FilterBadges';
import UpdatedMessage from '../components/UpdatedMessage';

export default {
    
    
    data(){
        return{
            showUpdateMessage: false
        }
    },
    
    computed: {
        specificItem: function() {
            if (typeof this.$route.query['item'] != 'undefined') {
                return Number(this.$route.query['item']);
            }
            else {
                return 0;
            }
        },
        
        specificUser: function() {
            if (typeof this.$route.query['user'] != 'undefined') {
                return Number(this.$route.query['user']);
            }
            else {
                return 0;
            }
        },
        
        specificGroup: function() {
            if (typeof this.$route.query['group'] != 'undefined') {
                return Number(this.$route.query['group']);
            }
            else {
                return 0;
            }
        },
        
        specificNote: function() {
            if (typeof this.$route.query['search'] != 'undefined' && this.$route.query['search'] != '') {
                return this.$route.query['search'];
            }
            else {
                return null;
            }
        },
        
        additionalFilters: function() {
            var filters = '';
            
            // General checkout filters
            if (typeof this.$route.query['show'] != 'undefined' && (this.$route.query['show'] == 'nonreturned' || this.$route.query['show'] == 'completed' || this.$route.query['show'] == 'incomplete' || this.$route.query['show'] == 'checkouts' || this.$route.query['show'] == 'returned' || this.$route.query['show'] == 'grouped')) {
                filters = filters + '&show=' + this.$route.query['show'];
            }
            
            // Item, user, group, and search filters
            if (this.specificItem > 0) {
                filters = filters + '&item=' + this.specificItem;
            }
            if (this.specificGroup > 0) {
                filters = filters + '&group=' + this.specificGroup;
            }
            if (this.specificUser > 0) {
                filters = filters + '&user=' + this.specificUser;
            }
            if (this.specificNote != null) {
                filters = filters + '&search=' + this.specificNote;
            }

            return filters;
        },
        
        specificQueryVars: function() {
            var qv = {};
            
            if (this.specificItem > 0) {
                qv.item = this.specificItem;
            }
            if (this.specificUser > 0) {
                qv.user = this.specificUser;
            }
            if (this.specificGroup > 0) {
                qv.group = this.specificGroup;
            }
            if (this.specificNote != null) {
                qv.search = this.specificNote;
            }
            
            return qv;
        }
        
    },
    
    components: {
        'filter-badges': FilterBadges,
        'updated-message': UpdatedMessage
    },
    
    mounted: function () {
        this.fetchItems();
    },
    
    watch: {
        additionalFilters: function() {
            this.requestMessage = "Loading...";
            this.fetchItems();
        }
    },
};