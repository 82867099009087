<!-- Users.vue - Component for displaying the Users page -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    <div id="users-content">
        <page-structure
            v-bind="editDeletePageProps"
            v-bind:noun="noun"
            v-bind:pluralNoun="pluralNoun"
            v-bind:restore="restore"         
            v-bind:showDetails="true"
            v-bind:showUpdateMessage.sync="showUpdateMessage"
            v-bind:sortedBy="sortedBy"
            v-bind:groupedBy="groupedBy"
            v-bind:groupedItems="groupedItems('group')"
            v-bind:columns="columns"
            v-bind:groupedColumns="columnsWithoutGroup"
            v-bind:additionalQueries = "{ sort: this.$route.query['sort'] }"
            v-bind:showSelectAll = "true"
            v-bind:selectAllIsChecked.sync="selectAllIsChecked"
            v-bind:includeSelectColumn="$parent.scope == 'admin'"
            skeletonWidth="5%"
            v-on:selectAll="selectAll"
            v-bind:useBootstrapTable="localPagination"
            v-bind:items="items"
            v-bind:fields="fields"
            v-bind:statusChecker="status"
            v-bind:selected.sync="selected"
            v-bind:sortDesc="sortedBy == 'itemsOut' || sortedBy == 'lastCheckout' || sortedBy == 'total' || sortedBy == 'completed'"
            v-on:updated-limit="$emit('updated-limit', arguments[0])"
            v-bind:limit="modifiedLimit"
        >
            <template v-slot:topAdminButtons>
                <b-dropdown split text="Add New User" size="sm" variant="outline-primary" v-on:click="handleAddNew">
                    <b-dropdown-item-button v-on:click="handleAddNewMult">Add Multiple Users</b-dropdown-item-button>
                </b-dropdown>
                <add-new-user
                    v-on:added="handleAddedItems"
                    v-on:checkAuth="handleCheckAuth"
                    v-on:rendered="tryAddNewResume"
                >
                </add-new-user>
                <edit-users
                    v-bind:selectedItems="[]"
                    v-on:updated-items="handleAddedItems"
                    id="add-new-mult"
                    title="Add New Users"
                    v-bind:progressive="true"
                    v-on:rendered="tryAddNewMultResume"
                >
                </edit-users>
            </template>   
            
            <template v-slot:header-formatter="head">
                <router-link
                    v-if="head.column=='staff'"
                    v-bind:to="{ query: { page: 1, sort: head.column } }"
                >
                    {{ head.label }}
                </router-link> 
                <template v-else>
                    {{ head.label }}
                </template>
            </template>
            
            <template v-slot:cell-formatter="cell">
                <template v-if="cell.field.key == 'id' || cell.field.key == 'lastName' || cell.field.key == 'firstName' || cell.field.key == 'total'">
                    <router-link
                        v-bind:to="{ name: 'Checkouts', query: { item: null, user: cell.item.id, group: null, show: 'checkouts' }}"
                    >
                        <span v-on:click.prevent="goToFilteredCheckouts(null, cell.item, null, 'checkouts')">{{ cell.value }}</span>
                    </router-link>
                </template>
                <template v-else-if="cell.field.key == 'itemsOut'">
                    <router-link
                        v-bind:to="{ name: 'Checkouts', query: { item: null, user: cell.item.id, group: null, show: 'nonreturned' }}"
                    >
                        <span v-on:click.prevent="goToFilteredCheckouts(null, cell.item, null, 'nonreturned')">{{ cell.value }}</span>
                    </router-link>
                </template>
                <template v-else-if="cell.field.key == 'completed'">
                    <router-link
                        v-bind:to="{ name: 'Checkouts', query: { item:null, user: cell.item.id, group:null, show: 'completed' }}"
                    >
                        <span v-on:click.prevent="goToFilteredCheckouts(null, cell.item, null, 'completed')">{{ cell.value }}</span>
                    </router-link>
                </template>
                <template v-else-if="cell.field.key == 'staff'">
                    <router-link
                        v-bind:to="{name: 'Checkouts', query: { item:null, user: null, group: cell.item.group.id, show: 'checkouts' }}"
                    >
                        <span v-on:click.prevent="goToFilteredCheckouts(null, null, cell.item.group, 'checkouts')">{{ cell.value }}</span>
                    </router-link>
                </template>
                <template v-else>
                    {{ cell.value }}
                </template>
            </template>
            
            <template v-slot:grouped-records="groupProps">
                <user-record
                    v-for="user in groupProps.items"
                    v-model="selected[user.id]"
                    v-bind:user = "user"
                    v-bind:showMark = "$parent.scope == 'admin'"
                    v-bind:key="user.id"
                    v-bind:grouped=true
                    v-on:change = updateSelectAll
                    v-bind:enableTracking="enableTracking"
                    v-on:update:requestedFilterName="$emit('update:requestedFilterName', arguments[0])"
                ></user-record>
            </template>
        
            <template v-slot:bottom-right-admin-buttons>
                <b-button v-if="!restore" v-bind:disabled="count == 0" size="sm" variant="secondary" v-on:click="handleUpdateItems('edit-users')" class="mr-2">Edit</b-button>
                <button v-if="!restore" v-bind:disabled="count == 0" type="button" class="btn btn-sm btn-danger" v-on:click="handleDeleteButton(false)">Delete</button>
                <b-button v-if="restore" v-bind:disabled="count == 0" size="sm" variant="secondary" v-on:click="handleDeleteButton(true)">Restore</b-button>
            </template>
        
        </page-structure>
    
        <edit-users
            v-bind:selectedItems="itemsToChange"
            v-on:updated-items="handleUpdatedItems"
            id="edit-users"
            title="Edit Users"
            v-on:rendered="tryEditResume"
        >
        </edit-users>
    </div>
</template>

<script>

import UserRecord from './UserRecord'
import AddNewUser from './AddNewUser'
import EditUsers from './EditUsers'
import groupedItems from '../mixins/grouped-items'
import editDelete from '../mixins/edit-delete'
import filterLinks from '../mixins/filter-links'
import base from '../mixins/base'
import PageStructure from './PageStructure'

export default {
    
    data(){
        return{
            uri: 'users',
            noun: 'user',
            pluralNoun: 'users',
            localPagination: true,
            modifiedLimit: this.limit,
            baseColumns: [
                { sort: 'id', name: 'User #', sortable:true },
                { sort: 'lastName', name: 'Last Name', sortable:true  },
                { sort: 'firstName', name: 'First Name', sortable:true  },
                { sort: 'itemsOut', name: 'Items Out', sortable:true  },
                { sort: 'lastCheckout', name: 'Last Checkout', sortable:true },
                { sort: 'total', name: 'Total', sortable:true  },
            ],
            groupColumn: { sort: 'staff', name: 'Group', sortable:true  },
            completedColumn: { sort: 'completed', name: 'Completed', sortable:true  },
            baseFields: [
                {
                    key: 'id',
                    label: 'User #',
                    sortable: true
                },
                {
                    key: 'lastName',
                    sortable: true
                },
                {
                    key: 'firstName',
                    sortable: true
                },
                {
                    key: 'itemsOut',
                    sortable: true,
                    sortByFormatted: true,
                    sortDirection: 'desc',
                    formatter: (value, key, user) => {
                        return user.status.itemsOut;
                    }
                },
                {
                    key: 'lastCheckout',
                    sortable: true,
                    sortByFormatted: (value, key, user) => {
                        if (user.status.lastCheckoutTime != null) {
                            return new Date(user.status.lastCheckoutTime.replace(' ', 'T'));
                        }
                        else {
                            return null
                        }
                    },
                    sortDirection: 'desc',
                    formatter: (value, key, user) => {
                        let date = '-';
                        if (user.status.lastCheckoutTime != null) {
                            date = new Date(user.status.lastCheckoutTime.replace(' ', 'T'));
                        }
                        return this.prettyDate(date);
                    },
                    tdAttr: (value, key, user) => {
                        return {title: user.status.lastCheckoutTime};
                    }
                },
                {
                    key: 'total',
                    sortable: true,
                    sortByFormatted: true,
                    sortDirection: 'desc',
                    formatter: (value, key, user) => {
                        return user.status.totalCheckouts;
                    }
                },
            ],
            completedField: {
                key: 'completed',
                sortable: true,
                sortByFormatted: true,
                sortDirection: 'desc',
                formatter: (value, key, user) => {
                    return user.status.completedTasks;
                }
            },
            groupField: {
                key: 'staff',
                label: 'Group',
                formatter: (value, key, user) => {
                    return user.group.name;
                },
                tdClass: (value, key, user) => {
                    return user.group.active ? '' : 'inactive';
                }
            }
        }
    },
    
    metaInfo() {
        return {
            title: (this.restore ? 'Deleted ' : '') + 'Users',
        }
    },
    
    props: {
        enableTracking: Boolean,
        requestedFilterName: String
    },
    
    mixins: [base, editDelete, groupedItems, filterLinks],
    
    components: {
        'user-record': UserRecord,
        'edit-users': EditUsers,
        'add-new-user': AddNewUser,
        'page-structure': PageStructure
    },
    
    created: function() {
        this.fetchItems();
    },
    
    computed: {
        
        columns: function() {
            const c = this.columnsWithoutGroup.slice();
            c.push(this.groupColumn);
            return c;
        },
        
        columnsWithoutGroup: function() {
            const c = this.baseColumns.slice();
            if (this.enableTracking) {
                c.push(this.completedColumn);
            }
            return c;
        },
        
        fields: function() {
            const f = this.baseFields.slice();
            if (this.enableTracking) {
                f.push(this.completedField);
            }
            f.push(this.groupField);
            return f;
        },
        
        sortedBy: function() {
            // Function to test whether specified sort option is in the valid list
            const sortTest = column => { return column.sort == this.$route.query['sort']; }
            
            // If we've received a query, validate it against the sort list above
            if ( typeof this.$route.query['sort'] != 'undefined' && this.columns.find(sortTest) ) {
                return this.$route.query['sort'];
            }
            // Default is to be filtered by last name
            else {
                return 'lastName';
            }
        },
        
        groupedBy: function() {
            return (this.sortedBy == 'staff') ? 'group' : null;
        },
        
        additionalFilters: function() {           
            return '&sort=' + this.sortedBy;
        }
    },
    
    watch: {
        sortedBy: function() {
            this.requestMessage = "Sorting...";
            if (this.sortedBy == 'staff') {
                this.modifiedLimit = 0;
            }
            else {
                this.modifiedLimit = this.limit;
            }
            this.fetchItems();
        },
        
        showCheckAlert: function() {
            if ( Object.prototype.hasOwnProperty.call(this.showCheckAlert, 'type') && this.showCheckAlert != {} ) {
                if (this.showCheckAlert.type == 'in' || this.showCheckAlert.type == 'out') {
                    this.fetchItems();
                }
                this.$emit('check', {});
            }
        },
    },
    
    methods: {
    
        handleAddNewMult() {
            
            // Check authorization
            const currentTime = new Date();
            if ( (this.expire.getTime() - currentTime.getTime()) <= 0 ) {
                this.$emit('checkAuth', {
                    lastCommand: 'add-new-mult-users',
                    lastInput: ''
                });
            }

            // Show the modal
            else {
                this.$bvModal.show('add-new-mult')
            }
        },
        
        handleAddNew() {
        
            // Check authorization
            const currentTime = new Date();
            if ( (this.expire.getTime() - currentTime.getTime()) <= 0 ) {
                this.$emit('checkAuth', {
                    lastCommand: 'add-new-user',
                    lastInput: ''
                });
            }

            // Show the modal
            else {
                this.$bvModal.show('add-new')
            }
        },
        
        prettyDate: function(d) {
            if (d == '-') {
                return '-';
            }
            const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(d);
            const date = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(d);
            return month + " " + date + ", " + year;
        },
        
        status: function(user) {
            if (user.status.itemsOut > 0) {
                if (user.status.hasOverdueCheckout) {
                    return 'overdue';
                }
                else {
                    return 'out';
                }
            }
            else {
                return '';
            }
        },
        
        tryAddNewMultResume: function() {
            
            // Show multiple add new if user was trying to do that before reauthorization
            if (this.lastCommand == 'add-new-mult-users') {
                this.$bvModal.show('add-new-mult');
                this.$emit('resumed');
            }
        },
        
        tryAddNewResume: function() {
        
            // Show add new if user was trying to do that before reauthorization
            if (this.lastCommand == 'add-new-user') {
                console.log('Try add new user');
                this.$bvModal.show('add-new');
                this.$emit('resumed');
            }
        },
    }
}
</script>

<style>

    #add-new-mult .modal-dialog, #edit-users .modal-dialog {
        width: 97.5%;
        max-width: 950px;
    }
    
</style>
