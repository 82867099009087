/**
 * edit-delete.js - Code shared by various interfaces that can edit and delete things
 *
 * @see       https://github.com/teachernerd/inventory/ The Inventory Box GitHub project
 *
 * @author    Daniel Reinish (teachernerd) <dan@reinish.net>
 * @copyright 2016 - 2022 Daniel Reinish
 * @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
 * @note      This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 */
 
/**
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
 */

export default {
  
  data(){
        return {
            itemsToChange: [],
            updateCount: 0,
            action: '[actioned]',
            resumedItems: [],
            showUpdateMessage: false,
            waitingForUpdate: false
        }
    },
    
    mounted: function() {
        this.tryDeleteResume();
    },
    
    props: {
        lastCommand: String,
        lastInput: [String, Array, Object],
        expire: Date
    },
    
    computed: {
        editDeletePageProps: function() {
            return {
                updateCount: this.updateCount,
                action: this.action,
                ...this.basePageProps
            }
        }
    },
    
    methods: {
        // EDIT AND DELETE BUTTONS
        
        handleUpdateItems: function(t) {
            
            // Load the list of items to change
            // (Since this involves a for-loop, we only compute it on demand)
            this.getItemsToChange();
            
            // If something was selected, proceed
            if (this.itemsToChange.length) {
                
                // Check authorization
                const currentTime = new Date();
                if ( (this.expire.getTime() - currentTime.getTime()) <= 0 ) {
                    this.$emit('checkAuth', {
                        lastCommand: t, // Each component sends a unique message
                        lastInput: this.itemsToChange
                    });
                }
                
                // Show the modal
                else {
                    this.$bvModal.show(t);
                }
            }
            
            // Display an error if nothing was selected
            else {
                this.displayNothingSelected();
            }
        },
        
        displayNothingSelected: function() {
            this.$bvModal.msgBoxOk('Please select one or more ' + this.pluralNoun + ' and try again.',
                {
                    title: 'Nothing Selected!',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'warning'
                }
            );
        },
        
        handleUpdatedItems: function(allSuccessful) {   
            if (allSuccessful) {
                /*
                this.$bvModal.msgBoxOk('All updates applied successfully!',
                    {
                        title: 'Success',
                        size: 'md',
                        buttonSize: 'sm',
                        okVariant: 'success'
                    }
                );
                */
                
                // Display the status message
                this.action = 'updated';
                this.updateCount = allSuccessful;
                window.scrollTo(0,0);
                this.showUpdateMessage = true;
                
            }
            this.selected = []; // Clear the checkboxes
            this.updateSelectAll(); // Make sure select all box resets
            this.fetchItems(); 
        },
        
        getItemsToChange: function() {
            // If we received a list of resumed items, just use that
            if (this.resumedItems.length) {
                console.log('resumed items exist');
                this.itemsToChange = this.resumedItems;
                this.resumedItems = [];
            }
            
            // If we didn't receive a list of resumed items, get the list from the
            // selections
            else {
                var itemsToChange = [];
                this.selected.forEach((marked, searchId) => {
                    if (marked) {
                        const selectedItemIndex = this.items.findIndex(asset => asset.id == searchId);
                        itemsToChange.push(this.items[selectedItemIndex]);
                    }
                });
                this.itemsToChange = itemsToChange;
            }
        },
        
        
        handleDeleteButton: function(restore = false) {
            this.getItemsToChange();
            if (this.itemsToChange.length) {
                
                // Check authorization
                const currentTime = new Date();
                if ( (this.expire.getTime() - currentTime.getTime()) <= 0 ) {                
                    this.$emit('checkAuth', {
                        lastCommand: (restore ? 'restore' : 'delete') + '-' + this.pluralNoun, // Each component sends a unique message
                        lastInput: this.selected
                    });
                }
                
                else {
                
                    this.$bvModal.msgBoxConfirm('Are you sure you want to '
                            + (restore ? 'restore' : 'delete') + ' the selected '
                            + this.pluralNoun + '?',
                    {
                        title: 'Please Confirm',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: restore ? 'Restore' : 'Delete',
                        cancelTitle: 'Cancel',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: false
                    })
                        .then(value => {
                            if (value) {
                                this.deleteSelectedItems(restore);
                            }
                        })
                        .catch(err => {
                            // An error occurred
                            console.log(err)
                        })
                }
            }
            else {
                this.displayNothingSelected();
            }
        },
        
        deleteSelectedItems: function(restore = false) {
            this.waitingForUpdate = true;
            this.removalCount = 0;
            this.actuallyRemoved = 0;
            let loopArray = this.selected.slice();
            loopArray.forEach((marked, asset) => {
                
                // Make sure that this item was marked for deletion (and not "unmarked")
                if (marked) {
                    
                    // Increase counter and set API route
                    this.removalCount++;
                    let uri = this.uri + "/" + asset;
                    
                    // Display progress box
                    this.$bvModal.msgBoxOk((restore ? 'Restoring' : 'Deleting') + ' selected ' + this.pluralNoun + '...', {
                        id: 'deleting',
                        title: 'In Progress',
                        size: 'sm',
                        buttonSize: 'sm',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: false,
                        hideFooter: true
                    });
                    
                    // Call the API
                    this.axios
                        .patch(uri, {active: (restore ? true : false)})
                        .then((response) => {
                            // If successful, update counter and remove from table
                            if (restore == response.data.active) {
                                this.actuallyRemoved++;
                                this.removeItemFromTable(asset, restore)
                            }
                            this.waitingForUpdate = false;
                        })
                        .catch(() => {
                            // Show error
                            this.$bvModal.hide('deleting');
                            this.$bvModal.msgBoxOk(`One or more of the selected items
                                                    could not be deleted. Please
                                                    refresh the page and try again.`, {
                                title: 'Unexpected Error',
                                size: 'sm',
                                buttonSize: 'sm',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: false,
                                okVariant: 'warning'
                            });
                            this.waitingForUpdate = false;
                        });
                }
            });
        },
        
        removeItemFromTable: function(searchId, restore = false) {
            // Remove item from list of selected items (though this is kind of moot)
            this.selected = this.selected.splice(searchId, 1);
            
            // Find index of deleted item in master list and remove
            let deletedItemIndex = this.items.findIndex(asset => asset.id == searchId);
            this.items.splice(deletedItemIndex, 1);
            
            // If the final item has been removed, display a notification.
            if (this.removalCount == this.actuallyRemoved) {
                
                // Update variables
                this.selected = [];
                this.count = this.count - this.actuallyRemoved;
                
                // Dismiss the progress box
                this.$bvModal.hide('deleting');
                
                // Display the message
                this.action = restore ? 'restored' : 'deleted';
                window.scrollTo(0,0);
                this.updateCount = this.removalCount;
                this.showUpdateMessage = true;
                
                /*
                this.$bvModal.msgBoxOk(this.removalCount + (this.removalCount == 1 ? ' ' + this.noun : ' ' + this.pluralNoun) + ' removed.', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: false,
                    noFade: true,
                    okVariant: 'success'
                });
                */
            }
        },
        
        handleAddedItems: function(allSuccessful) {
            if (allSuccessful) {
                
                this.action = 'added';
                window.scrollTo(0,0);
                this.updateCount = allSuccessful;
                this.showUpdateMessage = true;
                
                /*
                this.$bvModal.msgBoxOk(allSuccessful + ' ' +  (allSuccessful == 1 ? ' ' + this.noun : ' ' + this.pluralNoun + ' added.'),
                    {
                        title: 'Success!',
                        size: 'md',
                        buttonSize: 'sm',
                        okVariant: 'success'
                    }
                );
                */
                
            }
            this.selected = []; // Clear the checkboxes
            this.fetchItems(); 
        },
        
        
        // METHODS RELATED TO RESUMING FROM RELOAD
        
        handleCheckAuth: function(args) {
            this.$emit('checkAuth', args);
        },
        
        tryEditResume: function() {
            if (this.lastCommand == 'edit-' + this.pluralNoun) {
                this.resumedItems = this.lastInput;
                this.handleUpdateItems('edit-' + this.pluralNoun);
                this.$emit('resumed');
            }
        },
        
        tryDeleteResume: function() {
            if (this.lastCommand == 'delete-' + this.pluralNoun) {
                this.selected = this.lastInput
                this.handleDeleteButton(false);
                this.$emit('resumed');
            }
            else if (this.lastCommand == 'restore-' + this.pluralNoun) {
                this.selected = this.lastInput;
                this.handleDeleteButton(true);
                this.$emit('resumed');
            }
        }
    }
};