<!-- App.vue - Root component for Inventory Box front-end client app -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
  <div id="app-wrapper" v-bind:class="{'kiosk': isKiosk}">
      <div
        id="app"
        v-bind:class="{container: true, kiosk: isKiosk, 'rounded-lg': isKiosk}"
        v-bind:style="{height: isKiosk ? kioskHeight : '100%'}"
    >
        <nav class="row navbar navbar-expand-lg bg-light">
        
            <div class="col-md-5 col-lg-auto mr-n3"><router-link :to="{ name: 'Inventory' }" class="navbar-brand">The Inventory Box</router-link></div>
            <ul class="navbar-nav ml-3 col-md-8 col-lg-auto">
                <li class="nav-item">
                  <router-link :to="{ name: 'Inventory' }" class="nav-link">Inventory</router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'Users' }" class="nav-link">Users</router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'Checkouts' }" class="nav-link"> {{ enableTracking ? 'Assignments' : 'Checkouts' }}</router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'Transactions' }" class="nav-link">Transactions</router-link>
                </li>
            </ul>
            <div class="col-lg text-right">
                <button
                    v-bind:disabled="accessToken == ''"
                    type="button"
                    class="btn btn-primary mr-2"
                    v-on:click="startCheck('checkout-form');"
                >
                    Check Out
                </button>
                <button
                    v-bind:disabled="accessToken == ''"
                    type="button"
                    class="btn btn-primary"
                    v-on:click="startCheck('checkin-form');"
                >
                    Check In
                </button>
                <b-dropdown
                    class="ml-2"
                    no-caret
                    right
                >
                    <template v-slot:button-content>
                        <b-icon-gear-fill></b-icon-gear-fill>
                    </template>
                
                    <template v-if="isAuthorized">
                        <b-dropdown-text style="width:250px">
                            <b-icon-person-circle></b-icon-person-circle><span class="ml-2" style="font-weight:bold">{{ accountName }}</span>
                            - <small><strong><a v-bind:href="base + '/auth/edit'">Edit</a></strong></small>
                        </b-dropdown-text>
                
                        <b-dropdown-divider></b-dropdown-divider>
                
                        <b-dropdown-item
                            v-bind:href="base + '/auth/logout'"
                        >
                            Log Out
                        </b-dropdown-item>
                
                        <b-dropdown-item
                            v-if="scope == 'admin'"
                            v-bind:href="base + '/auth/manage'"
                        >
                            Manage Accounts
                        </b-dropdown-item>
                    </template>
                    <template v-else>
                        <b-dropdown-item
                            href="login"
                            v-on:click.prevent="authorize()"
                        >
                            Log In
                        </b-dropdown-item>
                    </template>

                
                    <template v-if="scope == 'admin'">
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item
                            v-bind:to="{ name: 'Groups' }"
                        >
                            Edit User Groups
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-bind:to="{ name: 'Categories' }"
                        >
                            Edit Inventory Categories
                        </b-dropdown-item>
                   
                        <b-dropdown-divider></b-dropdown-divider>
                   
                        <b-dropdown-item
                            v-on:click="handleSettingsDialog('email-settings')"
                        >
                            Notification Settings
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-on:click="handleSettingsDialog('checkout-settings')"
                        >
                            Checkout Settings
                        </b-dropdown-item>
                        <b-dropdown-item
                             v-on:click="handleSettingsDialog('display-settings')"
                        >
                            Display Settings
                        </b-dropdown-item>
                    
                        <b-dropdown-divider></b-dropdown-divider>
                    
                        <b-dropdown-item
                            v-bind:to="{ name: 'Deactivated Users' }"
                        >
                            View/Restore Deleted Users
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-bind:to="{ name: 'Deleted Items' }"
                        >
                            View/Restore Deleted Items
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-bind:to="{ name: 'Deleted Groups' }"
                        >
                            View/Restore Deleted Groups
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-bind:to="{ name: 'Deleted Categories' }"
                        >
                            View/Restore Deleted Categories
                        </b-dropdown-item>
                    </template>
                    
                    <template>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item
                            v-on:click="$bvModal.show('about')"
                        >
                            About
                        </b-dropdown-item>
                    </template>
                
                    <template v-if="showDebugInfo">
                        <b-dropdown-item
                            v-on:click="$bvModal.show('debug')"
                        >
                            Debug Info
                        </b-dropdown-item>
                    </template>
                
                    <template v-if="$parent.scope == 'admin' && showKioskSettings">
                        <b-dropdown-item
                            v-bind:href="base + '/auth/reboot'"
                        >
                            Reboot
                        </b-dropdown-item>
                        <b-dropdown-item
                            v-bind:href="base + '/auth/shutdown'"
                        >
                            Shut Down
                        </b-dropdown-item>
                    </template>
                                
                </b-dropdown>
            </div>
        </nav>
            <div id="global-checkout">
                <checkout-form
                    id="checkout-form"
                    type="out"
                    v-on:checkout="handleCheckout"
                    v-bind:checkoutMessage="checkoutMessage"
                    v-bind:enableTracking="enableTracking"
                    v-bind:allowVirtual="allowVirtual"
                    v-bind:allowMultiple="allowMultiple"
                    v-bind:alwaysCentered="false"
                ></checkout-form>
                <checkout-form
                    id="checkin-form"
                    type="in"
                    v-on:checkin="handleCheckin"
                    v-bind:allowMultiple="allowMultiple"
                    v-bind:alwaysCentered="false"
                ></checkout-form>
            </div>
        <transition name="fade">
          <div class="mt-4">
            <router-view
                v-if="safeToFetch"
                v-model="checkTrigger"
                v-on:checkAuth="handleCheckAuth"
                v-on:resumed="handleResumed"
                v-on:updateSettings="handleUpdateSettings"
                v-bind:lastCommand="lastCommand"
                v-bind:lastInput="lastInput"
                v-bind:expire="expire"
                v-bind:safeToFetch="safeToFetch"
                v-bind:enableTracking="enableTracking"
                v-bind:key="routerKey"
                v-bind:limit="limit"
                v-on:updated-limit="newLimit"
                v-bind:requestedFilterName.sync="requestedFilterName"
            ></router-view>
            <p v-else>
                Please <a href="login" v-on:click.prevent="authorize()">log in</a> to access the checkout system. 
            </p>     
          </div>
        </transition>
    
        <email-settings
            v-if="scope == 'admin'"
            v-bind:expire="expire"
            v-bind:lastCommand="lastCommand"
            v-bind:lastInput="lastInput"
            v-bind:isKiosk="isKiosk"
            v-on:checkAuth="handleCheckAuth"
            v-on:resumed="handleResumed"
        ></email-settings>
    
        <checkout-settings
            v-if="scope == 'admin'"
            v-bind:expire="expire"
            v-bind:lastCommand="lastCommand"
            v-bind:lastInput="lastInput"
            v-on:checkAuth="handleCheckAuth"
            v-on:resumed="handleResumed"
            v-on:updateSettings="handleUpdateSettings"
        ></checkout-settings>
    
        <display-settings
            v-if="scope == 'admin'"
            v-bind:expire="expire"
            v-bind:lastCommand="lastCommand"
            v-bind:lastInput="lastInput"
            v-on:checkAuth="handleCheckAuth"
            v-on:resumed="handleResumed"
            v-on:updateQueryDates="routerKey += 1;"
        ></display-settings>
    
        <debug-dialog
            v-bind:isAuthorized="isAuthorized"
            v-bind:scope="scope"
            v-bind:accessToken="accessToken"
            v-bind:expire="expire"
        ></debug-dialog>
        
        <about-box
            id="about"
            v-bind:version="appVersion"
            v-bind:copyright="copyright"
            v-bind:author="author"
            v-bind:authorContact="authorContact"
            v-bind:description="appDescription"
        >
        </about-box>
    
      </div>
    </div>
</template>

<script>

import AboutBox from './components/AboutBox'
import CheckoutForm from './components/CheckoutForm'
import EmailSettings from './components/EmailSettings'
import CheckoutSettings from './components/CheckoutSettings'
import DebugDialog from './components/DebugDialog'
import DisplaySettings from './components/DisplaySettings';
//import jwt_decode from "jwt-decode"
import {jwtVerify, createRemoteJWKSet} from 'jose'

export default {

    data(){
        return {
            tokenEndpoint: '/auth/token',
            authorizeEndpoint: '/auth/authorize',
            jwksEndpoint: '/auth/keys',
            defaultScopes: 'view limited admin openid',
            isAuthorized: false,
            redirectPage: process.env.VUE_APP_REDIRECT,
            errorDetails: '',
            accessToken: '',
            expire: null,
            scope: 'view',
            checkTrigger: {},
            lastCommand: '',
            lastInput: {},
            safeToFetch: false,
            checkoutMessage: '',
            enableTracking: false,
            allowVirtual: false,
            allowMultiple: true,
            base: process.env.VUE_APP_API_BASE + '/' + this.$clientPath,
            accountName: '',
            routerKey: 0,
            limit:0,
            defaultLimit: parseInt(process.env.VUE_APP_DEFAULT_LIMIT),
            requestedFilterName: '',
            isKiosk: process.env.VUE_APP_KIOSK_MODE == 'true',
            kioskHeight: process.env.VUE_APP_KIOSK_HEIGHT,
            copyright: process.env.VUE_APP_COPYRIGHT,
            appVersion: process.env.VUE_APP_VERSION,
            appDescription: process.env.VUE_APP_DESCRIPTION,
            author: process.env.VUE_APP_AUTHOR_NAME,
            authorContact: process.env.VUE_APP_AUTHOR_EMAIL
         }
    },
    
    metaInfo: {
        title: 'Home',
        titleTemplate: '%s | Inventory Box'
    },
    
    components: {
        'checkout-form': CheckoutForm,
        'email-settings': EmailSettings,
        'checkout-settings': CheckoutSettings,
        'display-settings': DisplaySettings,
        'debug-dialog': DebugDialog,
        'about-box': AboutBox
    },
    
    created: function() {
        // Intercept escape key in kiosk mode to prevent accidental exit of full screen
        if (this.isKiosk) {
            window.addEventListener('keydown', this.preventEscape);
        }
        
        // All the ajax requests relating to authorization and authentication are
        // handled by the browser API. Once an authorization token has been obtained,
        // future requests to the API are handled by the Axios library. These requests
        // are watched by the Auth Monitor for 401 failures. If a 401 (authentication)
        // failure is received, the auth monitor alerts the application that the user
        // is no longer logged in so that appropriate action can be taken.
        this.setUpAuthMonitor();
        
        // Upon app creation, check for an auth code in the URL queries. If found,
        // assume this is a redirect from the login server and attempt to get a token.
        // If the app receives an authorization error, stop loading (in order to prevent
        // refresh loop.)
        if (this.$route.query['error'] == null) {
            let code = this.$route.query['code'];
            if (code != null) {
                this.getToken(code, this.$route.query['state']);
            }
            else {
                this.authorize();
            }
        }
        else {
            console.log(this.$route.query['error']);
        }
        
        // Listen for auth check requests
        //this.$on('checkAuth', this.handleCheckAuth)
        
    },
    
    watch: {
    
        // When connection routine reports that it's safe to fetch, immediately retrieve
        // general settings from server and local settings from local storage
        safeToFetch: function() {
            if (this.safeToFetch) {
                // If limit (rows per page) hasn't been set grab it from storage
                // and if there's nothing set in storage, then use the default.
                if (this.limit == 0) {
                    const savedLimit = localStorage.getItem("rows_per_page");
                    this.limit = (savedLimit !== null) ? parseInt(savedLimit) : this.defaultLimit;
                }
                // Get settings from server
                this.axios.get('settings/checkout').then((response) => {
                    this.checkoutMessage = response.data.checkoutMessage;
                    this.enableTracking = response.data.enableTracking;
                    this.allowVirtual = response.data.allowVirtual;
                    this.allowMultiple = response.data.allowMultiple;
                });
            }
        }
    },
    
    computed: {
        
        showKioskSettings: function() {
            return process.env.VUE_APP_KIOSK_MODE === 'true' ? true : false;
        },
        
        showDebugInfo: function() {
            return process.env.VUE_APP_SHOW_DEBUG_INFO === 'true' ? true : false;
        },
    },
    
    methods: {
        
        // Listens to all keydown events and blocks them if they involve escape key.
        // (This is to prevent accidental exiting of full screen mode when in kiosk mode)
        preventEscape: function(event) {
            if (event.keyCode === 27) {
                event.preventDefault();
            }
        },
        
        newLimit: function (l) {
            this.limit = l;
            this.routerKey += 1;
            localStorage.setItem("rows_per_page", this.limit);
        },
        
        startCheck: function (m) {           
            // If we still have a valid auth token, show the dialog
            if (this.checkAuth('startCheck', m)) {
            
                // Clear the checkout trigger
                this.checkTrigger = {};
            
                // Show the checkout/checkin dialog
                this.$bvModal.show(m);  
            }
        },
        
        checkAuth: function(lastCommand, lastInput) {
            //console.log(this.expire);
            const currentTime = new Date();
            if ( (this.expire.getTime() - currentTime.getTime()) <= 0 ) {
                this.authorize(lastCommand, lastInput);
                return false;
            }
            return true;
        },
        
        handleCheckAuth(x) {
            this.checkAuth(x.lastCommand, x.lastInput);
        },
        
        handleResumed() {
            this.lastCommand = '';
            this.lastInput = {};
        },
        
        handleUpdateSettings(args) {
            this.checkoutMessage = args.checkoutMessage;
            this.enableTracking = args.enableTracking;
            this.allowVirtual = args.allowVirtual;
            this.allowMultiple = args.allowMultiple;
        },
        
        handleCheckout(checkouts) {
            // If only a single object was returned, turn it into an array so that it
            // can be "looped" through successfully.
            if (!(checkouts instanceof Array)) {
                checkouts = [checkouts];
            }
            
            // Loop through returned checkout objects
            checkouts.forEach((checkout) => {
                // Post a notification
                let toastText = (checkout.asset.id == -1) ?
                    'Assignment successfully logged.' :
                    'Item #' + checkout.asset.id + ' successfully checked out.';
                this.$bvToast.toast(toastText, {
                      title: 'Check Out',
                      solid: true,
                      toaster: 'b-toaster-top-right',
                      variant: 'success'
                });
            
                // Redirect to the checkout log
                //if (this.$route.name != 'Checkouts') this.$router.push({ name: 'Checkouts' });
            
            });
            
            // Set the App's checkTrigger property to indicate the type of transaction
            // and the checkout object we received from the event. This will cause
            // this information to be passed to the router-view (via a v-model) and,
            // in turn, triggers the checkout page's alert/update routine. While this
            // feels rather convoluted, it allows us to keep to Vue.js style by avoiding
            // the direct coupling of the child to the parent.
            this.checkTrigger = { type: 'out', data: checkouts };
        },
        
        handleCheckin(checkins) {
        
            // Hide the checkin form.
            this.$bvModal.hide('checkin-form');
            
            // If only a single object was returned, turn it into an array so that it
            // can be "looped" through successfully.
            if (!(checkins instanceof Array)) {
                checkins = [checkins];
            }
            
            // Loop through returned checkout objects
            checkins.forEach((checkin) => {
            
                // Post a notification
                let toastText = 'Item #' + checkin.asset.id + ' successfully checked in.';
                this.$bvToast.toast(toastText, {
                      title: 'Check In',
                      solid: true,
                      toaster: 'b-toaster-top-right',
                      variant: 'success'
                });
        
                // Redirect to the checkout log
                //if (this.$route.name != 'Checkouts') this.$router.push({ name: 'Checkouts' });
            });
            
            // Set the checkTrigger property (see above for explanation)
            this.checkTrigger = { type: 'in', data: checkins };
        },
        
        handleSettingsDialog(command) {
            // Check authorization
            this.checkAuth(command, null);
            
            // Show dialog        
            this.$bvModal.show(command);
        },
        
        resume: function(state) {
            // Set the component properties to the state so that they can be bound
            // to the router view
            this.lastCommand = state.lastCommand;
            this.lastInput = state.lastInput;
                        
            // Return user to page they were previously on
            if (state.lastRoute != '') {
                this.$router.push({
                    name: state.lastRoute
                });
            }
        
            // If last command was checkout or checkin, try it again
            if (state.lastCommand == 'startCheck') {
                this.startCheck(state.lastInput);
            }
            
            // If user was attempting to edit email or checkout settings before auth reset,
            // try to resume.
            else if (state.lastCommand == 'email-settings' || state.lastCommand == 'checkout-settings' || state.lastCommand == 'display-settings') {
                this.handleSettingsDialog(state.lastCommand);
                this.handleResumed();
            }
            
            // If user was attempting to save email settings before auth reset,
            // attempt to resume.
            else if (state.lastCommand == 'save-email') {
                // We seem to need a nextTick in order to get the lastCommand to
                // get passed to the email settings dialog before it loads.
                this.$nextTick(function () {
                    this.$bvModal.show('email-settings');
                });
            }
            
            // If user was attempting to save checkout settings before auth reset,
            // attempt to resume.
            else if (state.lastCommand == 'save-checkout') {
                // We seem to need a nextTick in order to get the lastCommand to
                // get passed to the email settings dialog before it loads.
                this.$nextTick(function () {
                    this.$bvModal.show('checkout-settings');
                });
            }
            
            // If user was attempting to save display settings before auth reset,
            // attempt to resume.
            else if (state.lastCommand == 'save-display') {
                // We seem to need a nextTick in order to get the lastCommand to
                // get passed to the email settings dialog before it loads.
                this.$nextTick(function () {
                    this.$bvModal.show('display-settings');
                });
            }
        },
        
        authorize: async function(lastCommand = '', lastInput = '') {
            // Create and store a random "state" value
            var state = this.generateRandomString();
            localStorage.setItem("pkce_state", state);
            
            // Store state refresh values
            localStorage.setItem("state_" + state, JSON.stringify ({
                lastCommand: lastCommand,
                lastInput: lastInput,
                lastRoute: this.$route.name
            }));

            // Create and store a new PKCE code_verifier (the plaintext random secret)
            var code_verifier = this.generateRandomString();
            localStorage.setItem("pkce_code_verifier", code_verifier);

            // Hash and base64-urlencode the secret to use as the challenge
            var code_challenge = await this.pkceChallengeFromVerifier(code_verifier);

            // Build the authorization URL
            var url = process.env.VUE_APP_API_BASE + '/' + this.$clientPath + this.authorizeEndpoint
                + "?response_type=code"
                + "&client_id="+encodeURIComponent(process.env.VUE_APP_CLIENT_ID)
                + "&state="+encodeURIComponent(state)
                + "&scope="+encodeURIComponent(this.defaultScopes)
                + "&redirect_uri="+encodeURIComponent(process.env.VUE_APP_CLIENT_HOST + process.env.VUE_APP_CLIENT_PATH + '/' + this.$clientPath + '/' + this.redirectPage)
                + "&code_challenge="+encodeURIComponent(code_challenge)
                + "&code_challenge_method=S256"
                ;
            
            // Redirect to the authorization server
            window.location = url;
        },
        
        getToken: function(code, state) {
                    
            // If state does not match what we set at the beginning, the session has expired
            if(localStorage.getItem("pkce_state") != state) {
                this.displayLoginError("The session has expired. Please try logging in again.");
            }
            
            // If state matches, it is safe to continue
            else {
                               
                // Exchange the authorization code for an access token
                this.sendPostRequest(process.env.VUE_APP_API_BASE + '/' + this.$clientPath + this.tokenEndpoint, {
                    grant_type: "authorization_code",
                    code: code,
                    client_id: process.env.VUE_APP_CLIENT_ID,
                    redirect_uri: process.env.VUE_APP_CLIENT_HOST + process.env.VUE_APP_CLIENT_PATH + '/' + this.$clientPath + '/' + this.redirectPage,
                    code_verifier: localStorage.getItem("pkce_code_verifier")
                },
                
                // If token request was successful...
                (request, body) => {
                    
                    // Grab JSON Web Keyset from API
                    let remoteJWKUrl = new URL(process.env.VUE_APP_API_BASE + '/' + this.$clientPath + this.jwksEndpoint);
                    const JWKS = createRemoteJWKSet(remoteJWKUrl);
                    
                    // Verify the ID token we received using the keyset
                    jwtVerify(body.id_token, JWKS, {
                        issuer: process.env.VUE_APP_API_BASE + '/' + this.$clientPath,
                        audience: process.env.VUE_APP_CLIENT_ID
                    })
                    
                    // If verification succeeds...
                    .then(({ payload }) => {
                        //console.log(payload);
                        // Grab account name from the ID token we received
                        this.accountName = payload.sub;
                        
                        // Store the access token we received
                        this.accessToken = body.access_token;
                        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + body.access_token;
                        this.isAuthorized = true;
                    
                        // Set the token's expiration time
                        const exp = new Date();
                        exp.setTime(exp.getTime() + (body.expires_in * 1000) - (1000 * 60 * 15));
                        //exp.setTime(exp.getTime() + (1000 * 60 * .25));
                        this.expire = exp;
                    
                        // Store the returned scope so that interface can display appropriate functionality
                        if (body.scope.includes('admin')) {
                            this.scope = 'admin';
                        }
                        else if (body.scope.includes('limited')) {
                            this.scope = 'limited';
                        }
                        else {
                            this.scope = 'view';
                        }
                    
                        // Replace the history entry to remove the auth code from the browser address bar
                        window.history.replaceState({}, null, this.redirectPage);
                    
                        // One everything has re-rendered, resume and/or fetch as necessary
                        // (Some dialog resumptions won't work if the modals haven't rendered yet)
                        this.$nextTick(() => {                    
                            // If user was trying to do something before the auth routine was
                            // triggered, attempt to resume whatever was happening
                            this.resume(JSON.parse(localStorage.getItem("state_" + state)));
                                        
                            // Clear any state keys from storage since we no longer need them
                            for (const key in localStorage) {
                                if (key.slice(0, 6) == "state_") {
                                    localStorage.removeItem(key);
                                }
                            }                    
                    
                            // Notify interface that it is now safe to perform a fetch operation
                            this.safeToFetch = true;
                        });
                    },
                    
                    // If the ID token does not pass validation, record an error
                    (error) => {
                        console.log(error);
                        this.displayLoginError('Account could not be authenticated.');
                    });

                }, (request, error) => {
                    // This could be an error response from the OAuth server, or an error because the 
                    // request failed such as if the OAuth server doesn't allow CORS requests
                    let errorDetails = "";
                    if (typeof error.error_description == 'undefined') {
                        errorDetails = 'The authorization server could not be reached.';
                    }
                    else {
                        errorDetails = 'The authorization server rejected the request.';
                        console.log('Authorization failure: ' + error.error_description);
                    }                
                    
                    // Show error
                    this.displayLoginError(errorDetails);
                    
                    // Clear any state keys from storage since we no longer need them
                    for (const key in localStorage) {
                        if (key.slice(0, 6) == "state_") {
                            localStorage.removeItem(key);
                        }
                    }   
                });
            }

            // Clean these up since we don't need them anymore
            localStorage.removeItem("pkce_state");
            localStorage.removeItem("pkce_code_verifier");
        },
        
        displayLoginError(errorDetails) {
            // Show error
            this.$bvModal.msgBoxOk(errorDetails, {
                title: 'Login Error',
                size: 'sm',
                buttonSize: 'sm',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: false,
                okVariant: 'warning'
            });
        },
        
        // Make a POST request and parse the response as JSON
        sendPostRequest: function(url, params, success, error) {
            var request = new XMLHttpRequest();
            request.open('POST', url, true);
            request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
            request.onload = function() {
                var body = {};
                try {
                    body = JSON.parse(request.response);
                } catch(e) {console.log('Post error')}

                if(request.status == 200) {
                    success(request, body);
                } else {
                    error(request, body);
                }
            }
            request.onerror = function() {
                error(request, {});
            }
            var body = Object.keys(params).map(key => key + '=' + params[key]).join('&');
            request.send(body);
        },

        // Parse a query string into an object
        parseQueryString: function(string) {
            if(string == "") { return {}; }
            var segments = string.split("&").map(s => s.split("=") );
            var queryString = {};
            segments.forEach(s => queryString[s[0]] = s[1]);
            return queryString;
        },

        // Generate a secure random string using the browser crypto functions
        generateRandomString: function() {
            var array = new Uint32Array(28);
            window.crypto.getRandomValues(array);
            return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
        },

        // Calculate the SHA256 hash of the input text. 
        // Returns a promise that resolves to an ArrayBuffer
        sha256: function(plain) {
            const encoder = new TextEncoder();
            const data = encoder.encode(plain);
            return window.crypto.subtle.digest('SHA-256', data);
        },

        // Base64-urlencodes the input string
        base64urlencode: function(str) {
            // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
            // btoa accepts chars only within ascii 0-255 and base64 encodes them.
            // Then convert the base64 encoded to base64url encoded
            //   (replace + with -, replace / with _, trim trailing =)
            return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
                .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
        },

        // Return the base64-urlencoded sha256 hash for the PKCE challenge
        pkceChallengeFromVerifier: async function(v) {
            var hashed = await this.sha256(v);
            return this.base64urlencode(hashed);
        },
        
        
        setUpAuthMonitor: function() {
            // We're using an Axios interceptor to monitor all API requests and
            // reset the logged-in status when it notices a 401 error
            this.axios.interceptors.response.use(
                response => {
                    // Pass responses on as-is
                    return response;
                },
                error => {    
                    // Check for 401 errors
                    if (error.response && (error.response.status == 401)) {
                        this.accessToken = '';
                        this.isAuthorized = false;
                        this.scope = 'view';
                        this.authorize();
                    }
                    return Promise.reject(error);
                }
            );
        }
        
        
        
    }
}
</script>

<style>
    .fade-enter-active, .fade-leave-active {
      transition: opacity .5s
    }
    .fade-enter, .fade-leave-active {
      opacity: 0
    }
    .gap {
      margin-top: 50px;
    }
    .router-link-active {
        color:black;
        text-decoration:none;
    }
    .router-link-active:hover {
        color:black;
        text-decoration:none;
    }
    .navbar-brand {
        color:black;
        font-weight: bold
    }
    table a {
        color:black;
    }
    iframe.reauthorize: {
        
    }
    
    #app-wrapper.kiosk {
        background-color: rgb(205, 205, 205);
        position: fixed;
        width: 100%;
        height: 100%;
    }
    
    #app.kiosk {
        background-color: white;
        overflow-y: scroll;
        overflow-x: hidden;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
    }

    .kiosk .navbar .btn {
        transition: none;
    }
    
</style>