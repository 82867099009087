<!-- InventoryPage.vue - Component for displyaing Inventory page -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    <div id="inventory-content">
        <page-structure
            v-bind="editDeletePageProps"
            v-bind:noun="noun"
            v-bind:pluralNoun="pluralNoun"
            title="Inventory"
            v-bind:restore="restore"         
            v-bind:showDetails="false"
            v-bind:showUpdateMessage.sync="showUpdateMessage"
            v-bind:sortedBy="null"
            groupedBy="category"
            v-bind:groupedItems="groupedItems('category')"
            v-bind:columns="columns"
            v-bind:groupedColumns="columns"
            v-bind:showSelectAll = "true"
            v-bind:selectAllIsChecked.sync="selectAllIsChecked"
            v-bind:includeSelectColumn="$parent.scope == 'admin'"
            skeletonWidth="5%"
            v-on:selectAll="selectAll"
            v-bind:limit="0"
        >

            <template v-slot:topAdminButtons>
                <add-new-item
                    v-on:added="handleAddedItems"
                    v-on:checkAuth="handleCheckAuth"
                    v-on:resumed="$emit('resumed')"
                    v-bind:lastCommand="lastCommand"
                    v-bind:expire="expire"
                >
                </add-new-item>
            </template>
            
            <template v-slot:group-status="captionProps">
                <span v-if="!restore">
                    ({{ availabilityByCategory[captionProps.group] }} of {{ captionProps.items.length }} available)
                </span>
            </template>
            
            <template v-slot:grouped-records="groupProps">
                <asset-record
                    v-for="asset in groupProps.items"
                    v-model="selected[asset.id]"
                    v-bind:asset = "asset"
                    v-bind:showMark = "$parent.scope == 'admin'"
                    v-bind:key="asset.id"
                    v-on:change="updateSelectAll"
                    v-on:update:requestedFilterName="$emit('update:requestedFilterName', arguments[0])"
                ></asset-record>
            </template>
            
            <template v-slot:bottom-left-admin-buttons>
                <b-button
                    v-if="!restore"
                    size="sm"
                    variant="primary"
                    v-on:click="handleUpdateItems('change-status')"
                    v-bind:disabled="waitingForUpdate"
                >
                    Change Status
                </b-button>
            </template>
            
            <template v-slot:bottom-right-admin-buttons>            
                <div>
                    <b-button
                        v-if="!restore"
                        size="sm"
                        variant="secondary"
                        v-on:click="handleUpdateItems('edit-items')"
                        class="mr-2 mb-2"
                        v-bind:disabled="waitingForUpdate"
                    >
                        Edit
                    </b-button>
                    <button
                        v-if="!restore"
                        type="button"
                        class="btn btn-sm btn-danger mb-2"
                        v-on:click="handleDeleteButton(false)"
                        v-bind:disabled="waitingForUpdate"
                    >
                        Delete
                    </button>
                    <b-button
                        v-if="restore"
                        size="sm"
                        variant="secondary"
                        v-on:click="handleDeleteButton(true)"
                        v-bind:disabled="waitingForUpdate"
                    >
                        Restore
                    </b-button>
                </div>
            </template>
        </page-structure>

        <change-status
            v-bind:selectedItems="itemsToChange"
            v-on:updated-items="handleUpdatedItems"
            v-on:rendered="tryChangeResume"
        >
        </change-status>
        <edit-items
            v-bind:selectedItems="itemsToChange"
            v-on:updated-items="handleUpdatedItems"
            v-on:rendered="tryEditResume"
        >
        </edit-items>
        
        
    </div>
</template>


<script>

//import UpdatedMessage from './UpdatedMessage';
import PageStructure from './PageStructure'
import AssetRecord from './AssetRecord'
import AddNewItem from './AddNewItem'
import ChangeStatus from './ChangeStatus'
import EditItems from './EditItems'
import editDelete from '../mixins/edit-delete'
import groupedItems from '../mixins/grouped-items'
import base from '../mixins/base'

export default {
    data(){
        return{
            uri: 'assets',
            removalCount: 0,
            actuallyRemoved: 0,
            noun: 'item',
            pluralNoun: 'items',
            localPagination: true, // Forces unlimited result list
            columns: [
                { sort: 'id', name: 'Item #', sortable:false },
                { sort: 'desc', name: 'Description', sortable:false  },
                { sort: 'checkcount', name: 'Checkouts', sortable:false  },
                { sort: 'status', name: 'Status', sortable:false  },
                { sort: 'lasttrans', name: 'Last Transaction', sortable:false  },
                { sort: 'uor', name: 'User of Record', sortable:false  }
            ],
            buttonsDisabled: false,
        }
    },
    
    metaInfo() {
        return {
            title: (this.restore ? 'Deleted ' : '') + 'Items',
        }
    },
    
    props: {
        restore: Boolean,
    },
    
    mixins: [base, editDelete, groupedItems],
    
    components: {
        'asset-record': AssetRecord,
        'add-new-item': AddNewItem,
        'change-status': ChangeStatus,
        'edit-items': EditItems,
        'page-structure': PageStructure,
        //'updated-message': UpdatedMessage,
    },
    
    created: function() {
        this.fetchItems();
    },
    
    computed: {
        
        availabilityByCategory: function() {
            
            // Array to store the availability list
            var availability = [];
            
            // Iterate across the groupedItems object, using each property as if it were an array key
            // This allows to do a for-each loop on each category of assets in the list
            Object.entries(this.groupedItems('category')).forEach( ([category, assets]) => {
                
                // Iterate across each asset in the current category
                assets.forEach(asset => {
                
                    // See if the item is checked in or newly added.
                    if (asset.status.lastTransaction.type == 'in' || asset.status.lastTransaction.type == 'added') {
                        
                        // If so, increment the availability counter for the current category (or create it if it didn't exist yet)
                        availability[category] = availability[category] + 1 || 1;
                    }
                    
                    // If the item is not checked in or newly added, do nothing (or create the counter and set it to 0
                    // if this is the first time encountering this category)
                    else {
                        availability[category] = availability[category] || 0;
                    }
                })
            });

            // Return the availability list
            return availability;
        },
        
        additionalFilters: function() {
            return '&sort=category'
        },
             
    },
    
    watch: {
        showCheckAlert: function() {
            if ( Object.prototype.hasOwnProperty.call(this.showCheckAlert, 'type') && this.showCheckAlert != {} ) {
                if (this.showCheckAlert.type == 'in' || this.showCheckAlert.type == 'out') {
                    this.fetchItems();
                }
                this.$emit('check', {});
            }
        }
    },
    
    methods: {
        tryChangeResume: function() {
            // If the user was trying to do something before reauthorization, resume
            if (this.lastCommand == 'change-status') {
                this.resumedItems = this.lastInput;
                this.handleUpdateItems('change-status');
                this.$emit('resumed');
            }
        }
    }
}
</script>

<style>

table .item-cat td {
    background-color: white;
    border-top:none
}

</style>
