/**
 * base.js - Shared code for retrieving and dealing with various API objects
 *
 * @see       https://github.com/teachernerd/inventory/ The Inventory Box GitHub project
 *
 * @author    Daniel Reinish (teachernerd) <dan@reinish.net>
 * @copyright 2016 - 2022 Daniel Reinish
 * @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
 * @note      This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 */
 
/**
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
 */

import PageNav from '../components/PageNav';
import LoadingRecord from '../components/LoadingRecord';
import RecordStatus from '../components/RecordStatus';

export default {
    
    data(){
        return{
            items: [],
            inactiveItems: [],
            count: 0,
            pending: 0,
            page: 0,
            pq: 0,
            loading: false,
            selected: [],
            selectAllIsChecked: false,
            initialLoadComplete: false
        }
    },
    
    props: {
        restore: Boolean,
        showCheckAlert: Object,
        limit: Number
    },
    
    model: {
        prop: 'showCheckAlert',
        event: 'check'
    },
    
    components: {
        'page-nav': PageNav,
        'loading-record': LoadingRecord,
        'record-status': RecordStatus
    },
    
    computed: {

        start: function() {
            if (this.count) {
                return (this.page - 1) * this.limit + 1;
            }
            else {
                return 0;
            }
        },
    
        end: function() {
            if (this.count) {
                if (this.page == this.last) {
                    return this.count;
                }
                else {
                    return this.page * this.limit;
                }
            }
            else {
                return 0;
            }
        },
    
        last: function() {
            return Math.ceil(this.count / this.limit);
        },
        
        queryWithParams: function() {
            return this.uri + '?getstatus=1&limit=' + (this.localPagination? 0 : this.limit) + '&page=' + this.pq + '&active=' + (this.restore ? 0 : 1) + this.additionalFilters;
        },
        
        rawPQ: function() {
            return this.$route.query['page']
        },
        
        additionalFilters: function() {
            return '';
        },
        
        basePageProps: function() {
            return {
                authorized: this.$parent.isAuthorized,
                authFunction: this.$parent.authorize,
                scope: this.$parent.scope,
                loading: this.loading,
                start: this.start,
                end: this.end,
                count: this.count,
                currentPage: this.page,
                lastPage: this.last,
                initialLoadComplete: this.initialLoadComplete
            }
        }
    },
    
    watch: {
        rawPQ: function() {
            this.requestMessage = "Loading...";
            this.getPageQuery();
            if (!this.localPagination) {
                this.fetchItems();
            }
            else {
                this.setCorrectPageNumber();
            }
        },
        
        restore: function() {
            this.fetchItems();
        },
        
        count: function() {
            if (this.count > 0) {
                this.loading = false;
                this.initialLoadComplete = true;
            }
        }
    },
        
    created: function()
    {
        this.requestMessage = "Loading...";
        this.getPageQuery();
    },
    

    methods: {
        fetchItems: function()
        {
        
            // Clear table
            this.items = [];
        
            // Trigger the loading message
            this.loading = true;
            this.count = 0;
            
            // Clear checkmarks. Makes UI clearer and avoids weird errors with associated
            // with error buttons when switching pages.
            this.selected = [];
            
            // Fetch inactive items first
            let uri = 'assets?active=0';
            this.axios.get(uri)
                .then((response) => {
                    this.inactiveItems = response.data.map(function(value) { 
                        return value['id'];
                    });
                
                    // Now do the actual fetch
                    let uri = this.queryWithParams;
                    this.axios.get(uri)
                        .then((response) => {
                            this.items = response.data;
                            this.count = parseInt(response.headers['total-count']);
                            this.pending = response.headers['pending-count'];
                            this.setCorrectPageNumber();
                            
                            // If the number of items returned is 0, stop the loading message
                            // (Otherwise, we let the watcher do it to avoid excessive interface flashing.)
                            if (this.count == 0) {
                                this.loading = false;
                                this.initialLoadComplete = true;
                            }
                        })
                        .catch(() => {
                            this.showError();
                            this.loading = false;
                            this.initialLoadComplete = true;
                        });
                })
                .then(() => {
                    //this.loading = false;
                })
                .catch(() => {
                    this.showError();
                    this.loading = false;
                    this.initialLoadComplete = true;
                });
        },
            
        getPageQuery: function() {
            // Get page query
            if (this.$route.query['page'] == null) {
                this.pq = 1;
            }
            else {
                this.pq = Number(this.$route.query['page']);
            }
        },
        setCorrectPageNumber: function() {
            // Correct for page number too high
            if (this.pq == 0 && this.last > 0) {
                this.page = 1;
                this.pq = 1;
            }
            else if (this.pq > this.last) {
                this.page = this.last;
                this.pq = this.last;
            }
            else {
                this.page = this.pq;
            }
        },
        
        showError() {
            // Show error
            
            this.$bvModal.msgBoxOk(`The requested items could not be fetched. Please
                                    refresh the page and try again.`, {
                title: 'Network Error',
                size: 'sm',
                buttonSize: 'sm',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: false,
                okVariant: 'warning'
            });
            
        },
        
        selectAll: function() {
            this.selected = [];
            if (this.selectAllIsChecked) {
                this.items.forEach(user => this.selected[user.id] = true);
            }
        },
        
        updateSelectAll: function() {
            if (this.selected.length) {
                const selectedCount = this.selected.reduce((totalTrue, selection) => {
                    return (selection) ? totalTrue + 1 : totalTrue;
                });
                this.selectAllIsChecked = (selectedCount == this.items.length);
            }
            else {
                this.selectAllIsChecked = 0;
            }
        },
    },
    
    filters: {
        capitalize: function(str) {
            if(typeof str === 'string') {
                return str.replace(/^\w/, c => c.toUpperCase());
            } else {
                return '';
            }
        }
    }
};