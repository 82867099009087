/**
 * record-transaction.js - Shared code for components that display individual transaction records
 *
 * @see       https://github.com/teachernerd/inventory/ The Inventory Box GitHub project
 *
 * @author    Daniel Reinish (teachernerd) <dan@reinish.net>
 * @copyright 2016 - 2022 Daniel Reinish
 * @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
 * @note      This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 */
 
/**
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
 */
 
 
export default {

    props: {
        checkout: Object,
        inactiveItems: Array,
        filterItem: Boolean,
        filterUser: Boolean,
        filterGroup: Boolean
    },
    
    computed: {
        checkoutDate: function() {
            return new Date(this.checkout.timestamp.replace(' ', 'T'));
        },
        asset: function() {
            var prefix = (this.checkout.asset.id == -1 || this.checkout.asset.id == 0) ? '' : this.checkout.asset.id + ' - ';
            return prefix + this.checkout.asset.description;
        },
        assetClass: function() {
            if (this.checkout.asset.id == -1) {
                return 'special';
            }
            else if (this.inactiveItems.includes(this.checkout.asset.id)) {
                return 'inactive';
            }
            else {
                return '';
            }
        },
        showing: function() {
            var show = '';
            
            // General checkout filters
            if (typeof this.$route.query['show'] != 'undefined' && (this.$route.query['show'] == 'nonreturned' || this.$route.query['show'] == 'completed' || this.$route.query['show'] == 'incomplete' || this.$route.query['show'] == 'checkouts')) {
                show = this.$route.query['show'];
            }
            else {
                show = 'checkouts';
            }
            return show;
        },        
    },
    
    filters: {
        itemString: function(item) {
            return item.id + ' - ' + item.description;
        }
    
    }
};