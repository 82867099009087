<!-- PageNav.vue - Component for displaying page navigation elements -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    
    <div id="page-nav" class="row">
        <div class="col">
            <b-pagination-nav
                use-router
                v-bind:link-gen="linkGen"
                v-bind:number-of-pages="lastPage"
                v-model="theCurrentPage"
                size="sm"
                align="center"
                hide-ellipsis
                exact
            >
                <template v-slot:prev-text>&lsaquo;<span class="d-none d-lg-inline"> Prev</span></template>
                <template v-slot:next-text><span class="d-none d-lg-inline">Next </span>&rsaquo;</template>
            </b-pagination-nav>
        </div>
    </div>
</template>


<script>

export default {
    data() {
        return {
            theCurrentPage: this.currentPage
        }
    },
    props: {
        currentPage: Number,
        lastPage: Number,
        additionalQueries: Object
    },
    
    methods: {
        linkGen: function(pageNum) {
            return {
                query: { page: pageNum, ...this.additionalQueries }
            }
        }
    }
}

</script>


<style>


#page-nav a:hover {
    text-decoration:underline
}

#page-nav a {
    /*color: #007bff;*/
}

</style>