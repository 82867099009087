/**
 * grouped-items.js - Shared code for dealing with item grouping
 *
 * @see       https://github.com/teachernerd/inventory/ The Inventory Box GitHub project
 *
 * @author    Daniel Reinish (teachernerd) <dan@reinish.net>
 * @copyright 2016 - 2022 Daniel Reinish
 * @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
 * @note      This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 */
 
/**
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
 */
 
 
export default {
  
    methods: {
        
        /*
         * This function is a modified version of robmathers/groupBy.js 
         *
         * Original Author: Rob Mathers
         * Source: https://gist.github.com/robmathers/1830ce09695f759bf2c4df15c29dd22d
         *
         * Original description: A more readable and annotated version of the Javascript groupBy from
         * Ceasar Bautista (https://stackoverflow.com/a/34890276/1376063)
         *
         */
        groupedItems: function(groupKey) {
            // reduce runs this anonymous function on each element of `data` (the `item` parameter,
            // returning the `storage` parameter at the end
            var groupedItems = this.items.reduce( (storage, item) => {
                // get the first instance of the key by which we're grouping
                
                var group = '';
                if (groupKey == 'category' || groupKey == 'group') {
                    group = item[groupKey].name;
                }
                if (groupKey == 'user') {
                    group = item.status.lastTransaction.user.firstName + ' ' + item.status.lastTransaction.user.lastName /*+ ' (#' + item.status.lastTransaction.user.id + ')'*/;
                }

                // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
                storage[group] = storage[group] || [];

                // add this item to its group within `storage`
                storage[group].push(item);
                
                // If this is a group of users, ensure that the group is sorted alphabetically 
                if (groupKey == 'group') {
                    storage[group].sort((a, b) => (a.lastName > b.lastName) ? 1 :
                        a.lastName === b.lastName ? ((a.firstName > b.firstName) ? 1 : -1) : -1
                    );
                }
                
                // return the updated storage to the reduce function, which will then loop through the next 
                return storage; 
                
            }, {}); // {} is the initial value of the storage

            return groupedItems;
        },

    
    }
    
};