<!-- DisplaySettings.vue - Component for showing Display Settings dialog -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    <b-modal
        v-bind:id="modal"
        v-bind:title="title"
        v-on:ok="handleSaveSettings"
        v-on:show="handleShow"
        ok-title="Save"
        size="sm"
        v-bind:ok-disabled="disabled"
        v-bind:cancel-disabled="disabled"
    >
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <label for="query-start">Query start date:</label>
                    <b-form-datepicker
                        id="query-start"
                        v-model="items.beginDate"
                        class="mb-2"
                        size="sm"
                        today-button
                        close-button
                        hide-header
                        v-bind:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        locale="en"
                    >
                    </b-form-datepicker>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <label for="query-end">Query end date:</label>
                    <b-form-datepicker
                        id="query-end"
                        v-model="items.endDate"
                        class="mb-2"
                        size="sm"
                        today-button
                        hide-header
                        v-bind:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        locale="en"
                    >
                    </b-form-datepicker>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <label for="time-zone">Time Zone:</label>
                    <b-form-select
                        id="time-zone"
                        v-model="items.timezone"
                        v-bind:options="timezoneChoices"
                        size="sm"
                    >
                    </b-form-select>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-10">
                    <label for="overdue">Overdue Threshold:</label>
                    <b-form-input
                        id="overdue"
                        v-model="items.overdueThreshold"
                        type="number"
                        size="sm"
                        class="col-4"
                    >
                    </b-form-input>
                    <small id="overdueHelp" class="form-text text-muted">After how many days (excluding weekends) should a checkout be marked as 'overdue'?</small>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <p class="text-secondary" v-if="success == 1">Saving...</p>
                    <p class="text-danger" v-else-if="success == -1">Failed to update checkout settings: {{ failReason }}</p>
                    <p style="visibility: hidden;" v-else>Status</p>
                </div>
            </div>
        </div>     
    </b-modal>
</template>


<script>

import settingsDialog from '../mixins/settings-dialog'

export default {

    data() {
        return {
            uri: 'settings/query',
            id: 'save-display',
            modal: 'display-settings',
            title: 'Display Settings'
        }
    },
    
    components: {
        
    },
    
    props: {
        expire: [Object, Date],
        lastCommand: String,
        lastInput: [String, Array, Object],
    },
    
    mixins: [settingsDialog],
    
    computed: {
        timezoneChoices: function() {
            // Get list of timezones from server
            let timezones = this.items.allTimezones;
            
            // Array to store the properly formatted list
            let timezoneOptions = [];
            
            // Loop through each of the continents in the server list
            for (var continent in timezones) {
                if (Object.prototype.hasOwnProperty.call(timezones, continent)) {
                
                    // Get individual timezones (in proper format) for each continent group
                    let zones = [];
                    for (var zone in timezones[continent]) {
                        if (Object.prototype.hasOwnProperty.call(timezones[continent], zone)) {
                            // Build array of timezones for given continent
                            zones.push({
                                text: timezones[continent][zone],
                                value: zone
                            });
                        }
                    }
                    
                    // Build array of continents
                    timezoneOptions.push({
                        label: continent,
                        options: zones
                    });
                }
            }
            return timezoneOptions;
        },
        
        
    },
    
    methods: {
    
        saveSettingsValidator: function() {
            
            let saveData = {
                beginDate: this.items.beginDate,
                endDate: this.items.endDate,
                timezone: this.items.timezone,
                overdueThreshold: this.items.overdueThreshold
            }
            
            return saveData;
        },
    
        
        validateResponse: function(response) {
            let valid = ( (response.data.beginDate == this.items.beginDate) && (response.data.endDate == this.items.endDate) && (response.data.timezone == this.items.timezone) );
            if (valid) {
                this.$emit('updateQueryDates');
            }
            return valid;
        }
    }
}
</script>
