<!-- AddNewItem.vue - Component for "New User" interface -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
   
    <b-modal
        id="add-new"
        title="Add New User"
        v-on:ok="handleAddNewSaveButton"
        v-on:show="resetAddNew"
        ok-title="Save"
        v-bind:ok-disabled="disabled"
        v-bind:cancel-disabled="disabled"
    >
        <form method="post"> 
            <b-container fluid>
                <b-row class="my-3">
                    <b-col sm="3">
                      <label for="input-last">Last Name:</label>
                    </b-col>
                    <b-col sm="8">
                      <b-form-input id="input-last" size="sm" v-model="newUserLastName" maxlength=20></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="my-3">
                    <b-col sm="3">
                      <label for="input-first">First Name:</label>
                    </b-col>
                    <b-col sm="8">
                      <b-form-input id="input-first" size="sm" v-model="newUserFirstName" maxlength=20></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="my-3">
                    <b-col sm="3">
                      <label for="input-email">Email:</label>
                    </b-col>
                    <b-col sm="8">
                      <b-form-input id="input-email" size="sm" v-model="newUserEmail" maxlength=50></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="my-3">
                    <b-col sm="3">
                      <label for="input-group">Group:</label>
                    </b-col>
                    <b-col sm="5">
                      <b-form-select
                        id="input-group" size="sm"
                        v-model="newUserGroup"
                        v-bind:options="newUserGroupOptions"
                      >
                      </b-form-select>
                    
                    </b-col>
                </b-row>
                <b-row v-if="error != ''">
                    <p class="text-danger"> {{ error }}</p>
                </b-row>
            </b-container>
        </form>
    </b-modal>

</template>

<script>

import netfail from '../mixins/netfail'

export default {
    data(){
        return {
            newUserLastName: '',
            newUserFirstName: '',
            newUserEmail: '',
            newUserGroup: '',
            newUserGroupOptions: [
              { value: null, text: '' },
            ],
            error: '',
            disabled: false
        }
    },
    
    props: {
    
    },
    
    mixins: [netfail],
    
    created: function() {
        // Get group list
        this.axios.get('groups')
            .then((response) => {
                response.data.forEach(group => {
                    this.newUserGroupOptions.push({
                        value: group.id,
                        text: group.name 
                    });
				})
			})
			.catch(() => {
                console.log('Network Failure');
                if (!this.networkFalure) this.networkFailure = true;
            });
    },
    
    mounted: function() {
        this.$nextTick(function () {
            this.$emit('rendered');
        });
    },
    
    methods: {
        
        multipleTest() {
            this.$bvModal.msgBoxOk('This function has not been implemented yet.',
                {
                    title: 'Sorry!',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'info'
                }
            );
        },
        
        resetAddNew() {
            // Clear error
            this.error = '';
        },
        
        handleAddNewSaveButton(bvModalEvt) {
			
			this.disabled = true;
			
            // Override default close behavior
            bvModalEvt.preventDefault();
            
            // Submit the new item
            this.handleAddNewSubmit();
            
        },
        
        handleAddNewCancelButton() {
                    
            // Clear error
            this.error = '';
            
        },
        
        handleAddNewSubmit() {
            
            // Ensure names have been entered
            if (this.newUserLastName == '' || this.newUserFirstName == '') {
                this.error = 'First and last name are required!'
                this.disabled = false;
                return;
            }
            
            // Ensure that name is correct format
            const regex = RegExp('^[a-zA-Z -]*$');
            if (!regex.test(this.newUserLastName) || !regex.test(this.newUserFirstName)) {
                this.error = 'Names may contain only letters, spaces, and hyphens!';
                this.disabled = false;
                return;
            }
            
            // Email can be left blank, but if it's entered we need to validate it
            if (this.newUserEmail != '') {
                        
                // Validate email
                if (!this.$regexEmail.test(this.newUserEmail)) {
                    this.error = 'Invalid email!';
                    this.disabled = false;
                    return;
                }
            }
            
            // Group must not be blank.
            if (this.newUserGroup == 0) {
                this.error = 'Group is required!';
                this.disabled = false;
                return;
            }
           
            // Submit new user to API
            let uri = 'users';
            this.axios.post(uri, {
                lastName: this.newUserLastName,
                firstName: this.newUserFirstName,
                email: this.newUserEmail,
                group: this.newUserGroup
            })
            .then((response) => {
                console.log(response);
                
                // Clear inputs
                this.newUserLastName = '',
                this.newUserFirstName = '',
                this.newUserEmail = '',
                this.newUserGroup = '',
                
                // Close the modal
                this.$bvModal.hide('add-new')
                
                // Send a success notification to the parent
                this.$emit('added', 1);
                
                // Reenable buttons for next submission
                this.disabled = false;
                
                // Display confirmation
                
                /*
                this.$bvModal.msgBoxOk(response.data.firstName + ' ' + response.data.lastName +
                    ' has been added to the user list.',
                    {
                        title: 'Success!',
                        size: 'md',
                        buttonSize: 'sm',
                        okVariant: 'success'
                    }
                );
                */
            })
            .catch((error) => {
                if (error.response) {
                    this.$bvModal.hide('add-new'); 
                    // Display confirmation
                    this.$bvModal.msgBoxOk(error.response.data.reason_phrase,
                        {
                            title: 'Error!',
                            size: 'md',
                            buttonSize: 'sm',
                            okVariant: 'warning'
                        }
                    ).then( () => {
                        this.$bvModal.show('add-new');
                    });
                }
                else {
                    this.$bvModal.msgBoxOk('New user could not be saved. Please try again.',
                        {
                            title: 'Something went wrong...',
                            size: 'md',
                            buttonSize: 'sm',
                            okVariant: 'warning'
                        }
                    ).then( () => {
                        this.$bvModal.show('add-new');
                    });
                }
                
                // Reenable buttons for next submission
                this.disabled = false;
                
            });
        }
        

    
    }
    
    
}
</script>

<style>

</style>
