<!-- CategoriesPage.vue - Component for displaying categories page -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
     <div class="container">
        <div class="row">
            <div class="col">
                <h2><span v-if="restore">Deleted </span>{{ pluralNoun | capitalize }}</h2>
            </div>
        </div>
        <div v-if="!restore && $parent.isAuthorized && $parent.scope == 'admin'" class="row mb-4 mt-3">
            <div class="col">
                <add-new-category
                    v-on:added="handleAddedItems"
                    v-on:checkAuth="handleCheckAuth"
                    v-on:resumed="$emit('resumed')"
                    v-bind:includeOrder="includeOrder"
                    v-bind:includeEmail="includeEmail"
                    v-bind:title="noun | capitalize"
                    v-bind:uri="uri"
                    v-bind:lastCommand="lastCommand"
                    v-bind:expire="expire"
                    v-bind:noun="noun"
                    v-bind:pluralNoun="pluralNoun"
                >
                </add-new-category>
            </div>          
        </div>
        <div class="row mb-3">
             <div class="col align-self-end">
                <span v-if="!$parent.isAuthorized">No records available. Please <a href="login" v-on:click.prevent="$parent.authorize()">log in</a> to access the checkout system.</span>
                <span v-else-if="loading">Loading...</span>
                <span v-else-if="count > 0">Displaying {{ start }}-{{ end }} of {{ count }} {{ restore ? 'deleted' : '' }} {{ pluralNoun }}</span>
                <span v-else>The request returned 0 results.</span>
            </div>
        </div>
        <div class="row">
            <div class="col-5">
                <updated-message
                    v-bind:showMessage.sync="showUpdateMessage"
                    v-bind:count="updateCount"
                    v-bind:noun="noun"
                    v-bind:pluralNoun="pluralNoun"
                ></updated-message>
            </div>
        </div>
        <div v-if="$parent.isAuthorized" class="row">
            <div class="col-5">
                <div class="table-responsive">
                    <table class="table table-hover table-sm table-striped">
                        <thead class="thead-light">
                        <tr>
                            <th v-if="$parent.scope == 'admin'">
                                <b-form-checkbox v-on:change='selectAll' v-model='selectAllIsChecked'></b-form-checkbox>
                            </th>
                            <th>Name</th>
                            <th v-if="includeOrder">Sort</th>
                            <th v-if="includeEmail && $parent.scope == 'admin'">Email</th>
                        </tr>
                        </thead>

                        <tbody>
                            <category-record
                                v-for="category in items"
                                v-model="selected[category.id]"
                                v-bind:category = "category"
                                v-bind:showMark = "$parent.scope == 'admin'"
                                v-bind:key="category.id"
                                v-bind:includeOrder="includeOrder"
                                v-bind:includeEmail="includeEmail"
                                v-on:change="updateSelectAll"
                            ></category-record>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-if="$parent.isAuthorized && count > 0 && $parent.scope == 'admin'" class="row mb-4">
            <div class="col-5 text-right">
                <b-button
                    v-if="!restore"
                    size="sm"
                    variant="secondary"
                    v-on:click="handleUpdateItems('edit-' + pluralNoun)"
                    class="mr-2"
                >
                    Edit
                </b-button>
                <button
                    v-if="!restore"
                    type="button"
                    class="btn btn-sm btn-danger"
                    v-on:click="handleDeleteButton(false)"
                >
                    Delete
                </button>
                <b-button
                    v-if="restore"
                    size="sm" variant="secondary"
                    v-on:click="handleDeleteButton(true)"
                >
                    Restore
                </b-button>
            </div>
            <edit-categories
                v-bind:selectedItems="itemsToChange"
                v-bind:id="'edit-' + pluralNoun"
                v-bind:title="pluralNoun | capitalize"
                v-bind:includeOrder="includeOrder"
                v-bind:includeEmail="includeEmail"
                v-bind:uri="uri"
                noun="category"
                v-bind:activeCategories="items"
                v-on:rendered="tryEditResume"
                v-on:updated-items="handleUpdatedItems"
            >
            </edit-categories>
        </div>
    </div>
</template>

<script>

import UpdatedMessage from './UpdatedMessage';
import CategoryRecord from './CategoryRecord'
import AddNewCategory from './AddNewCategory'
import EditCategories from './EditCategories'
import editDelete from '../mixins/edit-delete'
import base from '../mixins/base'

export default {
    
    data(){
        return{
            /*
            uri: 'categories',
            noun: 'category',
            pluralNoun: 'categories'
            */
            selectAllStatus: false,
        }
    },
    
    metaInfo () {
        return {
            title: (this.restore ? 'Deleted ' : '') + this.$options.filters.capitalize(this.pluralNoun)
        }
    },
    
    props: {
        uri: String,
        noun: String,
        pluralNoun: String,
        includeOrder: Boolean,
        includeEmail: Boolean
    },
    
    mixins: [base, editDelete],
    
    components: {
        'category-record': CategoryRecord,
        'edit-categories': EditCategories,
        'add-new-category': AddNewCategory,
        'updated-message': UpdatedMessage,
    },
    
    created: function() {
        this.fetchItems();
    },
    
    computed: {
    
        additionalFilters: function() {
            return '&sort=order'
        } 
        
    },
    
    watch: {
        uri: function() {
            this.fetchItems();
        }
    },
    
    methods: {
       
    }
    
}
</script>

<style>

    #add-new-mult .modal-dialog, #edit-users .modal-dialog {
        width: 97.5%;
        max-width: 950px;
    
    }
</style>
