<!-- CheckoutRecord.vue - Component for displaying invidual row of checkout table -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    <tr v-bind:class="[rowClass]">
        <td
            v-if="showMark"
        >
            <b-form-checkbox
                v-if="(status == 'returned' || status == 'logged')"
                name="selections[]"
                v-bind:id="'selection-' + checkout.id"
                v-model="isChecked"
                v-bind:checked="checked"
                v-on:change="$emit('change', isChecked)"
            >
            </b-form-checkbox>
        </td>
        <td
            v-bind:class="{special: (checkout.user.id == 1), inactive: (!checkout.user.active)}"
            v-if="!filterUser"
        >  
            <span v-if="checkout.user.active && checkout.user.id != 1">
                <router-link
                    v-bind:to="{name: 'Checkouts', query: { user: checkout.user.id, show: showing, ...specificQueryVars }}"
                >
                    {{ checkout.user.firstName }} {{ checkout.user.lastName }}
                </router-link>
            </span>
            <span v-else>
                {{ checkout.user.firstName }} {{ checkout.user.lastName }}
            </span>
        </td>
        <td v-bind:class="[assetClass]" v-if="!filterItem">
            <span v-if="checkout.asset.id == 0">
                <ul style="list-style-type: none; padding: 0; margin: 0">
                    <li v-for="groupedAsset in checkout.asset.collection" v-bind:key="groupedAsset.id">
                        <span v-bind:style="groupedAsset.status == 'returned' ? 'font-weight: normal !important' : ''">{{ groupedAsset | itemString }}</span>
                    </li>
                </ul>
            </span>
            <span v-else-if="checkout.asset.id != -1 && assetClass != 'inactive'">
                <router-link
                    v-bind:to="{name: 'Checkouts', query: { item: checkout.asset.id, show: showing, ...specificQueryVars }}"
                >
                    {{ asset }}
                </router-link>
            </span>
            <span v-else> {{ asset }} </span>
        </td>
        <td>
            <span v-if="checkout.asset.id == 0">
                <ul style="list-style-type: none; padding: 0; margin: 0">
                    <li v-for="groupedAsset in checkout.asset.collection" v-bind:key="groupedAsset.id">
                        <span v-bind:style="groupedAsset.status == 'returned' ? 'font-weight: normal !important' : ''">{{ groupedAsset.status }}</span>
                    </li>
                </ul>
            </span>
            <span v-else-if="status == 'out' || status == 'overdue' || status == 'completed'">
                <router-link
                    v-bind:to="{name: 'Checkouts', query: { show: filterBasedOnStatus, ...specificQueryVars }}"
                >
                    {{ status }}
                </router-link>
            </span>
            <span v-else>
                {{ status }}
            </span>
        </td>
        <td v-bind:title="checkout.timestamp">
            {{ checkoutDate | prettyDate }}
        </td>
        <td>
            {{ checkout.note }}
        </td>
    </tr>
</template>

<script>

import record from '../mixins/record'
import recordTransaction from '../mixins/record-transaction'

export default {
    data(){
        return {
            
        }
    },
    
    props: {
        specificQueryVars: Object
    },
    
    mixins: [record, recordTransaction],
    
    computed: {
        
        status: function () {
            //return (this.checkout.status == 'out' && this.overdue) ? 'overdue' : this.checkout.status;
            let status = this.checkout.status;
            if (!this.showMark && status == 'completed') {
                status = this.checkout.asset.id == -1 ? 'logged' : 'returned';
            }
            return status;
        },
        
        filterBasedOnStatus: function() {
            switch (this.status) {
                case 'out':
                case 'overdue':
                    return 'nonreturned';
                case 'completed':
                    return 'completed';
                default:
                    return '';
            }
        }
    },
}
</script>

<style>

td.inactive {
    color: gray;
    text-decoration: line-through;
}

td.special, tr.special, tr.special td a {
    color: gray;
}

tr.missing {
    font-style: italic;
}
tr.late {
    color: red;
    font-weight: bold;
}

tr.late a {
    color: red;
}

tr.late a:hover {
    color: #007bff;
    text-decoration: underline;
}

tr.out{
    color: black;
    font-weight: bold;
}

tr.out a {
    color: black;
}

tr.out a:hover {
    color: #007bff;
    font-weight: bold;
}

td a:hover {
    color: #007bff;
}

table .router-link-active:hover {
    color: #007bff;
}

</style>
