import { render, staticRenderFns } from "./UserRecord.vue?vue&type=template&id=15cc0fbd"
import script from "./UserRecord.vue?vue&type=script&lang=js"
export * from "./UserRecord.vue?vue&type=script&lang=js"
import style0 from "./UserRecord.vue?vue&type=style&index=0&id=15cc0fbd&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports