<!-- UpdatedMessage.vue - Component for showing embedded confirmation messages -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    <div class="updated-message">
        <b-alert
            v-bind:show="dismissCountDown"
            dismissible
            fade
            v-bind:variant="failure ? 'danger' : 'success'"
            v-on:dismissed="dismissCountDown=0"
            v-on:dismiss-count-down="countDownChanged"
        >
            <div v-if="!failure">Successfully {{ computedAction }} {{ count }} {{ count > 1 ? computedPluralNoun : noun }}.</div>
            <div v-else>One or more assignments could not be updated.</div>
        </b-alert>
    </div>
</template>

<script>

export default {
    
    data(){
        return{
            dismissCountDown: 0,
            defaultDismissSecs: 10,
            defaultAction: 'updated'
        }
    },
    
    props: {
        showMessage: Boolean,
        duration: Number,
        failure: Boolean,
        count: Number,
        noun: String,
        pluralNoun: String,
        action: String
    },
    
    computed: {
        computedPluralNoun: function() {
            return (this.pluralNoun != null) ? this.pluralNoun : this.noun + 's';
        },
        
        dismissSecs: function() {
            return (this.duration > 0) ? this.duration : this.defaultDismissSecs;
        },
        
        computedAction: function() {
            return (this.action != null) ? this.action : this.defaultAction;
        }
    },
    
    watch: {
        showMessage: function() {
            if (this.showMessage) {
                this.dismissCountDown = this.dismissSecs;
                this.$emit('update:showMessage', false);
            }
        }
    
    },
    
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown;
        }
    
    }
    
    
}
</script>

<style>


    
</style>
