/**
 * settings.js - Shared code used by settings dialogs
 *
 * @see       https://github.com/teachernerd/inventory/ The Inventory Box GitHub project
 *
 * @author    Daniel Reinish (teachernerd) <dan@reinish.net>
 * @copyright 2016 - 2022 Daniel Reinish
 * @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
 * @note      This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 */
 
/**
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
 */
 
 
import base from '../mixins/base'

export default {
    
    data(){
        return{
            success: 0,
            failReason: '',
            dismissSecs: 5,
            countdown: null,
            disabled: false
        }
    },
    
    props: {
        expire: [Object, Date],
        lastCommand: String,
        lastInput: [String, Array, Object]
    },
    
    mixins: [base],
    
    model: {
        
    },
    
    components: {
        
    },
    
    computed: {

        
    },
    
    watch: {
        
    },
        
   
    methods: {
        // Fires when modal pops up
        handleShow: function() {
            // Reset buttons
            this.disabled = false;
            
            // Reset notices
            this.success = 0;
            this.replyToState = null;
            
            // Attempt to resume from failed save
            if (this.lastCommand == this.id) {
                this.items = this.lastInput;
                this.doSaveSettings();
                this.$emit('resumed');
            }
            
            // If no failed save to resume from, get the old settings from API
            else {
                this.fetchItems();
            }
        },
        
        // Fires when user clicks on save button
        handleSaveSettings: function(e) {  
            e.preventDefault();
            this.disabled = true;
            this.doSaveSettings();
        },
        
        // Does the saving process
        doSaveSettings: function() {
            // Stop any notification countdown that may have been started previously
            clearInterval(this.countdown);
            
            // Do an auth check before attempting to save
            const currentTime = new Date();
            if ( (this.expire.getTime() - currentTime.getTime()) <= 0 ) {
                this.$emit('checkAuth', {
                    lastCommand: this.id, // Each component sends a unique message
                    lastInput: this.items
                });
                this.disabled = false;
                return;
            }
            
            // If we've passed the auth check, attempt to validate the input
            let saveData = this.saveSettingsValidator();
            if (!saveData) {
                this.disabled = false;
                return false;
            }
                 
            // Cause a loading message to be displayed
            this.success = 1;
           
            // Submit the API request
            this.axios({
                url: this.uri,
                method: 'put',
                data: saveData
            })
            
            // If successful...
            .then((response) => {
                // Verify that returned data is equivalent to submitted data.
                // (However, skip the message data since this gets run through an HTML
                // validator which will inevitably change it.)
                if (this.validateResponse(response)) {
                    this.$bvModal.hide(this.modal);
                    this.displaySuccess();
                }
                // If somehow the data is not equivalent, then the update wasn't successful
                else {
                    this.failReason = 'Unknown error';
                    this.setSuccess(false);
                }
                
                this.disabled = false;
            })
            
            // If API request is unsuccessful
            .catch((error) => {
                this.failReason = error.response.data.reason_phrase;
                this.setSuccess(false);
                this.disabled = false;
            });
            
            return false;
            
        },
        
        // Display success or failure messages with a count-down timer
        setSuccess: function(b) {
            this.success = b ? 1 : -1;
            var seconds = this.dismissSecs;
            this.countdown = setInterval(() => {
                seconds--;
                if (seconds <= 0) {
                    clearInterval(this.countdown);
                    this.success = 0;
                }
            }, 1000);
        },
        
        displaySuccess: function() {
            this.$bvModal.msgBoxOk('Your changes have been saved successfully!', {
                title: this.title,
                size: 'sm',
                okVariant: 'success'
            });
        }
        
    },
    

};