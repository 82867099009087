<!-- RecordStatus.vue - Component for showing range of records being viewed -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    <div class="row mb-3">
        <div class="col align-self-end">
            <span v-if="!authorized">No records available. Please <a href="login" v-on:click.prevent="$parent.authorize()">log in</a> to access the checkout system.</span>
            <span v-else-if="loading">Loading...</span>
            <span v-else-if="showDetails && count > 0">Displaying {{ start }}-{{ limit == 0 || grouped ? count : end }} of {{ count }} {{ active }} {{ pluralNoun }} <slot></slot>
                <b-link
                    id="tableSettings" ref="tableSettings" v-if="limit > 0 && !grouped"
                >
                    <b-icon-gear-fill variant="secondary"></b-icon-gear-fill>
                </b-link>
                <b-popover
                    v-if="limit > 0"
                    target="tableSettings"
                    triggers="click"
                    placement="right"
                    container="my-container"
                    ref="popover"
                    v-bind:show.sync="popoverShow"
                    v-on:show="onShow"
                >
                    <template v-slot:title>
                        <b-button id="table-settings-close" v-on:click="onClose" class="close mt-n1 mb-2" aria-label="Close">
                            <span class="d-inline-block" aria-hidden="true">&times;</span>
                        </b-button>
                        Customize
                    </template>
                    
                    <div>
                        <b-form-group
                            label="Rows per page:"
                            label-for="row-limit"
                            class="mb-2"
                        >
                            <b-form-select
                                ref="limit"
                                id="rows-per-page"
                                v-model="chosenLimit"
                                size="sm"
                                class="col-6"
                            >
                                 <b-form-select-option v-bind:value="5">5</b-form-select-option>
                                 <b-form-select-option v-bind:value="10">10</b-form-select-option>
                                 <b-form-select-option v-bind:value="15">15</b-form-select-option>
                                 <b-form-select-option v-bind:value="20">20</b-form-select-option>
                                 <b-form-select-option v-bind:value="25">25</b-form-select-option>
                                 <b-form-select-option v-bind:value="30">30</b-form-select-option>
                                 <b-form-select-option v-bind:value="35">35</b-form-select-option>
                                 <b-form-select-option v-bind:value="40">40</b-form-select-option>
                                 <b-form-select-option v-bind:value="45">45</b-form-select-option>
                                 <b-form-select-option v-bind:value="50">50</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                        <b-button v-on:click="onClose" size="sm" variant="outline-secondary" class="mr-2">Cancel</b-button>
                        <b-button v-on:click="onSave" size="sm" variant="outline-primary">Save</b-button>
                    </div>

                </b-popover>
            </span>
            <span v-else-if="count > 0"></span>
            <span v-else>The request returned 0 results.</span>
        </div>
        
        
        
    </div>
</template>

<script>

export default {
    
    data(){
        return{
            popoverShow: false,
            chosenLimit: 0
        }
    },
    
    computed: {
        active: function() {
            switch (this.restore) {
                case true:
                    return 'deleted';
                case false:
                default:
                    return '';
            }
        }
    },
    
    props: {
        authorized: Boolean,
        loading: Boolean,
        authFunction: Function,
        showDetails: Boolean,
        start: Number,
        end: Number,
        count: Number,
        restore: Boolean,
        pluralNoun: String,
        limit: Number,
        grouped: Boolean
    },
    
    methods: {
        onClose: function() {
            this.popoverShow = false;
        },
        
        onShow: function() {
            this.chosenLimit = this.limit;
        },
        
        onSave: function() {
            this.$emit('updated-limit', this.chosenLimit);
            this.popoverShow = false;
        }
    }
    
    
}
</script>