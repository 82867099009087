<!-- AddNewCategory.vue - Component for displaying "New Category" interface -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    <div id="add-new-button">
        <b-button size="sm" variant="primary" v-on:click="showAddNew">Add New {{ title }}</b-button>
        <b-modal
            id="add-new"
            v-bind:title="'Add New ' + title" 
            v-on:ok="handleAddNewSaveButton"
            v-on:show="resetAddNew"
            ok-title="Save"
            size="sm"
        >
            <form method="post"> 
                <b-form-group
                    id="category-name"
                    v-bind:label="title + ' Name:'"
                    label-for="input-cat"
                >
                    <b-form-input class="col-12" id="input-cat" size="sm" v-model="newCategoryName" maxlength=20></b-form-input>
                </b-form-group>
                <b-form-group
                    v-if="includeEmail"
                    id="category-email"
                    label="Email Address:"
                    label-for="input-cat"
                    description="Optional: Provide a supervisor address that can be included on notification messages."
                >
                    <b-form-input class="col-12" type="email" id="input-cat" size="sm" v-model="newCategoryEmail" maxlength=20></b-form-input>
                </b-form-group>
                <b-form-group
                    v-if="includeOrder"
                    id="category-sort"
                    label="Sort Order:"
                    label-for="input-sort"
                    description="Optional"
                >
                    <b-form-input class="col-6" id="input-desc" size="sm" v-model="newSortIndex" maxlength=5></b-form-input>
                </b-form-group>
                <span v-if="error != ''">
                    <p class="text-danger"> {{ error }}</p>
                </span>
            </form>
        </b-modal>
    </div>
</template>

<script>

export default {
    data(){
        return {
            existingCategories: [],
            newCategoryName: '',
            newCategoryEmail: '',
            newSortIndex: '',
            error: ''
        }
    },
    
    props: {
        includeOrder: Boolean,
        includeEmail: Boolean,
        title: String,
        uri: String,
        lastCommand: String,
        expire: Date,
        noun: String,
        pluralNoun: String
    },
    
    created: function() {
        this.fetchExisting();
    },
    
    mounted: function() {
        // If the user was trying to add a new item before reauthorization, resume
        if (this.lastCommand == 'add-new-' + this.noun) {
            this.showAddNew();
            this.$emit('resumed');
        }
    },
    
    watch: {
        // Ensure that inputs are cleared when switching between groups and categories
        noun: function() {
            this.fetchExisting();
            this.newCategoryName = '';
            this.newCategoryEmail = '';
            this.newSortIndex = '';
        }
    },
    
    methods: {
        
        fetchExisting: function() {
            // Get active and inactive categories
            this.axios.get(this.pluralNoun + '?limit=1000')
                .then((response) => {
                    response.data.forEach(category => {
                        this.existingCategories.push(category.name);
                    });
                
                    this.axios.get(this.pluralNoun + '?active=0&limit=1000')
                        .then((response) => {
                            response.data.forEach(category => {
                                this.existingCategories.push(category.name);
                            });
                        })
                        .catch(() => {
                            this.showCategoryError();
                        });
                
                })
                .catch(() => {
                    this.showCategoryError();
                });
        },
        
        showCategoryError() {
            this.$bvModal.msgBoxOk(`An error occurred while connecting to the server.
                                    Please check your network connection, refresh
                                    the page, and try again.`,
            {
                title: 'Network Error',
                size: 'sm',
                buttonSize: 'sm',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: false,
                okVariant: 'warning'
            });
        },
        
        showAddNew() {
            const currentTime = new Date();
            if ( (this.expire.getTime() - currentTime.getTime()) <= 0 ) {
                this.$emit('checkAuth', {
                    lastCommand: 'add-new-' + this.noun,
                    lastInput: ''
                });
            }
            
            else {
                this.$bvModal.show('add-new');
            }
        },
        
        resetAddNew() {
            // Clear error
            this.error = '';
        },
        
        handleAddNewSaveButton(bvModalEvt) {

            // Override default close behavior
            bvModalEvt.preventDefault();
            
            // Submit the new item
            this.handleAddNewSubmit();
            
        },
        
        handleAddNewCancelButton() {
                    
            // Clear error
            this.error = '';
            
        },
        
        handleAddNewSubmit() {
            console.log(this.existingItems)
            
            // Ensure item number has been entered
            if (this.newCategoryName == '') {
                this.error = 'The ' + this.noun + ' name is required!'
                return;
            }
            
            // Ensure category name is correct format
            const regex = RegExp('^[a-zA-Z0-9 ]*$');
            if (!regex.test(this.newCategoryName)) {
                this.error = 'Name may contain only letters and whitespace!'
                return;
            }
            
            // Ensure category name is unique
            if (this.existingCategories.includes(this.newCategoryName)) {
                this.error = 'The ' + this.noun + ' name must be unique!';
                return;
            }
            
            // If sort index entered, ensure it's a number between 1 and 1000
            if (this.includeOrder && this.newSortIndex != "") {
                var n = Number(this.newSortIndex);
                if (n < 1 || n > 1000) {
                    this.error = 'Item # must be an integer between 1 and 1000!';
                    return;
                }
            }
            
            // If email is entered, ensure it's valid
            if (this.includeEmail && this.newCategoryEmail != "" && !this.$regexEmail.test(this.newCategoryEmail)) {
                this.error = 'Invalid email!';
                return;
            }                
           
            // Submit new item to API
            this.axios.post(this.uri, {
                name: this.newCategoryName,
                email: this.newCategoryEmail,
                order: this.newSortIndex,
            })
            .then((response) => {
                console.log(response);
                
                // Add new group/category to list of existing
                this.existingCategories.push(this.newCategoryName);
                
                // Clear inputs
                this.newCategoryName = '';
                this.newCategoryEmail = '';
                this.newSortIndex = '';
                
                // Close the modal
                this.$bvModal.hide('add-new');
                
                // Send a success notification to the parent
                this.$emit('added', 1);
            })
            .catch((error) => {
                if (error.response) {
                    this.$bvModal.hide('add-new'); 
                    // Display confirmation
                    this.$bvModal.msgBoxOk(error.response.data.reason_phrase,
                        {
                            title: 'Error!',
                            size: 'md',
                            buttonSize: 'sm',
                            okVariant: 'warning'
                        }
                    ).then( () => {
                        this.$bvModal.show('add-new');
                    });
                }
                else {
                    this.$bvModal.msgBoxOk('New category could not be saved. Please try again.',
                        {
                            title: 'Something went wrong...',
                            size: 'md',
                            buttonSize: 'sm',
                            okVariant: 'warning'
                        }
                    ).then( () => {
                        this.$bvModal.show('add-new');
                    });
                }
            });
        }  
    }  
}
</script>

<style>

</style>
