<!-- ChangeStatus.vue - Component for displaying item status change UI -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    <b-modal
        v-bind:id="id"
        title="Change Item Status"
        v-on:ok="handleSaveButton"
        v-on:close="handleCancelButton"
        v-on:cancel="handleCancelButton"
        v-on:show="reset"
        v-bind:ok-disabled="disabled"
        v-bind:cancel-disabled="disabled"
        ok-title="Save"
        size="xl"
    >
        <b-container fluid>
            
            <table class="table table-hover table-sm table-striped">
                <thead class="thead-light">
                <tr>
                    <th>#</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th>User</th>
                    <th>Reason/Note</th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in itemsToChange" v-bind:key="item.id">
                        <td>{{ item.id }}</td>
                        <td>{{ item.description }}</td>
                        <td>
                            <b-form-select
                                v-bind:id="'status-' + item.id" size="sm"
                                v-bind:options="statusOptions"
                                v-model="item.type"
                            ></b-form-select>
                        </td>
                        <td>
                            <b-form-select
                                v-bind:id="'user-' + item.id" size="sm"
                                v-model="item.user"
                                v-bind:options="userList"
                            ></b-form-select>
                        </td>
                        <td>
                            <b-form-input
                                 v-bind:id="'note' + item.id" size="sm"
                                 v-model="item.note" maxlength=20>
                            ></b-form-input>
                             <span v-bind:class="(itemErrors[index] == 'Success!') ? 'text-success' : 'text-danger'">{{ itemErrors[index] }}</span>
                        </td> 
                    </tr>
                </tbody>
            </table>
        </b-container>
    </b-modal>
</template>

<script>

import updateItems from '../mixins/update-items'
import netfail from '../mixins/netfail'

export default {
    data(){
        return {
            userList: [ { value: 1, text: 'Administrator'} ],
            statusOptions: [
              { value: 'in', text: 'in' },
              { value: 'out', text: 'out' },
              { value: 'missing', text: 'missing' },
              { value: 'out for repair', text: 'out for repair' },
              { value: 'assigned', text: 'assigned' },
              { value: 'reserved', text: 'reserved' }
            ],
            uriPrefix: 'assets',
            uriSuffix: '/updates',
            id: 'change-status',
            updateMethod: 'post'
        }
    },
    
    mixins: [updateItems, netfail],
    
    mounted: function() {
        // Get user list
        this.axios.get('users?sort=last&limit=0')
            .then((response) => {
                // Add users to a temporary list
                let tmpUserList = [];
                response.data.forEach(user => {
                    tmpUserList.push({
                        id: user.id,
                        firstName: user.firstName,
                        lastName: user.lastName
                    });
                });
                // Sort temporary user list by last name
                tmpUserList.sort((a, b) => {
                    let compare = a.lastName.localeCompare(b.lastName);
                    if (compare == 0) {
                        return a.firstName.localeCompare(b.firstName);
                    }
                    else {
                        return compare;
                    }
                });
                // Add sorted names to drop-down selection list
                tmpUserList.forEach(user => {
                    this.userList.push({
                        value: user.id,
                        text: user.firstName + ' ' + user.lastName 
                    });
                })    
            })
            .catch(() => {
                console.log('Network Failure');
                if (!this.networkFalure) this.networkFailure = true;
            })
        ;
    },
    
    watch: {
        selectedItems: function() {
            this.itemsToChange = [];
            this.selectedItems.forEach(item => {
                this.itemsToChange.push({            
                    id: item.id,
                    description: item.description,
                    type: item.status.lastTransaction.type == 'overdue' ? 'out' : item.status.lastTransaction.type,
                    user: item.status.lastTransaction.user.id,
                    note: item.status.lastTransaction.note,
                    error: ''
                });
            });
        }
    },
    
    methods: {
        
        validateInput(item, index) {
            // Ensure note is correct format
            const regex = RegExp('^[a-zA-Z0-9 ]*$');
            if (regex.test(item.note)) {
                return true;
            }
            else {
                this.itemErrors[index] = 'Only letters, numbers, and whitespace!';
                this.errorCount++;
                this.$forceUpdate();
                return false;
            }    
        },
        
        // Given an item, build an object to send to the API as a JSON request
        buildUpdateObject(item) {
            return {
                type: item.type,
                user: item.user,
                note: item.note
            };
        }
    }
}
</script>

<style>

</style>
