/**
 * netfail.js - Functionality for displaying alerts when API data cannot be retrieved
 *
 * @see       https://github.com/teachernerd/inventory/ The Inventory Box GitHub project
 *
 * @author    Daniel Reinish (teachernerd) <dan@reinish.net>
 * @copyright 2016 - 2022 Daniel Reinish
 * @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
 * @note      This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 */
 
/**
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
 */

export default {
    data() {
        return {
            networkFailure: false
        }
    },
    
    watch: {
        networkFailure: function() {
            if (this.networkFailure) {
                this.$bvModal.hide('add-new');
                this.$bvModal.hide('netfail');
                this.$bvModal.msgBoxOk(`An error occurred while connecting to the server.
                                        Please check your network connection, refresh
                                        the page, and try again.`,
                {
                    id: 'netfail',
                    title: 'Network Error',
                    size: 'sm',
                    buttonSize: 'sm',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: false,
                    okVariant: 'warning'
                });
            }
        }
    },
};