<!-- AboutBox.vue - Dialog box component for displaying credits -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    <b-modal
        v-bind:id="id"
        title="About" 
        size="lg"
        v-bind:ok-only="true"
        ok-variant="info"
    >
        <h3>Inventory Box</h3>
        <p>{{ description }}</p>
        <p><strong>Version {{ version }}</strong></p>
        <p>Copyright &copy; {{ copyright }} <a v-bind:href="'mailto:' + authorContact"> {{ author }}</a></p>
        <p>
            This program is free software: you can redistribute it and/or modify
            it under the terms of the <a href="https://www.gnu.org/licenses/gpl-3.0.en.html">
            GNU General Public License</a> as published by
            the Free Software Foundation, either version 3 of the License, or
            (at your option) any later version.
        </p>
        <p>
            This program is distributed in the hope that it will be useful,
            but WITHOUT ANY WARRANTY; without even the implied warranty of
            MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
            GNU General Public License for more details.
        </p>
        <p>
            You should have received a copy of the GNU General Public License
            along with this program.  If not, see
            <a href="https://www.gnu.org/licenses/gpl-3.0.en.html">
            https://www.gnu.org/licenses/gpl-3.0.en.html</a>.
        </p>
        <p>
            Source code is available via the <a href="https://github.com/teachernerd/inventory">
            teachernerd/inventory</a> project on GitHub.
        <hr>
        <p>
            Icon made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
        </p>
        <hr>
        <p>
            This program includes code adapted from the <a href="https://github.com/PHPMailer/PHPMailer/">
            PHPMailer</a> project and licensed under terms of the <a href="https://www.gnu.org/licenses/gpl-3.0.en.html">
            GNU General Public License</a>, version 3.
        </p>
        <ul>
            <li>Copyright &copy; 2012 - 2020 <a href="mailto:phpmailer@synchromedia.co.uk">Marcus Bointon</a> (Synchro/coolbru)</li>
            <li>Copyright &copy; 2010 - 2012 <a href="mailto:jimjag@gmail.com">Jim Jagielski</a> (jimjag)</li>
            <li>Copyright &copy; copyright 2004 - 2009 <a href="mailto:codeworxtech@users.sourceforge.net">Andy Prevost</a> (codeworxtech)</li>
            <li>Brent R. Matzelle (original founder)</li>
        </ul>
    </b-modal>
</template>

<script>

export default {

    data() {
        return {
            
        }
    },
    
    props: {
        id: String,
        copyright: String,
        version: String,
        author: String,
        authorContact: String,
        description: String
    }
}

</script>