/**
 * record.js - Shared code used by components that display individual records in a table
 *
 * @see       https://github.com/teachernerd/inventory/ The Inventory Box GitHub project
 *
 * @author    Daniel Reinish (teachernerd) <dan@reinish.net>
 * @copyright 2016 - 2022 Daniel Reinish
 * @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
 * @note      This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 */
 
/**
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
 */
 
 
export default {
    
    data(){
        return {
            isChecked: false
        }
    },
    
    model: {
        prop: 'checked',
        event: 'change'
    },
    
    props: {
        showMark: Boolean,
        checked: Boolean,
    },
    
    computed: {
        /*
         overdue: function() {
            var day = 1000*60*60*24;
            
            // Get timestamp from checkout date
            var checkoutTime = this.checkoutDate.getTime(); 
                   
            // Calculate overdue date
            var overdueTime = checkoutTime + 2 * 24 * 3600 * 1000;
            var overdueDate = new Date(overdueTime);
        
            // Add grace period for weekends
            if (overdueDate.getDay() == 0) { // Sunday
                overdueDate = new Date (overdueTime + 1 * 24 * 3600 * 1000);
            }
            else if (overdueDate.getDay() == 6) { // Saturday
                overdueDate = new Date (overdueTime + 2 * 24 * 3600 * 1000);
            }
        
            // Calculate difference (in days) between current date and overdue date
            var currentDate = new Date();
            var diff = (currentDate - overdueDate) / day;
            return (diff > 0);
        },
        */
        
        rowClass: function() {
            if (this.status == 'missing') {
                return 'table-warning missing';
            }
            else if (this.status == 'overdue') {
                return 'table-danger out';
            }
            else if (this.status == 'out' || this.status == 'assigned' || this.status == 'partial') {
                return 'out';
            }
            else if (this.status == 'completed') {
                return '';
            }
            else {
                return '';
            }
        },
        
    },
    
    watch: {
        checked: function() {
            this.isChecked = this.checked;
        }
    },

    filters: {
        prettyDate: function(d) {
            if (d == '-') {
                return '-';
            }
            const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(d);
            const date = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(d);
            return month + " " + date + ", " + year;
        },
    },
};