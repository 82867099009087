/**
 * main.js - Entry point for front-end client app
 *
 * @see       https://github.com/teachernerd/inventory/ The Inventory Box GitHub project
 *
 * @author    Daniel Reinish (teachernerd) <dan@reinish.net>
 * @copyright 2016 - 2022 Daniel Reinish
 * @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
 * @note      This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 */
 
/**
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
 */

// Import external modules
import Vue from 'vue'
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta'
import VueAxios from 'vue-axios';
import axios from 'axios';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// Import components
import App from './App.vue';
import Inventory from './components/InventoryPage.vue';
import Users from './components/UsersPage.vue';
import Checkouts from './components/CheckoutsPage.vue';
import Transactions from './components/TransactionsPage.vue';
import Categories from './components/CategoriesPage.vue';

// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Attach various stuff
Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.use(VueAxios, axios);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Turn off dev mode warning
Vue.config.productionTip = false;

// Determine client path
Vue.prototype.$clientPath = window.location.pathname.replace(process.env.VUE_APP_CLIENT_PATH + '/','').split('/')[0];

// Axios configuration
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_BASE + '/' + 
    Vue.prototype.$clientPath + '/api/';

// Global for email regex
// Email regex (borrowed from Vue.js cookbook)
// https://vuejs.org/v2/cookbook/form-validation.html
Vue.prototype.$regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// Global capitalization filter (Credit: Vue docs - https://vuejs.org/v2/guide/filters.html)
Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})


// Set page title in browser
document.title = 'The Inventory Box';

const routes = [
  {
    name: 'Inventory',
    path: '/:account/inventory',
    component: Inventory,
    props: {
        restore: false
    }
  },
  {
    name: 'Deleted Items',
    path: '/:account/restore_items',
    component: Inventory,
    props: {
        restore: true
    }
  },
  {
    name: 'Users',
    path: '/:account/users',
    component: Users,
    props: {
        restore: false
    }
  },
  {
    name: 'Deactivated Users',
    path: '/:account/restore_users',
    component: Users,
    props: {
        restore: true
    }
  },
  {
    name: 'Checkouts',
    path: '/:account/checkouts',
    component: Checkouts
  },
  {
    name: 'Transactions',
    path: '/:account/transactions',
    component: Transactions
  },
  {
    name: 'Categories',
    path: '/:account/categories',
    component: Categories,
    props: {
        uri: 'categories',
        noun: 'category',
        pluralNoun: 'categories',
        includeOrder: true,
        includeEmail: false,
        restore: false }
  },
  {
    name: 'Deleted Categories',
    path: '/:account/restore_categories',
    component: Categories,
    props: {
        uri: 'categories',
        noun: 'category',
        pluralNoun: 'categories',
        includeOrder: true,
        includeEmail: false,
        restore: true }
  },
  {
    name: 'Groups',
    path: '/:account/groups',
    component: Categories,
    props: {
        uri: 'groups',
        noun: 'group',
        pluralNoun: 'groups',
        includeOrder: false,
        includeEmail: true,
        restore: false }
  },
  {
    name: 'Deleted Groups',
    path: '/:account/restore_groups',
    component: Categories,
    props: {
        uri: 'groups',
        noun: 'group',
        pluralNoun: 'groups',
        includeOrder: false,
        includeEmail: true,
        restore: true }
  }
];

const router = new VueRouter({ mode: 'history', routes: routes, base: process.env.VUE_APP_CLIENT_PATH + '/' });

new Vue({
  render: h => h(App),
  router
}).$mount('#app')


