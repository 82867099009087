<!-- EditUsers.vue - Component for displaying user editing interface -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
  
    <b-modal
        v-bind:id="id"
        v-bind:title="title"
        v-on:ok="handleSaveButtonIntermediate"
        v-on:close="handleCancelButton"
        v-on:cancel="handleCancelButton"
        v-on:show="resetIntermediate"
        v-bind:ok-disabled="disabled"
        v-bind:cancel-disabled="disabled"
        ok-title="Save"
        size="xl"
    >
        <b-container fluid>
        
            <table class="table table-hover table-sm table-striped">
                <thead class="thead-light">
                <tr>
                    <th><span v-if="!progressive">#</span></th>
                    <th>Last Name</th>
                    <th>First Name</th>
                    <th>Email</th>
                    <th>Group</th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in itemsToChange" v-bind:key="index">
                        <td>
                            <span v-if="!progressive">{{ item.id }}</span>
                            <span v-else>
                                <button
                                    class="btn btn-danger badge remove-row"
                                    v-bind:style="{visibility: (index == 0) ? 'hidden' : 'visible'}"
                                    v-on:click="remove(index)"
                                >
                                    &ndash;
                                </button>
                            </span>
                        </td>
                        <td style="width:30%">
                            <b-form-input
                                v-bind:id="'lastname-' + item.id" size="sm"
                                v-model="item.lastName" maxlength=20
                                v-on:keyup="expand(index)"
                            >
                            </b-form-input>
                            <span v-bind:class="(itemErrors[index] == 'Success!') ? 'text-success' : 'text-danger'">{{ itemErrors[index] }}</span>
                        </td>
                        <td>
                            <b-form-input
                                v-bind:id="'firstname-' + item.id" size="sm"
                                v-model="item.firstName" maxlength=20
                                v-on:keyup="expand(index)"
                            >
                            </b-form-input> 
                        </td>
                        <td>
                            <b-form-input
                                v-bind:id="'email-' + item.id" size="sm"
                                v-model="item.email" maxlength=50
                            >
                            </b-form-input> 
                        </td>
                        <td>
                            <b-form-select
                                v-bind:id="'group-' + item.id" size="sm"
                                v-model="item.group"
                                v-bind:options="groupOptions"
                            >
                            </b-form-select>
                        </td>                        
                    </tr>
                </tbody>
            </table>
        </b-container>
    </b-modal>

</template>

<script>

import updateItems from '../mixins/update-items'
import netfail from '../mixins/netfail'

export default {
    data(){
        return {
            groupOptions: [{value:0, text:''}],
            uriPrefix: 'users',
            uriSuffix: '',
            updateMethod: 'put'
        }
    },
    
    props: {
        id: String,
        title: String,
        progressive: Boolean
    },
    
    mixins: [updateItems, netfail],
    
    created: function() {
        // Get category list
        this.axios.get('groups')
            .then((response) => {
                response.data.forEach(group => {
                    this.groupOptions.push({
                        value: group.id,
                        text: group.name 
                    });
                })
            })
            .catch(() => {
                console.log('Network Failure');
                if (!this.networkFalure) this.networkFailure = true;
            });
        
        // Tweaks for "progressive" editing form (e.g. 'add new' rather than edit)
        if (this.progressive) {

            // Change update method from put to post
            this.updateMethod = 'post';
        }
    },
    
    watch: {
        selectedItems: function() {
            if (!this.progressive) {
                this.itemsToChange = [];
                this.selectedItems.forEach(user => {
                    this.itemsToChange.push({            
                        id: user.id,
                        lastName: user.lastName,
                        firstName: user.firstName,
                        email: (user.email == null) ? '' : user.email,
                        group: user.group.id,
                        error: ''
                    });
                });
            }
        }
    },
    
    methods: {
    
        // Ensures that there is always a blank row at the start when adding new users.
        // Also ensures that we start with a fresh slate every time the form is shown
        resetIntermediate() {
            if (this.progressive) {
                this.itemsToChange = [];
                
                // Add a blank row to start with
                this.itemsToChange.push({
                    id: '',
                    lastName: '',
                    firstName: '',
                    email: '',
                    group: 0,
                    error: ''
                });
            }
            
            // Continue with standard reset routine
            this.reset();
        
        },
        
        
        handleSaveButtonIntermediate(bvModalEvt) {
            // Remove last item from list if it's blank
            const lastItem = this.itemsToChange[this.itemsToChange.length - 1];
            if ( lastItem.id == '' && lastItem.lastName == '' && lastItem.firstName == '' && lastItem.email == '' && lastItem.group == 0) {
                this.itemsToChange.splice(this.itemsToChange.length - 1, 1);
            }
            
            // Fail gracefully if this is actually an "Add Multiple Users" window
            // and no new users have been provided
            console.log(this.itemsToChange.length)
            if (!this.itemsToChange.length) {
                this.resetIntermediate();
            }
            
            // Continue with the save button routine
            this.handleSaveButton(bvModalEvt);
        
        },
    
         validateInput(item, index) {
                      
            // Ensure names have been entered
            if (item.lastName == '' || item.firstName == '') {
                this.itemErrors[index] = 'First and last name are required!'
                this.errorCount++;
                this.$forceUpdate();
                return false;
            }
            
            // Name regex
            const regex = RegExp('^[a-zA-Z -]*$');
                       
            // Ensure note is correct format
            if (!regex.test(item.lastName) || !regex.test(item.firstName)) {
                this.itemErrors[index] = 'Names may contain only letters, spaces, and hyphens!';
                this.errorCount++;
                this.$forceUpdate();
                return false;
            }
            
            // Validate email
            else if (item.email != '' && item.email != null && !this.$regexEmail.test(item.email)) {
                console.log(item.email);
                this.itemErrors[index] = 'Invalid email!';
                this.errorCount++;
                this.$forceUpdate();
                return false;
            }
            
            // Group must not be blank. (We only have a blank spot on the drop-down
            // to help deal with the edge case where the current group has been removed)
            else if (item.group == 0) {
                this.itemErrors[index] = 'Group is required!';
                this.errorCount++;
                this.$forceUpdate();
                return false;
            }
            
            else {
                return true;
            }
        },
        
        // Given an item, build an object to send to the API as a JSON request
        buildUpdateObject(user) {
            return {
                lastName: user.lastName,
                firstName: user.firstName,
                email: user.email,
                group: user.group,
            };
        },
        
        expand() {
            if (this.progressive) {
            
                if (this.itemsToChange[this.itemsToChange.length - 1].lastName != '' || this.itemsToChange[this.itemsToChange.length - 1].firstName != '') {
                    this.itemsToChange.push({
                        id: '',
                        lastName: '',
                        firstName: '',
                        email: '',
                        group: 0,
                        error: ''
                    });
                }
            }
        },
        
        remove(i) {
            this.itemsToChange.splice(i, 1);
        }
    }
}
</script>

<style>
    button.remove-row {
        margin-left: 4px;
        margin-right: -3px;
        padding-right: 6px;
        padding-left: 6px;
    }
</style>
