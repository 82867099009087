<!-- CategoryRecord.vue - Component for displaying single row of Categories list -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    <tr v-bind:class="[rowClass]">
        <td
            v-if="showMark"
        >
            <!--
            <input
                v-if="showMark"
                type="checkbox"
                name="selections[]"
                v-bind:id="'selection-' + category.id"
                v-bind:value="category.id"
                v-bind:checked="checked"
                v-on:change="$emit('change', $event.target.checked)"
            > -->
            <b-form-checkbox
                v-if="showMark"
                name="selections[]"
                v-model="isChecked"
                v-bind:id="'selection-' + category.id"
                v-bind:checked="checked"
                v-on:change="$emit('change', isChecked)"
            >
            </b-form-checkbox>
        </td>
        <td >
            {{ category.name }}
        </td>
        <td v-if="includeOrder">
            {{ category.order }}
        </td>
        <td v-if="includeEmail && showMark">
            {{ category.email }}
        </td>
    </tr>
</template>

<script>

import record from '../mixins/record'

export default {

    props: {
        category: Object,
        includeOrder: Boolean,
        includeEmail: Boolean
    },
    
    mixins: [record]     
    
}
</script>

<style>


</style>
