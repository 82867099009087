<!-- FilterBadges.vue - Filter badge component for checkouts and transactions pages -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    <div class="d-inline-block">
        <router-link
            v-if="specificItem > 0 && filteredItem != 'Item...'"
            class="badge badge-pill badge-info align-top ml-2"
            v-bind:to="{ query: { page: 1, show: $route.query['show'], ...specificQueryVars, item:0}}"
        >
            <span class="closer-placeholder">&minus;</span><span class="closer">&times;</span><span class="ml-2">#{{ filteredItem }}</span>
        </router-link>
        <router-link
            v-if="specificUser > 0 && filteredUser != 'User...'"
            class="badge badge-pill badge-info align-top ml-2"
            v-bind:to="{ query: { page: 1, show: $route.query['show'], ...specificQueryVars, user:0}}"
        >
            <span class="closer-placeholder">&minus;</span><span class="closer">&times;</span><span class="ml-2">{{ filteredUser }}</span>
        </router-link>
        <router-link
            v-if="specificGroup > 0 && filteredGroup != 'Group...'"
            class="badge badge-pill badge-info align-top ml-2"
            v-bind:to="{ query: { page: 1, show: $route.query['show'], ...specificQueryVars, group:0}}"
        >
            <span class="closer-placeholder">&minus;</span><span class="closer">&times;</span><span class="ml-2">{{ filteredGroup }}</span>
        </router-link>
        <router-link
            v-if="specificNote != null"
            class="badge badge-pill badge-info align-top ml-2"
            v-bind:to="{ query: { page: 1, show: $route.query['show'], ...specificQueryVars, search:''}}"
        >
            <span class="closer-placeholder">&minus;</span><span class="closer">&times;</span><span class="ml-2">{{ filteredNote}}</span>
        </router-link>
        {{ requestedFilterName }}
    </div>
</template>

<script>

export default {
    data(){
        return {
            lastFilteredUser: '',
            lastFilteredItem: '',
            lastFilteredGroup: '', 
        }
    },
    
    props: {
        specificItem: Number,
        specificUser: Number,
        specificGroup: Number,
        specificNote: String,
        specificQueryVars: Object,
        items: Array,
        requestedFilterName: String
    
    },
    
    mounted: function() {
        // If a filter was requested prior to page load, set the label name
        // (This is a workaround for empty filtered lists)
        if (this.requestedFilterName != '') {
            if (this.specificUser > 0) {
                this.lastFilteredUser = this.requestedFilterName;
                this.$emit('resetRequestedFilterName');
            }
            if (this.specificItem > 0) {
                this.lastFilteredItem = this.requestedFilterName;
                this.$emit('resetRequestedFilterName');
            }
            if (this.specificGroup > 0) {
                this.lastFilteredGroup = this.requestedFilterName;
                this.$emit('resetRequestedFilterName');
            }
        }
    },
    
    watch: {
        filteredUser: function() {
            if (this.specificUser > 0) {
                this.lastFilteredUser = this.filteredUser
            }
        },
        
        specificUser: function() {
            if (this.specificUser == 0) {
                this.lastFilteredUser = '';
            }
        },
        
        filteredItem: function() {
            if (this.specificItem > 0) {
                this.lastFilteredItem = this.filteredItem
            }
        },
        
        specificItem: function() {
            if (this.specificItem == 0) {
                this.lastFilteredItem = '';
            }
        },
        
        filteredGroup: function() {
            if (this.specificGroup > 0) {
                this.lastFilteredGroup = this.filteredGroup
            }
        },
        
        specificGroup: function() {
            if (this.specificGroup == 0) {
                this.lastFilteredGroup = '';
            }
        },
    },
    
    computed: {
        itemCount: function() {
            return this.items.length;
        },
        
        filteredItem: function() {
            if (this.items.length > 0) {
                return this.items[0].asset.id + ': ' + this.items[0].asset.description;
            }
            else if (this.lastFilteredItem != '') {
                return this.lastFilteredItem;
            }
            else {
                return 'Item...';
            }
        },
        
        
        filteredUser: function() {
            if (this.items.length > 0) {
                return this.items[0].user.firstName + ' ' + this.items[0].user.lastName;
            }
            else if (this.lastFilteredUser != '') {
                return this.lastFilteredUser;
            }
            else {
                return 'User...';
            }
        },
        
        
        filteredGroup: function() {
            if (this.items.length > 0) {
                return this.items[0].user.group.name;
            }
            else if (this.lastFilteredGroup != '') {
                return this.lastFilteredGroup;
            }
            else {
                return 'Group...';
            }
        },
        
        filteredNote: function() {
            return '"' + this.specificNote + '"';
        }
    
    }
    
    
}
</script>

<style>

</style>
