<!-- EditCategories.vue - Component for displaying category editing interface -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
  
    <b-modal
        v-bind:id="id"
        v-bind:title="'Edit ' + title"
        v-on:ok="handleSaveButtonIntermediate"
        v-on:close="handleCancelButton"
        v-on:cancel="handleCancelButton"
        v-on:show="showDialog"
        v-bind:ok-disabled="disabled"
        v-bind:cancel-disabled="disabled"
        ok-title="Save"
        size="md"
    >
        <b-container fluid>
        
            <table class="table table-hover table-sm table-striped">
                <thead class="thead-light">
                <tr>
                    <th>Name</th>
                    <th v-if="includeOrder">Sort</th>
                    <th v-if="includeEmail">Email</th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="(category, index) in itemsToChange" v-bind:key="index">
                        <td>
                            <b-form-input
                                v-bind:id="'name-' + category.id" size="sm"
                                v-model="category.name" maxlength=20
                            >
                            </b-form-input>
                            <span v-bind:class="(itemErrors[index] == 'Success!') ? 'text-success' : 'text-danger'">{{ itemErrors[index] }}</span>
                        </td>
                        <td v-if="includeOrder" style="width:30%">
                            <b-form-input
                                v-bind:id="'order-' + category.id" size="sm"
                                v-model="category.order" maxlength=4
                            >
                            </b-form-input> 
                        </td>     
                        <td v-if="includeEmail" style="width:60%">
                            <b-form-input
                                v-bind:id="'email-' + category.id" size="sm"
                                v-model="category.email" maxlength=255
                            >
                            </b-form-input> 
                        </td>                 
                    </tr>
                </tbody>
            </table>
        </b-container>
    </b-modal>

</template>

<script>

import updateItems from '../mixins/update-items'

export default {
    data(){
        return {
            uriPrefix: this.uri,
            uriSuffix: '',
            updateMethod: 'put',
            existingCategories: [],
            originalNames: []
        }
    },
    
    props: {
        id: String,
        title: String,
        noun: String,
        progressive: Boolean,
        includeOrder: Boolean,
        includeEmail: Boolean,
        uri: String,
        activeCategories: Array
    },
    
    mixins: [updateItems],
        
    watch: {
        selectedItems: function() {
            if (!this.progressive) {
                this.itemsToChange = [];
                this.selectedItems.forEach(category => {
                    this.itemsToChange.push({            
                        id: category.id,
                        name: category.name,
                        order: category.order,
                        email: category.email,
                        error: ''
                    });
                });
            }
        }
    },
    
    methods: {
    
        showDialog() {
            // Reset dialog state
            this.reset();
            
            // Reset existing category list
            this.existingCategories = [];
            
            // Save the original item names so that we can compare the current name
            // when submitting. (This is necessary for the uniqueness checker.)
            this.$nextTick(() => {
                this.selectedItems.forEach((category, index) => {
                    this.originalNames[index] = category.name;
                });
            });
            
            // Add active categories (passed from main page) to the existing category list
            this.activeCategories.forEach(category => {
                this.existingCategories.push(category.name);
            });
            
            // Get list of inactive categories from API and add to the existing category list
            this.axios.get(this.uri + '?active=0&limit=1000')
                .then((response) => {
                    response.data.forEach(category => {
                        this.existingCategories.push(category.name);
                    });
                })
                .catch(() => {
                    this.showCategoryError();
                });
        },
        
        showCategoryError() {
            this.$bvModal.msgBoxOk(`An error occurred while connecting to the server.
                                    Please check your network connection, refresh
                                    the page, and try again.`,
            {
                title: 'Network Error',
                size: 'sm',
                buttonSize: 'sm',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: false,
                okVariant: 'warning'
            });
        },
        
        handleSaveButtonIntermediate(bvModalEvt) {
            
            // Continue with the save button routine
            this.handleSaveButton(bvModalEvt);
        
        },
    
         validateInput(item, index) {  
            // Ensure names have been entered
            if (item.name == '') {
                this.itemErrors[index] = 'The ' + this.noun + ' name is required!'
                this.errorCount++;
                this.$forceUpdate();
                return false;
            }
            
            // Name regex
            const regex = RegExp('^[a-zA-Z0-9 ]*$');
            
            // Ensure name is correct format
            if (!regex.test(item.name)) {
                this.itemErrors[index] = 'Name may contain only letters and whitespace!';
                this.errorCount++;
                this.$forceUpdate();
                return false;
            }
            
            // Ensure category name is either unchanged or isn't the same as another one
            if (item.name != this.originalNames[index] && this.existingCategories.includes(item.name)) {
                this.itemErrors[index] = 'The ' + this.noun + ' name must be unique!';
                this.errorCount++;
                this.$forceUpdate();
                return;
            }
                        
            // If sort index entered, ensure it's a number between 1 and 1000
            if (item.order != "") {
                var n = Number(item.order);
                if (n < 1 || n > 1000) {
                    this.itemErrors[index] = 'Item # must an integer between 1 and 1000!'
					this.errorCount++;
					this.$forceUpdate();
					return false;
                }
            }
            
            // If email is entered, validate it
            if (item.email != '' && item.email != null && !this.$regexEmail.test(item.email)) {
                this.itemErrors[index] = 'Invalid email!';
                this.errorCount++;
                this.$forceUpdate();
                return false;
            } 
            
            return true;
        },
        
        // Given an item, build an object to send to the API as a JSON request
        buildUpdateObject(category) {
            return {
                name: category.name,
                order: category.order,
                email: category.email
            };
        },
    }
}
</script>


