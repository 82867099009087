<!-- Checkouts.vue - Component for displaying Checkouts page -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    <div id="checkounts-content">
        <page-structure
            v-bind="basePageProps"
            v-bind:noun="assignOrCheck"
            v-bind:pluralNoun="assignOrCheck + 's'"
            v-bind:restore="restore"
            v-bind:showDetails="true"
            v-bind:showUpdateMessage.sync="showUpdateMessage"
            v-bind:updateCount="updateCount"
            v-bind:action="null"
            v-bind:sortedBy="null"
            v-bind:groupedBy="null"
            v-bind:groupedItems="{}"
            v-bind:columns="columns"
            v-bind:groupedColumns="columns"
            v-bind:additionalQueries = "{ show: this.$route.query['show'], ...specificQueryVars }"
            v-bind:showSelectAll = "false"
            v-bind:includeSelectColumn = "($parent.scope == 'admin' || $parent.scope == 'limited') && this.enableTracking"
            skeletonWidth="10%"
            v-on:updated-limit="$emit('updated-limit', arguments[0])"
            v-bind:limit="limit"
        >
            <template v-slot:after-title>
                <filter-badges
                    v-bind:specificItem='specificItem'
                    v-bind:specificUser='specificUser'
                    v-bind:specificGroup='specificGroup'
                    v-bind:specificNote='specificNote'
                    v-bind:specificQueryVars='specificQueryVars'
                    v-bind:items='items'
                    v-bind:requestedFilterName='requestedFilterName'
                    v-on:resetRequestedFilterName="$emit('update:requestedFilterName', '')"
                ></filter-badges>
            </template>
            
            <template v-slot:sub-nav>
                <router-link
                    v-bind:to="{ query: { page: 1, show: 'checkouts', ...specificQueryVars }}"
                    v-bind:class="{ active:(typeof $route.query['show'] == 'undefined' || ($route.query['show'] != 'nonreturned' && $route.query['show'] != 'completed' && $route.query['show'] != 'incomplete' && $route.query['show'] != 'returned' )) }"
                >
                    All {{ assignOrCheck | capitalize }}s
                </router-link> | 
                <router-link
                    v-bind:to="{ query: { page: 1, show: 'nonreturned', ...specificQueryVars }}"
                    v-bind:class="{ active:typeof $route.query['show'] != 'undefined' && $route.query['show'] == 'nonreturned'}"
                >
                    Pending Return
                </router-link> {{ enableTracking ? '|' : '' }} 
                <router-link
                    v-if="enableTracking"
                    v-bind:to="{ query: { page: 1, show: 'incomplete', ...specificQueryVars }}"
                    v-bind:class="{ active:typeof $route.query['show'] != 'undefined' && $route.query['show'] == 'incomplete'}"
                >
                    Waiting for Review
                </router-link> {{ enableTracking ? '|' : '' }}  
                <router-link
                    v-if="enableTracking"
                    v-bind:to="{ query: { page: 1, show: 'completed', ...specificQueryVars }}"
                    v-bind:class="{ active:typeof $route.query['show'] != 'undefined' && $route.query['show'] == 'completed'}"
                >
                    Marked Complete
                </router-link> {{ enableTracking ? '' : '|' }}
                <router-link
                    v-if="!enableTracking"
                    v-bind:to="{ query: { page: 1, show: 'returned', ...specificQueryVars }}"
                    v-bind:class="{ active:typeof $route.query['show'] != 'undefined' && $route.query['show'] == 'returned'}"
                >
                    Returned
                </router-link> 
            </template>
            
            <template v-slot:extra-record-status>
                {{ (pending) ? '(' + pending + ' pending)' : ''}}
            </template>
            
            <template v-slot:additionalColumn1>
                <th>
                    <span class="mr-4">Reason/Note</span> <b-link href="#" id="searchLink" ref="searchLink"><b-icon icon="search"></b-icon></b-link>
                    <b-popover
                        target="searchLink"
                        triggers="click"
                        v-bind:show.sync="popoverShow"
                        placement="auto"
                        container="my-container"
                        ref="popover"
                        v-on:show="onShow"
                        v-on:shown="onShown"
                        v-on:hidden="onHidden"
                    >
                        <template v-slot:title>
                            <b-button id="search-close" v-on:click="onClose" class="close mb-2" aria-label="Close">
                                <span class="d-inline-block" aria-hidden="true">&times;</span>
                            </b-button>
                            Find Assignment
                        </template>
            
                        <div>
                            <b-form-group
                                label="Reason/Note"
                                label-for="search-term"
                                v-bind:state="searchTermState"
                                class="mb-2"
                                description="What are you looking for?"
                                invalid-feedback="Search term required"
                            >
                                <b-form-input
                                    ref="searchTerm"
                                    id="search-term"
                                    v-model="searchTerm"
                                    v-bind:state="searchTermState"
                                    size="sm"
                                    v-on:keyup.enter="onOk"
                                ></b-form-input>
                            </b-form-group>
                            <b-button v-on:click="onClose" size="sm" variant="outline-secondary" class="mr-2">Cancel</b-button>
                            <b-button v-on:click="onOk" size="sm" variant="outline-primary">Search</b-button>
                        </div>
                    </b-popover>
                </th>
            </template>
            
            <template v-slot:records>
                <checkout-record
                    v-for = "checkout in items"
                    v-model = "selected[checkout.id]"
                    v-bind:checkout = "checkout"
                    v-bind:inactiveItems = "inactiveItems"
                    v-bind:showMark = "($parent.scope == 'admin' || $parent.scope == 'limited') && enableTracking"
                    v-bind:key = "checkout.id"
                    v-bind:filterItem = "specificItem > 0"
                    v-bind:filterUser = "specificUser > 0"
                    v-bind:filterGroup = "specificGroup > 0"
                    v-bind:specificQueryVars = "specificQueryVars"
                ></checkout-record>
            </template>
            
            <template v-slot:bottom-left-admin-buttons>
                <button type="button" v-bind:disabled="loading" class="btn btn-sm btn-secondary" v-on:click="markComplete()">Mark Complete</button>
            </template>
            
        </page-structure>
    </div>
    
</template>

<script>

import CheckoutRecord from './CheckoutRecord'
import base from '../mixins/base'
import transaction from '../mixins/transaction'
import PageStructure from './PageStructure'

export default {
    
    data(){
        return{
            uri: 'checkouts',
            checkType: 'out',
            alertItem: 0,
            updateCount: 0,
            noun: 'assignment',
            pluralNoun: 'assignments',
            updateFail: false,
            popoverShow: false,
            searchTerm: '',
            searchTermReturn: '',
            searchTermState: null
        }
    },
    
    metaInfo: {
        title: 'Checkouts'
    },
    
    props: {
        expire: Date,
        lastCommand: String,
        lastInput: [String, Array, Object],
        enableTracking: Boolean,
        requestedFilterName: String
    },
    
    mixins: [base, transaction],
    
    components: {
       'checkout-record': CheckoutRecord,
       'page-structure': PageStructure
    },
    
    created: function() {
        // On creation of the checkout page, check to see whether the "showCheckAlert"
        // prop has been set to a non-empty object. If so, set the necessary
        // view properties to show an alert.
        if ( Object.prototype.hasOwnProperty.call(this.showCheckAlert, 'type') && this.showCheckAlert != {} ) {
            //this.addNewCheckout(this.showCheckAlert);
            this.checkType = this.showCheckAlert.type;
            this.alertItem = this.showCheckAlert.data.asset.id;
            //this.dismissCountDown = this.checkoutDismissSecs;
            
            // Once the alert has been shown, emit this event so that the prop is
            // set back to an empty object by the parent view.
            this.$emit('check', {});          
        }
    
    },
    
    mounted: function() {
        // If we are resuming from a reload, make it happen
        if (this.lastCommand == 'mark-complete') {
            console.log('detected mark-complete');
            this.selected = this.lastInput;
            this.markComplete();
            this.$emit('resumed');
        }
    },
    
    watch: {
    
        // This watcher is used to detect checkouts when the checkout page is already
        // open. It works similarly to the function above (in the "created" routine)
        // but instead of showing the alert directly, it calls helper methods that
        // also update the visible log.
        showCheckAlert: function() {
            if ( Object.prototype.hasOwnProperty.call(this.showCheckAlert, 'type') && this.showCheckAlert != {} ) {
                if (this.showCheckAlert.type == 'in') {
                    this.showCheckAlert.data.forEach((checkAlertItem) => {
                        this.recordCheckin(checkAlertItem);
                    });
                    
                }
                else {
                    this.showCheckAlert.data.forEach((checkAlertItem) => {
                        this.addNewCheckout(checkAlertItem);
                    });
                }
                
                // Once the alert has been shown, emit this event so that the prop is
                // set back to an empty object by the parent view.
                this.$emit('check', {});
            }
        },
        
    },
    
    computed: {
        assignOrCheck: function() {
            return this.enableTracking ? 'assignment' : 'checkout';
        },
        
        columns: function() {
            let columns = [];
            if (this.specificUser <= 0) columns.push({ sort: 'user', name: 'User', sortable:false });
            if (this.specificItem <= 0) columns.push({ sort: 'item', name: 'Item', sortable:false  });
            columns.push({ sort: 'status', name: 'Status', sortable:false  });
            columns.push({ sort: 'date', name: 'Date', sortable:false  });
            return columns;      
        }
        
    },
      
    methods: {
      
        markComplete() {
            
            this.updateFail = false;
            
            // If we're past the expiration time, stop and cause a reauthorization
            const currentTime = new Date();
            if ( (this.expire.getTime() - currentTime.getTime()) <= 0 ) {
                this.$emit('checkAuth', {
                    lastCommand: 'mark-complete',
                    lastInput: this.selected
                });       
            }
            
            // If we're not past expiration, then proceed
            else {
                this.updateCount = 0;
                let loopArray = this.selected.slice();
                loopArray.forEach((marked, asset) => {
                    if (marked) {
                        let uri = this.uri + "/" + asset;
                        this.axios
                            .patch(uri, {status:'completed'})
                            .then((response) => {
                                if (response.data.status == 'completed') {
                                    let updatedCheckout = this.items.find(ch => ch.id == asset);
                                    if (typeof updatedCheckout !== 'undefined') updatedCheckout.status = 'completed';
                                    this.selected = this.selected.splice(asset, 1);
                                    this.updateCount++;
                                    //this.dismissCountDown = this.checkoutDismissSecs;
                                    this.showUpdateMessage = true;
                                }
                            })
                            .catch(() => {
                                this.updateFail = true;
                                //this.dismissCountDown = this.checkoutDismissSecs;
                                this.showUpdateMessage = true;
                            });
                    }
                });
                this.selected = [];
            }
        },
        
        addNewCheckout(checkout) {
            if (++this.count > this.limit) {
                this.items.pop();
            }
            this.items.unshift(checkout);
            if (checkout.asset.id != -1) this.pending++;  
            this.checkType = 'out';
            this.alertItem = checkout.asset.id;
            //this.dismissCountDown = this.checkoutDismissSecs;
            
            // Correct page number if this is the first-ever checkout
            if (this.count == 1) {
                this.page = 1;
            }
            
        },
        
        recordCheckin(checkin) {
            let index = this.items.findIndex(item => {
                return item.asset.id == checkin.asset.id;
            });
            if (index >= 0) {
                this.items[index].status = 'returned';
            }
            this.$bvModal.hide('checkin-form');
            this.pending--;
            this.checkType = 'in';
            this.alertItem = checkin.asset.id;
            //this.dismissCountDown = this.checkinDismissSecs;
            
        },
        
        
        // The following adapted from Bootstrap Vue documentation
        // https://bootstrap-vue.org/docs/components/popover
        
        onClose() {
            this.popoverShow = false;
        },
        
        onOk() {
            /*
            if (!this.searchTerm) {
              this.searchTermState = false;
            }
            */
            //if (this.searchTerm) {
                this.onClose();
               
                // Don't do anything if the search term hasn't changed
                if (this.searchTerm != this.$route.query['search']) {
                    // Add search query to route
                    this.$router.push({
                            name: 'Checkouts',
                            query: {
                                show: this.showing,
                                ...this.specificQueryVars,
                                search: this.searchTerm
                            }
                    });
                
                    // Return our popover form results
                    this.searchTermReturn = this.searchTerm;
                }
           // }
            
        },
          
        onShow() {
            // This is called just before the popover is shown
            // Reset our popover form variables
            this.searchTerm = '';
            this.searchTermState = null;
            //this.searchTermReturn = '';
        },
        
        onShown() {
            // Called just after the popover has been shown
            // Transfer focus to the first input
            this.focusRef(this.$refs.searchTerm);
        },
        
        onHidden() {
            // Called just after the popover has finished hiding
            // Bring focus back to the button
            //this.focusRef(this.$refs.searchLink);
        },
        
        focusRef(ref) {
            // Some references may be a component, functional component, or plain element
            // This handles that check before focusing, assuming a `focus()` method exists
            // We do this in a double `$nextTick()` to ensure components have
            // updated & popover positioned first
            this.$nextTick(() => {
                this.$nextTick(() => {
                    (ref.$el || ref).focus()
                });
            });
        }
    }
}
</script>


<style>



a.badge {
    color:white;
}

a.badge .closer {
    display:none;
}

a.badge:hover .closer {
    display:inline;
}

a.badge .closer-placeholder {
    display:inline;
    
}

a.badge:hover .closer-placeholder {
    display:none;
}

#search-close {
    margin-top: -4px !important;
    margin-left: 10px;
}

</style>