<!-- TransactionRecord.vue - Component for displaying individual row of Transactions table -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    <tr v-bind:class="[rowClass]">
        <td>
            {{ checkout.id }}
        </td>
        <td
            v-bind:class="{special: (checkout.user.id == 1), inactive: (!checkout.user.active)}"
            v-if="!filterUser"
        >
            <span v-if="checkout.user.active && checkout.user.id != 1">
                <router-link
                    v-bind:to="{name: 'Transactions', query: { user: checkout.user.id, ...specificQueryVars }}"
                >
                    {{ checkout.user.firstName }} {{ checkout.user.lastName }}
                </router-link>
            </span>
            <span v-else>
                {{ checkout.user.firstName }} {{ checkout.user.lastName }}
            </span>
        </td>
        <td v-bind:class="[assetClass]" v-if="!filterItem">
            <span v-if="checkout.asset.id != -1 && assetClass != 'inactive'">
                <router-link
                    v-bind:to="{name: 'Transactions', query: { item: checkout.asset.id, ...specificQueryVars }}"
                >
                    {{ asset }}
                </router-link>
            </span>
            <span v-else> {{ asset }} </span>
        </td>
        <td>
            {{ checkout.type }}
        </td>
        <td v-bind:title="checkout.timestamp">
            {{ checkoutDate | prettyDate }}
        </td>
        <td v-if="enableTracking">
            {{ reviewed }}
        </td>
    </tr>
</template>

<script>

import record from '../mixins/record'
import recordTransaction from '../mixins/record-transaction'

export default {
    data(){
        return {
            
        }
    },
    
    props: {
        enableTracking: Boolean,
        specificQueryVars: Object
    },
    
    mixins: [record, recordTransaction],
    
    computed: {
        
        status: function() {
            return this.checkout.type;
        },
        
        reviewed: function() {
            if (this.status != 'out' && this.status != 'virtual') {
                return '-';
            }
            else {
                return this.checkout.review.status ? 'yes' : 'no';
            }
        }
        
    },
}
</script>

<style>

</style>
