<!-- EditItems.vue - Component for displaying asset editing interface -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
  
    <b-modal
        v-bind:id="id"
        title="Edit Items"
        v-on:ok="handleSaveButton"
        v-on:close="handleCancelButton"
        v-on:cancel="handleCancelButton"
        v-on:show="reset"
        v-bind:ok-disabled="disabled"
        v-bind:cancel-disabled="disabled"
        ok-title="Save"
        size="lg"
    >
        <b-container fluid>
        
            <table class="table table-hover table-sm table-striped">
                <thead class="thead-light">
                <tr>
                    <th>#</th>
                    <th>Description</th>
                    <th>Category</th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in itemsToChange" v-bind:key="index">
                        <td>{{ item.id }}</td>
                        <td>
                            <b-form-input
                                v-bind:id="'description-' + item.id" size="sm"
                                v-model="item.description" maxlength=20
                            >
                            </b-form-input>
                            <span v-bind:class="(itemErrors[index] == 'Success!') ? 'text-success' : 'text-danger'">{{ itemErrors[index] }}</span>
                        </td>
                        <td>
                            <b-form-select
                                v-bind:id="'category-' + item.id" size="sm"
                                v-model="item.category"
                                v-bind:options="categoryOptions"
                            >
                            </b-form-select>
                        </td> 
                    </tr>
                </tbody>
            </table>
        </b-container>
    </b-modal>

</template>

<script>

import updateItems from '../mixins/update-items'
import netfail from '../mixins/netfail'

export default {
    data(){
        return {
            categoryOptions: [],
            uriPrefix: 'assets',
            uriSuffix: '',
            id: 'edit-items',
            updateMethod: 'put'
        }
    },
    
    mixins: [updateItems, netfail],
    
    created: function() {
        // Get category list
        this.axios.get('categories?sort=order')
            .then((response) => {
                response.data.forEach(category => {
                    this.categoryOptions.push({
                        value: category.id,
                        text: category.name 
                    });
                })
            })
            .catch(() => {
                console.log('Network Failure');
                if (!this.networkFalure) this.networkFailure = true;
            });
    },
    
    watch: {
        selectedItems: function() {
            this.itemsToChange = [];
            this.selectedItems.forEach(item => {
                this.itemsToChange.push({            
                    id: item.id,
                    description: item.description,
                    category: item.category.id,
                    error: ''
                });
            });
        }
    },
    
    methods: {
        
        validateInput(item, index) {
            // Ensure note is correct format
            const regex = RegExp('^[a-zA-Z0-9 ]*$');
            if (regex.test(item.description)) {
                return true;
            }
            else {
                this.itemErrors[index] = 'Only letters, numbers, and whitespace!';
                this.errorCount++;
                this.$forceUpdate();
                return false;
            }    
        },
        
        // Given an item, build an object to send to the API as a JSON request
        buildUpdateObject(item) {
            return {
                description: item.description,
                category: item.category
            };
        }
    }
}
</script>

<style>

</style>
