/**
 * filter-links.js - Shared code for links that can be used to call up filtered checkout data
 *
 * @see       https://github.com/teachernerd/inventory/ The Inventory Box GitHub project
 *
 * @author    Daniel Reinish (teachernerd) <dan@reinish.net>
 * @copyright 2016 - 2022 Daniel Reinish
 * @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
 * @note      This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 */
 
/**
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
 */

export default {
    data() {
        return {
            
        }
    },
    
    methods: {
        goToFilteredCheckouts: function(item, user, group, filter) {
           
            if (item != null) {
                this.$emit('update:requestedFilterName', item.id + ': ' + item.description);
            }
            if (user != null) {
                this.$emit('update:requestedFilterName', user.firstName + ' ' + user.lastName);
            }
            if (group != null) {
                this.$emit('update:requestedFilterName', group.name);
            }
            
            this.$router.push({
                name: 'Checkouts',
                query: {
                    item: (item == null) ? null: item.id,
                    user: (user == null) ? null : user.id,
                    group: (group == null) ? null : group.id,
                    show: filter
                }
            });
        }
    }
}