<!-- TransactionsPage.vue - Component for showing Transactions page -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    <div id="transactions-content">
        <page-structure
            v-bind="basePageProps"
            v-bind:noun="noun"
            v-bind:pluralNoun="pluralNoun"
            v-bind:restore="false"
            v-bind:showDetails="true"
            v-bind:showUpdateMessage="false"
            v-bind:updateCount="0"
            v-bind:action="null"
            v-bind:sortedBy="null"
            v-bind:groupedBy="null"
            v-bind:groupedItems="{}"
            v-bind:columns="columns"
            v-bind:groupedColumns="columns"
            v-bind:additionalQueries = "{ show: this.$route.query['show'], ...specificQueryVars }"
            v-bind:showSelectAll = "false"
            v-bind:includeSelectColumn="false"
            skeletonWidth="5%"
            v-on:updated-limit="$emit('updated-limit', arguments[0])"
            v-bind:limit="limit"
        >
            <template v-slot:after-title>
                <filter-badges
                    v-bind:specificItem='specificItem'
                    v-bind:specificUser='specificUser'
                    v-bind:specificGroup='specificGroup'
                    v-bind:specificQueryVars='specificQueryVars'
                    v-bind:items='items'
                ></filter-badges>
            </template>
            
            <template v-slot:records>
                <transaction-record
                    v-for="transaction in items"
                    v-model="selected[transaction.id]"
                    v-bind:checkout = "transaction"
                    v-bind:inactiveItems = "inactiveItems"
                    v-bind:key="transaction.id"
                    v-bind:filterItem = "specificItem > 0"
                    v-bind:filterUser = "specificUser > 0"
                    v-bind:filterGroup = "specificGroup > 0"
                    v-bind:enableTracking = "enableTracking"
                    v-bind:specificQueryVars = "specificQueryVars"
                ></transaction-record>
            </template>
            
        </page-structure>
    </div>
</template>

<script>

import PageStructure from './PageStructure'
import TransactionRecord from './TransactionRecord'
import base from '../mixins/base'
import transaction from '../mixins/transaction'


export default {
    
    data(){
        return{
            uri: 'transactions',
            pluralNoun: 'transactions',
            noun: 'transaction'
        }
    },
    
    metaInfo: {
        title: 'Transactions'
    },
    
    props: {
        enableTracking: Boolean
    },
    
    mixins: [base, transaction],
    
    components: {
        'transaction-record': TransactionRecord,
        'page-structure': PageStructure
    },
    
    computed: {
        columns: function() {
            let columns = [];
            columns.push({ sort: 'trans', name: 'Trans. #', sortable:false  });
            if (this.specificUser <= 0) columns.push({ sort: 'user', name: 'User', sortable:false });
            if (this.specificItem <= 0) columns.push({ sort: 'item', name: 'Item', sortable:false  });
            columns.push({ sort: 'action', name: 'Action', sortable:false });
            columns.push({ sort: 'date', name: 'Timestamp', sortable:false  });
            if (this.enableTracking) columns.push({ sort: 'complete', name: 'Completed', sortable: false });
            return columns;      
        }
    },
    
    watch: {
        showCheckAlert: function() {
            if ( Object.prototype.hasOwnProperty.call(this.showCheckAlert, 'type') && this.showCheckAlert != {} ) {
                if (this.showCheckAlert.type == 'in' || this.showCheckAlert.type == 'out') {
                    this.fetchItems();
                }
                this.$emit('check', {});
            }
        }
    }
}
</script>
