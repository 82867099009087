<!-- LoadingRecord.vue - Placeholder component while table data is loading -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
	<tr v-if="loading">
		<td v-if="showCheck">
			<b-form-checkbox v-bind:disabled="true"></b-form-checkbox>
		</td>
		<td v-bind:colspan="8">
			<b-skeleton v-bind:width="skeletonWidth" v-bind:height="skeletonHeight"></b-skeleton>
		</td>
	</tr>
</template>

<script>

export default {
    
    data(){
        return{
            skeletonHeight: "20px"
        }
    },
    
    props: {
        showCheck: Boolean,
        skeletonWidth: String,
        loading: Boolean
    }
    
    
}
</script>

<style>


    
</style>
