<!-- DebugDialog.vue - Dialog box component to display data helpful for debugging -->

<!--
    @author    Daniel Reinish (teachernerd) <dan@reinish.net>
    @copyright 2016 - 2022 Daniel Reinish
    @license   https://www.gnu.org/licenses/gpl-3.0.html GNU General Public License
    @note      This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.
-->

<!--
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <https://www.gnu.org/licenses/gpl-3.0.html>.
-->

<template>
    <b-modal
        id="debug"
        title="Debug Info"
        ok-variant="info"
        ok-only
    >
        <div class="container">
            <div class="row">
                <div class="col">
                   <p><strong>Login Status:</strong> {{ isAuthorized }}</p>
                   <p><strong>Login Type:</strong> {{ scope }}</p>
                   <p><strong>Access Token:</strong> {{ accessToken }}</p>
                   <p><strong>Expiration:</strong> {{ expire }}</p>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>

export default {

    props: {
        isAuthorized: Boolean,
        scope: String,
        accessToken: String,
        expire: Date
    } 
}

</script>